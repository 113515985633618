import {Injectable} from '@angular/core';
import {Camera, CameraPermissionType, CameraResultType, ImageOptions, PermissionStatus, Photo} from '@capacitor/camera';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {
  }

  /**
   * Wrapper function that encapsulates a call to Camera.getPhoto after checking permissions.
   *
   * @param options ImageOptions from Capacitor camera plugin
   */
  async getPhoto(options?: ImageOptions): Promise<Photo> {
    // set default options if none are provided
    if (!options) {
      const promptLabelHeader = await this.translate.get('services.camera.prompt-label-header').toPromise();
      const promptLabelCancel = await this.translate.get('shared.cancel').toPromise();
      const promptLabelPhoto = await this.translate.get('services.camera.prompt-label-photo').toPromise();
      const promptLabelPicture = await this.translate.get('services.camera.prompt-label-picture').toPromise();

      options = {
        resultType: CameraResultType.Base64,
        promptLabelHeader,
        promptLabelCancel,
        promptLabelPhoto,
        promptLabelPicture
      };
    }
    let status: PermissionStatus = await Camera.checkPermissions();
    // check if permissions for camera usage and photo library access are missing
    const missingPermissions: CameraPermissionType[] = [];
    if (status.camera !== 'granted') {
      missingPermissions.push('camera');
    }
    if (status.photos !== 'granted') {
      missingPermissions.push('photos');
    }
    if (missingPermissions.length > 0) {
      // request missing permissions
      status = await Camera.requestPermissions({permissions: missingPermissions});
    }
    if (status.camera === 'granted' || status.photos === 'granted') {
      // continue if at least one permission was granted
      const photo = await Camera.getPhoto(options);
      photo.exif.name = 'IMG_' + UtilService.getDateTimeString() + '.' + photo.format;
      return photo;
    } else {
      // otherwise give some feedback
      const header = await this.translate.get('services.camera.permissions-denied-header').toPromise();
      const message = await this.translate.get('services.camera.permissions-denied-message').toPromise();
      const btnClose = await this.translate.get('shared.close').toPromise();

      const alert = await this.alertCtrl.create({
        header,
        message,
        buttons: [{
          text: btnClose,
          role: 'cancel'
        }]
      });

      await alert.present();
    }
  }
}
