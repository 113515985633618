import {AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  GeolocateControl,
  LngLat,
  Map,
  MapboxOptions,
  MapMouseEvent,
  Marker,
  NavigationControl,
  supported
} from 'maplibre-gl';
import {Constants} from '../../var/constants';
import {GeocodingService, MarkerInfo} from '../../services/geocoding.service';
import {BusStopService} from '../../services/busstop.service';
import {EventsService} from '../../services/events.service';
import {LayerService, OverlayInfo, WMSLayerInfo} from '../../services/layer.service';
import {LinksService} from '../../services/links.service';
import {GlobalsService} from '../../services/globals.service';
import {HttpClient} from '@angular/common/http';
import {MapService} from '../../services/map.service';
import {Platform} from '@ionic/angular';
import {PoisService} from '../../services/pois.service';
import {RoutingService} from '../../services/routing.service';
import {SettingsService} from '../../services/settings.service';
import {UtilService} from '../../services/util.service';
import {TranslateService} from '@ngx-translate/core';
import {Category} from '../../lib/types/radrevier-ruhr';
import {Router} from '@angular/router';
import {TurnByTurnNavigationControl} from '../../lib/maplibre-gl/control/tbt-navigation-control';
import {PoiControl} from '../../lib/maplibre-gl/control/poi-control';
import {NodeNetworkControl} from '../../lib/maplibre-gl/control/node-network-control';
import {WMSLayerControl} from '../../lib/maplibre-gl/control/wms-layer-control';
import FullscreenControl from '../../lib/maplibre-gl/control/fullscreen-control';
import {Capacitor} from '@capacitor/core';
import {MapMenuComponent} from '../../components/map-menu/map-menu.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({opacity: 1, transform: 'translateY(0)'})),
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateY(100%)'
        }),
        animate('0.2s ease-in')
      ]),
      transition(':leave', [
        animate('0.2s 0.1s ease-out', style({
          opacity: 0,
          transform: 'translateY(100%)'
        }))
      ])
    ])
  ]
})
export class MapComponent implements AfterViewChecked, OnInit, OnDestroy {

  @Input() hideControls: boolean;
  @Output() mapReady = new EventEmitter<Map>();
  @Output() mapStyleData = new EventEmitter<Map>();
  @Output() pointInfoClick = new EventEmitter<{ lngLat: LngLat; marker: Marker; markerInfo: any; event: MouseEvent }>();
  busStopLayer: any;
  map: Map;
  mapClickMarker: Marker;
  hoverMarker: Marker;
  hoverMarkerStart: any;
  hoverMarkerOptions = {
    firstWasSet: false,
    hoverMarkerPressed: false,
  };
  mapOptions: MapboxOptions = {
    container: 'map',
    style: Constants.URL_MAPTILES,
    maxBounds: [[Constants.MIN_LNG, Constants.MIN_LAT], [Constants.MAX_LNG, Constants.MAX_LAT]],
    center: [7.17,  51.938],
    zoom: Constants.ZOOM,
    maxZoom: 18,
    attributionControl: true,
    customAttribution: `<a href="http://www.openstreetmap.org/copyright" target="_blank" rel="noopener">
      &copy; OpenStreetMap </a>`
  };
  markerInfo: MarkerInfo;
  mobile: boolean;
  lastZoom: number;
  geolocateControl: GeolocateControl;
  tbtNavigationControl: TurnByTurnNavigationControl;
  poiControl: PoiControl;
  nodeNetworkControl: NodeNetworkControl;
  topValue = '0px';
  headerVisible = true;
  webGlSupported: boolean = supported();
  customRouteLayer: any;
  hoversOverMarker = false;
  timeoutIdDrag: number;
  firedMouseEvent = false;
  nodeCategory = Constants.nodeCategory;

  constructor(
    private busStopService: BusStopService,
    private events: EventsService,
    private geocoding: GeocodingService,
    private layerService: LayerService,
    private linksService: LinksService,
    private globals: GlobalsService,
    private http: HttpClient,
    private mapService: MapService,
    private platform: Platform,
    private poisService: PoisService,
    private router: Router,
    private routingService: RoutingService,
    private settings: SettingsService,
    private util: UtilService,
    public translate: TranslateService
  ) {
  }

  @Input() set fullHeight(value: boolean) {
    // doesn't fit anymore
    // if (value === true) {
    //   this.topValue = '0px';
    // } else {
    //   this.topValue = '140px';
    // }
  }

  _enableMapClick: boolean;

  @Input() set enableMapClick(value: boolean) {
    this._enableMapClick = value;
    this.setNodeNetworkControl(value);
  }

  ngOnInit() {
    this.linksService.getUrlParams(window.location.href);
    this.mobile = this.platform.is('mobile');

    // store initial zoom
    this.lastZoom = this.mapOptions.zoom;

    this.headerVisible = this.globals.get('headerVisible') !== false; // true by default
    console.log('header in map component: ' + this.headerVisible);

    const doInit = () => {
      this.map = new Map(this.mapOptions);
      this.mapService.setMap(this.map);

      this.events.subscribe('map:ready', async () => {
        this.mapReady.emit(this.map);
        // await this.layerService.restoreBaseLayer();
      });

      this.hoverMarkerOptions.hoverMarkerPressed = false;
      this.hoverMarkerOptions.firstWasSet = false;
      this.hoverMarkerStart = null;

      this.events.subscribe('map:styledata', async () => {
        await this.translateMapControls();
        this.mapStyleData.emit(this.map);
      });

      this.events.subscribe('language:change', () => this.translateMapControls());

      this.events.subscribe('map:marker-hover', hover => {
        this.hoversOverMarker = hover;
      });

      this.events.subscribe('layer:custom-route', layer => {
        this.customRouteLayer = layer;
      });

      // subscribing to when the dragging of a marker finished
      this.events.subscribe('map:marker-drag', ({event: event, marker: marker}) => {
        // should be a marker caused through dragging of the hover marker
        if (this.hoverMarkerOptions.firstWasSet) {
          const lngLat = marker.getLngLat();
          this.geocoding.reverse(lngLat).then((markerInfo) => {
            const args = {
              lngLat,
              markerInfo,
              hover: false
            };
            // save the last one
            this.routingService.addRoutingPointByMapClick('intermediate', args);
          });
        }
      });

      if (Capacitor.isNativePlatform() || this.platform.is('mobileweb')) {
        this.mapService.onLongTouch(this.onMapClick, this.resetMapClickMarker, this);
      } else {
        this.mapService.on(['click'], this.onMapClick, this);
      }

      this.events.subscribe('poi:selection-change', (params: { categories: Category[]; changed: Category }) => {
        this.updatePoiMapControl(params.categories);
      });

      this.events.subscribe('navigation:setup-navigation-view', () => {
        this.mapService.removeControl(this.geolocateControl);
        this.resetMapClickMarker();
      });

      this.events.subscribe('navigation:cancel-navigation-view', () => {
        this.map.addControl(this.geolocateControl);
        this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_LINES);
        this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_HEADS);
      });

      this.events.subscribe('routing:leaving-view', () => this.resetMapClickMarker());

      this.events.subscribe('routing:set-marker', ({point: point, type: type, index: index}) => {
        if (this.mapClickMarker) {
          if (point.coordinates.lng === this.mapClickMarker.getLngLat().lng
            && point.coordinates.lat === this.mapClickMarker.getLngLat().lat) {
            console.log('a marker was set in place of the mapClickMarker');
            this.mapClickMarker = null;
          }
        }
      });

      /**
       * Fired when a "drag to pan" interaction starts
       */
      this.map.on('dragstart', (e: MapMouseEvent) => {
        // Publish an event to the given topic.
        this.events.publish('map:drag-start', e);

        this.setCursor('move');
      });

      /**
       * Fired when a "drag to pan" interaction ends
       */
      this.map.on('dragend', (e: MapMouseEvent) => {
        // Publish an event to the given topic.
        this.events.publish('map:drag-end', e);
        // Set a variable after 30 seconds to re-enable automatic centering of map
        // Clear any previous timeout before
        this.mapService.cancelPanTimeout();
        this.mapService.timeoutPan = setTimeout(() => {
          this.mapService.cancelPanTimeout();
        }, 30000);


        this.setCursor('default');
        // this.refreshBusStopLayer();
      });

      /**
       * Fired just after the map completes a transition from one zoom level to another
       */
      this.map.on('zoomend', (e: MapMouseEvent) => {
        // Publish an event to the given topic.
        this.events.publish('map:zoom-end', e);
        const zoom = this.mapService.getZoom();
        if ((this.lastZoom - zoom) > 0) {
          this.events.publish('map:zoom-out', e);
        } else {
          this.events.publish('map:zoom-in', e);
        }
        this.lastZoom = zoom;
        // this.refreshBusStopLayer();
      });

      if (this.mapClickMarker) {
        this.mapClickMarker.remove();
        this.mapClickMarker = null;
      }

      this.map.on('mousedown', (e) => {
        if (this.hoverMarker) {
          // not to remove markers while they are being dragged
          // because it's not hovered over the route anymore
          this.hoverMarkerOptions.hoverMarkerPressed = true;
          this.hoverMarkerStart = e.point;
        }
      });

      this.map.on('mouseup', (e) => {
        // set the last marker as a waypoint and
        // refresh everything connected to the hovering
        if (this.hoverMarkerOptions.hoverMarkerPressed && this.hoverMarkerStart) {
          const index = this.globals.get('hover-point-index');
          const args = {
            lngLat: e.lngLat.wrap(),
            firstMarkerSet: this.hoverMarkerOptions.firstWasSet,
            last: true,
            index
          };
          // give it to the routing here
          this.firedMouseEvent = true;
          this.events.publish('hover-marker: dragged', args);

          this.hoverMarkerOptions.hoverMarkerPressed = false;
          this.hoverMarkerOptions.firstWasSet = false;
          this.hoverMarkerStart = null;
        }
      });

      // reacting to hovering over a custom route, to drag it in some
      // direction
      this.map.on('mousemove', (e) => {
        if (!this.mobile) {
          if (this.hoverMarkerOptions.hoverMarkerPressed) {
            if (this.firedMouseEvent) {
              this.firedMouseEvent = false;
            } else {
              // check how much the mouse moved
              const a = this.hoverMarkerStart.x - e.point.x;
              const b = this.hoverMarkerStart.y - e.point.y;
              const c = Math.sqrt(a * a + b * b);

              // if the distance is big enough, set a timeout to update the route
              // no timeout and fast dragging cause a 504 error (Gateway Timeout)
              if (c > 30) {
                this.requestRouteDragTimeout(e);
              }
            }
          }

          // Here are the options to see if the mouse hovers over the route or somewhere else
          const fs = this.map.queryRenderedFeatures(e.point);
          // checking if layers we're looking for are below the mouse
          // not adding more markers if the route is already being dragged or if
          // hovering is above an ordinary marker

          if (fs.length > 0 && !this.hoverMarkerOptions.hoverMarkerPressed && !this.hoversOverMarker) {

            // 'hover-route' is slightly wider than 'custom-route' to creat a small buffer for hovering
            if (fs.filter(f => f.layer.id === 'hover-route').length > 0) {
              if (fs.filter(f => f.layer.id.includes('markers')).length === 0) {
                // console.log('entering the route area');
                // find the closest point on the route. But the route geometry does not have all the points
                const closestPoint = this.util.findClosestPolylinePoint(e.lngLat.wrap(), this.customRouteLayer);

                if (this.hoverMarker && !this.hoverMarkerOptions.hoverMarkerPressed) {
                  this.hoverMarker.remove();
                  this.hoverMarker = null;
                }
                // this.turnedHoverToWaypoint = false;
                if (!this.hoverMarkerOptions.firstWasSet) {
                  this.hoverMarker = this.mapService.addMarker(
                    closestPoint.point, 'hover', {type: 'hover', draggable: true});
                }

              } else {
                if (this.hoverMarker && !this.hoverMarkerOptions.hoverMarkerPressed) {
                  console.log('hovering over a node');
                  this.hoverMarker.remove();
                  this.hoverMarker = null;
                }
              }
            } else {
              if (this.hoverMarker && !this.hoverMarkerOptions.hoverMarkerPressed) {
                // console.log('hovering over feature layers of no interest');
                this.hoverMarker.remove();
                this.hoverMarker = null;
              }
            }
          } else {
            if (this.hoverMarker && !this.hoverMarkerOptions.hoverMarkerPressed) {
              // console.log('hovering over no feature layers at all');
              this.hoverMarker.remove();
              this.hoverMarker = null;
            }
          }
        }
      });

      if (this.headerVisible) {
        console.log('setupmapcontrols');
        this.setupMapControls();
      }

      // this.refreshBusStopLayer();
    };

    // check if mapService still has a map stored
    if (this.mapService.map) {
      // old map needs to be destroyed before new instance can be created
      const subscription = this.events.subscribe('map:destroy', () => {
        doInit();
        subscription.unsubscribe();
      });
    } else {
      if (this.webGlSupported) {
        doInit();
      }
    }
  }

  // timeout before requesting a route from dragging to avoid a Gateway Timeout
  requestRouteDragTimeout(e) {
    this.cancelDragTimeout();
    this.timeoutIdDrag = window.setTimeout(async () => {
      if (this.firedMouseEvent) {
        this.firedMouseEvent = false;
      } else {
        this.hoverMarkerStart = e.point;
        const index = this.globals.get('hover-point-index');
        const args = {
          lngLat: e.lngLat.wrap(),
          firstMarkerSet: this.hoverMarkerOptions.firstWasSet,
          last: false,
          index
        };

        // give it to the routing here
        this.events.publish('hover-marker: dragged', args);
        this.hoverMarkerOptions.firstWasSet = true;
      }
    }, 200);

  }

  cancelDragTimeout() {
    if (this.timeoutIdDrag) {
      window.clearTimeout(this.timeoutIdDrag);
      this.timeoutIdDrag = null;
    }
  }

  ngAfterViewChecked() {
    if (this.map) {
      const containerHeight = this.map.getContainer().offsetHeight;
      const canvasHeight = this.map.getCanvas().offsetHeight;
      if (containerHeight !== canvasHeight) {
        // resize map
        this.map.resize();
      }
    }
  }

  ngOnDestroy() {
    // this.events.publish('map:destroy');
    this.mapService.deleteMap();
  }

  /**
   * If mapClick is enabled (_enableMapClick input) this will create a marker and an info popup containing name, city and coordinates
   * of the point clicked. The information is retrieved via reversed geocoding.
   *
   * This method is called for clicks/taps at arbitrary places on the map, not for clicks at POIs and nodes.
   *
   * @param e The click event
   */
  async onMapClick(e: MapMouseEvent) {
    if (this._enableMapClick && this.headerVisible) {
      if (!this.mapClickMarker && !this.map.queryRenderedFeatures(e.point)
        .find(feature => feature.source === 'pois-src' || feature.source === 'nodes-src')) {
        this.mapClickMarker = this.mapService.addMarker(e.lngLat, 'custom-marker', {type: 'info'});
        this.markerInfo = await this.geocoding.reverse(e.lngLat);
      } else {
        this.resetMapClickMarker();
      }
    }
  }


  setNodeNetworkControl(enabled: boolean) {
    const doSet = () => {
      if (enabled) {
        this.nodeNetworkControl = new NodeNetworkControl(this.events);
        this.map.addControl(this.nodeNetworkControl, 'top-right');
        this.nodeNetworkControl.container.addEventListener('toggleNodeNetwork', (event: CustomEvent) => {
          const poiCategories = this.globals.get('poiCategories');
          const networkCategory = poiCategories.find(category => category.code === this.nodeCategory);
          networkCategory.selected = !event.detail.inactive;
          this.globals.set('poiCategories', poiCategories);
          this.events.publish('map:poi-selection-change', networkCategory);
        });
        this.nodeNetworkControl.container.addEventListener('toggleNodeNetworkLines', (event: CustomEvent) => {
          const lang = this.translate.currentLang;
          const layerControl = this.globals.get('layerControl');
          const layerInfo: OverlayInfo = layerControl.overlays.find(info => info.layer === 'knotenpunktenetz');
          layerInfo.selected = !event.detail.inactive;
          this.layerService.toggleOverlay({
            layerInfo,
            checked: layerInfo.selected,
            before: 'roadnetworksbefore',
            fireEvent: true
          });

          let blockedOverlay;
          layerControl.blockedOverlays.forEach((element) => {
            if (element.layer.includes(layerInfo.layer)) {
              blockedOverlay = element;
            }
          });
          if (blockedOverlay) {
            blockedOverlay.selected = layerInfo.selected;
            this.layerService.toggleOverlay({
              layerInfo: blockedOverlay,
              checked: blockedOverlay.selected,
              before: 'roadnetworksbefore',
              fireEvent: false
            });
          }
          if (window.innerWidth < window.innerHeight) {
            layerControl.overlays.forEach((element) => {
              const layerOnMap = this.mapService.getLayer(element.localized[lang].title);
              const mapMenu: MapMenuComponent = this.globals.get('mapMenu');
              const nodeOverlay = mapMenu.overlays.find(overlay => overlay.localized.de.title === element.localized.de.title);
              nodeOverlay.selected = !!layerOnMap;
            });
          }
        });
      } else if (this.nodeNetworkControl) {
        this.map.removeControl(this.nodeNetworkControl);
      }
    };

    if (this.map) {
      doSet();
    } else {
      this.events.subscribe('map:ready', () => doSet());
    }
  }

  updatePoiMapControl(categories: Category[]) {
    // this.poiControl.categories = categories;
    if (this.poiControl) {
      this.poiControl.setCategories(categories);
    }
  }

  onPointInfoClick(event: MouseEvent) {
    this.pointInfoClick.emit({
      lngLat: this.mapClickMarker.getLngLat(),
      marker: this.mapClickMarker,
      markerInfo: this.markerInfo,
      event
    });
  }

  async onBusStopClick(event) {
    if (event.features && event.features.length > 0) {
      await this.router.navigate(['/haltestelle/' + event.features[0].properties.id]);
    }
  }

  clearBusStopLayer() {
    if (this.busStopLayer) {
      // this.map.off('click', 'busStops', this.onBusStopClick.bind(this));
      this.map.removeLayer('busStops');
      this.map.removeSource('busStops');
      this.busStopLayer = null;
    }
  }

  private resetMapClickMarker(): void {
    if (this.mapClickMarker) {
      this.mapClickMarker.remove();
      this.mapClickMarker = null;
    }
    if (this.markerInfo) {
      this.markerInfo = null;
    }
    this.hoverMarkerOptions.hoverMarkerPressed = false;
    this.hoverMarkerOptions.firstWasSet = false;
    this.hoverMarkerStart = null;
    this.globals.remove('hover-point-index');
  }

  private async setupMapControls() {
    if (!this.mobile) {
      const fullScreenControl = new FullscreenControl(this.translate);
      this.map.addControl(fullScreenControl, 'top-left');
    }

    const navigationControl = new NavigationControl({showCompass: true, showZoom: true});
    this.map.addControl(navigationControl, 'top-right');

    if (!this.hideControls) {
      const wmsLayersUrl = 'assets/json/wms-layers.json';
      this.http.get(wmsLayersUrl).subscribe((
        response: { baseLayers: WMSLayerInfo[]}) => {
        const lang = this.translate.currentLang;
        response.baseLayers.forEach(item => {
          item.title = item.localized[lang].title;
        });
        // response.overlays.forEach(item => {
        //   item.title = item.localized[lang].title;
        // });
        // response.blockedOverlays.forEach(item => {
        //   item.title = item.localized[lang].title;
        // });
        const layerControl = new WMSLayerControl(
          response.baseLayers,
          // response.overlays,
          // response.blockedOverlays,
          this.events,
          this.layerService,
          this.translate
        );
        this.map.addControl(layerControl, 'top-right');
        this.globals.set('layerControl', layerControl);
      });

      const categories = await this.poisService.getPoiCategories();
      this.poiControl = new PoiControl(categories, this.events);
      this.map.addControl(this.poiControl, 'top-right');
      this.poiControl.container.addEventListener('togglePoiCategory', (event: CustomEvent) => {
        console.log('Toggled poi category', event.detail.changed.code);
        this.globals.set('poiCategories', event.detail.categories);
        this.events.publish('map:poi-selection-change', event.detail.changed);
      });

      this.geolocateControl = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });

      this.tbtNavigationControl = new TurnByTurnNavigationControl();

      this.map.addControl(this.geolocateControl, 'bottom-right');
    }
  }

  private setCursor(type: string) {
    if (this.map) {
      this.map.getCanvasContainer().style.cursor = type;
    }
  }

  private async translateMapControls() {
    const labelCompass = await this.translate.get('map-controls.compass').toPromise();
    const labelGeolocate = await this.translate.get('map-controls.geolocate').toPromise();
    const labelZoomIn = await this.translate.get('map-controls.zoom-in').toPromise();
    const labelZoomOut = await this.translate.get('map-controls.zoom-out').toPromise();

    const containerEl = this.map.getContainer();
    [{
      class: 'mapboxgl-ctrl-zoom-in',
      title: labelZoomIn
    }, {
      class: 'mapboxgl-ctrl-zoom-out',
      title: labelZoomOut
    }, {
      class: 'mapboxgl-ctrl-compass',
      title: labelCompass
    }, {
      class: 'mapboxgl-ctrl-geolocate',
      title: labelGeolocate
    }].forEach(item => {
      const element = containerEl.getElementsByClassName(item.class).item(0) as HTMLElement;
      if (element) {
        element.title = item.title;
      }
    });
  }
}
