import {Component, OnInit} from '@angular/core';
import ClipboardJS from 'clipboard';
import {NavParams, PopoverController, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from '../../services/util.service';

@Component({
  selector: 'app-link-popover',
  templateUrl: './link-popover.component.html',
  styleUrls: ['./link-popover.component.scss'],
})
export class LinkPopoverComponent implements OnInit {

  clipboard: ClipboardJS;
  url: string;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    public util: UtilService
  ) {
  }

  ngOnInit() {
    this.url = this.navParams.get('url');
    this.clipboard = new ClipboardJS('.clipboard-button');
    this.clipboard.on('success', async () => {
      const header = await this.translate.get('pages.link-popover.feedback').toPromise();
      const toast = await this.toastCtrl.create({
        header,
        duration: 3000,
        position: 'bottom'
      });
      await toast.present();
      await this.close();
    });
  }

  async close() {
    await this.popoverCtrl.dismiss();
  }
}
