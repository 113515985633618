import {Injectable} from '@angular/core';
import {
  AnyLayer,
  BackgroundLayout,
  BackgroundPaint,
  CircleLayout,
  CirclePaint,
  FillExtrusionLayout,
  FillExtrusionPaint,
  FillLayout,
  FillPaint,
  HeatmapLayout,
  HeatmapPaint,
  HillshadeLayout,
  HillshadePaint,
  Layer,
  LineLayer,
  LineLayout,
  LinePaint, RasterLayer,
  RasterLayout,
  RasterPaint,
  SymbolLayout,
  SymbolPaint
} from 'maplibre-gl';
import {TranslateService} from '@ngx-translate/core';

import {MapService} from './map.service';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {EventsService} from './events.service';
import {Platform} from '@ionic/angular';
import {Subscription} from 'rxjs';

export class LayerInfo {
  localized: {
    de: {
      title: string;
    };
    en: {
      title: string;
    };
    nl: {
      title: string;
    };
  };
  title: string;
  legend?: string;
  selected?: boolean;
}

export class WMSLayerInfo extends LayerInfo {
  url: string;
  layer: string;
  version?: string;
}

export class OverlayInfo extends LayerInfo {
  layer: LineLayer;
}

export class ToggleOverlayParams {
  layerInfo?: OverlayInfo;
  layer?: Layer;
  checked?: boolean;
  before?: string;
  fireEvent?: boolean;
}

export class ToggleBaseLayerParams {
  layerInfo?: WMSLayerInfo;
  layer?: Layer;
  save?: boolean;
  fireEvent?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LayerService {

  activeBaseLayer: Layer;
  bahntrassenLayer: Layer;

  baseWidth: 5;
  baseZoom: 15;
  private subscriptions: Subscription[] = [];

  constructor(
    private events: EventsService,
    private http: HttpClient,
    private mapProvider: MapService,
    private platform: Platform,
    private settingsProvider: SettingsService,
    private translate: TranslateService,
  ) {
    this.events.subscribe('map:destroy', () => this.onMapDestroy());
  }

  createLayer(layerInfo: WMSLayerInfo): RasterLayer {
    const lang = this.translate.currentLang;
    return {
      id: layerInfo.localized[lang].title,
      type: 'raster',
      source: layerInfo.layer,
      paint: {}
    };
  }

  async setBaseLayer(params: ToggleBaseLayerParams) {
    params.save = params.save !== false; // true by default
    const doAdd = async () => {
      this.mapProvider.addLayer(this.activeBaseLayer, false, ['baselayersbefore']);
      if (params.fireEvent) {
        this.events.publish('layer:set-base-layer', this.activeBaseLayer);
      }
      if (params.save) {
        await this.settingsProvider.set('active-base-layer', this.activeBaseLayer);
      }
    };

    if (this.activeBaseLayer) {
      this.mapProvider.removeLayer(this.activeBaseLayer.id);
      this.activeBaseLayer = null;
      if (params.fireEvent) {
        this.events.publish('layer:set-basemap', null);
      }
    } else {
      await this.mapProvider.rmVectorLayers();
    }
    if (params.layer) {
      this.activeBaseLayer = params.layer;
    } else if (params.layerInfo) {
      if (params.layerInfo.layer) {
        // wms layer
        this.activeBaseLayer = this.createLayer(params.layerInfo);
      } else {
        // show vector base layer
        await this.mapProvider.addVectorLayers();
      }
    }
    if (this.activeBaseLayer) {
      if (!this.mapProvider.getLayer('baselayersbefore')) {
        this.subscriptions.push(this.events.subscribe('map:added-empty-layers', async () => await doAdd()));
      } else {
        await doAdd();
      }
    } else if (params.save) {
      await this.settingsProvider.remove('active-base-layer');
    }
  }

  async restoreBaseLayer() {
    const baseLayer = await this.settingsProvider.get('active-base-layer');
    if (baseLayer) {
      await this.setBaseLayer({layer: baseLayer, fireEvent: true});
    }
  }

  /**
   * Toggles visibility for a specified layer on the map
   */
  toggleOverlay(params: ToggleOverlayParams) {
    if (!params.layer && params.layerInfo) {
      // params.layer = this.createLayer(params.layerInfo, 'overlay');
      params.layer = params.layerInfo.layer;
      if (params.checked === null || params.checked === undefined) {
        params.checked = params.layerInfo.selected;
      }
    }
    params.checked = !!params.checked;
    if (!params.before) {
      params.before = 'roadnetworksbefore';
    }
    if (params.layer) {
      if (params.checked) {
        console.log('adding ' + params.layer.id);
        this.mapProvider.addLayer(params.layer, false, [params.before]);
        if (params.layer.id === 'Bahntrassen') {
          this.bahntrassenLayer = params.layer;
        } else if (this.bahntrassenLayer && !params.layer.id.includes('Gesperrt')) {
          this.mapProvider.addLayer(this.bahntrassenLayer, false, [params.before]);
        }
        if (params.fireEvent) {
          this.events.publish('layer:add-overlay', params.layer);
        }
      } else {
        console.log('removing ' + params.layer.id);
        this.mapProvider.removeLayer(params.layer.id);
        if (params.layer.id === 'Bahntrassen') {
          this.bahntrassenLayer = null;
        }
        if (params.fireEvent) {
          this.events.publish('layer:remove-overlay', params.layer);
        }
      }
      if (params.layer.id === 'Touristisches Knotenpunktnetz mit Sperrungen') {
        this.events.publish('map:node-network-selection-change', params);
      }
    }
  }

  async onMapDestroy() {
    // clear base layer
    await this.setBaseLayer({save: false});
    this.bahntrassenLayer = null;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
