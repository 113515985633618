import {IControl, Map} from 'maplibre-gl';
import {DOM} from './dom';
import {Category} from '../../types/radrevier-ruhr';
import {EventsService} from '../../../services/events.service';
import {Constants} from 'src/app/var/constants';

export class NodeNetworkControl implements IControl {
  public container: HTMLElement;
  private map: Map;
  private className = 'mapboxgl-ctrl';
  private triggerNodeNetworkBtn: HTMLButtonElement;
  private triggerNetworkBtn: HTMLButtonElement;
  private nodeCateogry = Constants.nodeCategory;

  constructor(private events: EventsService) {
    events.subscribe('map:poi-selection-change', (category) => this.onPoiSelectionChange(category));
    events.subscribe('map:node-network-selection-change', (params) => this.onNetworkSelectionChange(params));
  }

  /**
   * @implements {IControl.onAdd}
   * @param map
   */
  onAdd(map: Map): HTMLElement {
    this.map = map;

    // the container is a transparent wrapper for the triggerNodeNetworkBtn
    this.container = DOM.create('div', `${this.className} ${this.className}-group ${this.className}-group-node-network`);
    this.container.addEventListener('contextmenu', (e: MouseEvent) => e.preventDefault());
    this.container.addEventListener('click', event => event.stopPropagation());

    // the triggerNodeNetworkBtn triggers the visibility of the node network
    this.triggerNodeNetworkBtn = DOM.create('button',
      `${this.className}-icon ${this.className}-node-network`, this.container) as HTMLButtonElement;
    this.triggerNodeNetworkBtn.type = 'button';
    this.triggerNodeNetworkBtn.title = 'Knotennetzwerk';
    this.triggerNodeNetworkBtn.setAttribute('aria-label', 'Knotennetzwerk');
    this.triggerNodeNetworkBtn.addEventListener('click', event => {
      event.stopPropagation();
      this.trigger(this.triggerNodeNetworkBtn);
    });

    // the triggerNetworkBtn triggers the visibility of the node network
    this.triggerNetworkBtn = DOM.create('button',
      `${this.className}-icon ${this.className}-node-network-lines`, this.container) as HTMLButtonElement;
    this.triggerNetworkBtn.type = 'button';
    this.triggerNetworkBtn.title = 'KnotennetzwerkLinien';
    this.triggerNetworkBtn.setAttribute('aria-label', 'KnotennetzwerkLinien');
    this.triggerNetworkBtn.addEventListener('click', event => {
      event.stopPropagation();
      this.trigger(this.triggerNetworkBtn);
    });

    return this.container;
  }

  /**
   * @implements {IControl.onRemove}
   */
  onRemove() {
    DOM.remove(this.container);
  }

  /**
   * @implements {IControl.getDefaultPosition}
   */
  getDefaultPosition() {
    return 'top-right';
  }

  onPoiSelectionChange(category: Category) {
    if (category.code === this.nodeCateogry) {
      const inactive = !category.selected;
      if (inactive) {
        this.triggerNodeNetworkBtn.classList.add('inactive');
      } else {
        this.triggerNodeNetworkBtn.classList.remove('inactive');
      }
    }
  }

  onNetworkSelectionChange(params) {
    if (params) {
      const inactive = !params.checked;
      if (inactive) {
        this.triggerNetworkBtn.classList.add('inactive');
      } else {
        this.triggerNetworkBtn.classList.remove('inactive');
      }
    }
  }

  /**
   * Toggles status of the node network
   * @param button
   */
  private trigger(button: HTMLButtonElement) {
    const inactive = button.classList.contains('inactive');
    if (button.title === 'Knotennetzwerk') {
      const event = new CustomEvent('toggleNodeNetwork', {detail: {inactive: !inactive}});
      this.container.dispatchEvent(event);
    } else {
      const event = new CustomEvent('toggleNodeNetworkLines', {detail: {inactive: !inactive}});
      this.container.dispatchEvent(event);
    }
    
  }

}


