import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationState, RoutingSolution} from '../lib/types/radrevier-ruhr';
import {LngLat, Marker, Popup} from 'maplibre-gl';
import {GlobalsService} from './globals.service';
import {EventsService} from './events.service';
import {HttpClient} from '@angular/common/http';
import {MapService} from './map.service';
import {NavigationService} from './navigation.service';
import {Platform, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from './util.service';
import {Constants} from '../var/constants';
import {LineString} from 'geojson';
import {ListPopoverComponent} from '../components/list-popover/list-popover.component';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

export class RoutingPoint {
  label: string;
  coordinates: LngLat;
  clickHandler?: any;
  lineIndex?: number;
  hover?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  markers: { start: Marker, destination: Marker, intermediates: Marker[] };
  routeLayer: any;
  loadedComponentsCounter: number;
  routingRequestLock = false;
  nodeCategory = Constants.nodeCategory;

  constructor(
    private globals: GlobalsService,
    private events: EventsService,
    private http: HttpClient,
    private location: Location,
    private mapService: MapService,
    private navigationService: NavigationService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private router: Router,
    private translate: TranslateService,
    private util: UtilService
  ) {
    this.markers = {
      start: null,
      destination: null,
      intermediates: []
    };
    this.loadedComponentsCounter = 0;
  }

  updateAllMarkers(points: RoutingPoint[], zoomTo: boolean = true) {
    this.updateMarkers('start', [points[0]], {clearRoute: true, zoomTo: false});
    this.updateMarkers('intermediate', points.slice(1, points.length - 1), {clearRoute: false, zoomTo: false});
    this.updateMarkers('destination', [points[points.length - 1]], {clearRoute: false, zoomTo});
  }

  /**
   * Updates routing markers on the map (either start-, destination- or intermediate-markers)
   */
  updateMarkers(type: string, points: RoutingPoint[], options: {
    clearRoute?: boolean,
    mobileMapVisible?: boolean,
    offset?: { top: number, right: number, bottom: number, left: number },
    zoomTo?: boolean,
    roundTrip?: boolean
  } = {
    clearRoute: true,
    mobileMapVisible: false,
    offset: {top: 0, right: 0, bottom: 0, left: 0},
    zoomTo: false,
    roundTrip: false
  }) {
    console.log('RoutingProvider: updateMarkers', points);
    // default options
    if (options.clearRoute === undefined) {
      options.clearRoute = true;
    }
    if (options.zoomTo === undefined) {
      options.zoomTo = false;
    }
    // clear previous route if any
    if (options.clearRoute) {
      this.clearRoute();
    }
    if (type === 'intermediate') {
      if (this.markers.intermediates.length >= 1) {
        // remove existing markers from map
        this.markers.intermediates.forEach(marker => marker.remove());
      }
      this.markers.intermediates = [];
      points.forEach((point, index) => {
        if (point.coordinates) {
          // distinguishing between a hover marker and a normal waypoint marker
          const markerType = point.hover ? 'invisible' : 'glyph';
          const marker = this.mapService.addMarker(point.coordinates, markerType, {
            clickHandler: this.onRoutingPointClick.bind(this, point),
            draggable: true,
            type: 'routing',
            zoomTo: false
          });
          if (!point.hover) {
            const contentEl = marker.getElement().getElementsByClassName('marker-poi-content')[0];
            contentEl.innerHTML = (index + 1).toString();
          }
          this.markers.intermediates.push(marker);
        }
      });
    } else {
      // start or destination
      const point = points[0];
      let icon;
      if (options.roundTrip) {
        icon = type === 'start' ? 'invisible' : 'glyph-AB';
      } else {
        icon = type === 'start' ? 'glyph-A' : 'glyph-B';
      }

      if (this.markers[type]) {
        // remove existing marker from map
        this.markers[type].remove();
      }
      if (point.coordinates) {
        this.markers[type] = this.mapService.addMarker(point.coordinates, icon, {
          clickHandler: this.onRoutingPointClick.bind(this, point),
          draggable: true,
          type: 'routing',
          zoomTo: false
        });
      } else {
        this.markers[type] = null;
      }
    }
    if (options.zoomTo) {
      if (options.mobileMapVisible) {
        this.zoomToMarkers(options.offset);
      } else {
        this.zoomToMarkers();
      }
    }
  }

  /**
   * Fetches routing solutions from the server api
   */
  async requestRoutes(properties: {
    type: 'shortest' | 'circular' | 'abdistance',
    qualified?: string,
    length?: number,
    showLoadMask?: boolean,
    onTour?: number,
    additionalPoints?: any[],
    selectedRoute?: RoutingSolution
  }): Promise<any> {
    if (!this.routingRequestLock) {
      this.routingRequestLock = true;
      // set defaults for non mandatory properties
      properties.qualified = properties.qualified || 'KNOTENPUNKTENETZ,RADVERKEHRSNETZ';
      properties.length = properties.length || 20;
      properties.showLoadMask = properties.showLoadMask !== false; // true by default

      if (properties.showLoadMask) {
        await this.util.showLoadingBar();
      }

      const start = this.markers.start.getLngLat();
      const body: any = {
        audience: 'BIKING',
        start: `${start.lat},${start.lng}`,
        qualified: properties.qualified
      };
      let url;

      if (typeof properties.onTour !== 'number' && properties.type === 'circular') {
        // set distance for circular route
        body.distance = properties.length * 1000;
        url = Constants.URL_ROUTING_ROUNDTRIP;
      } else {
        if (typeof properties.onTour === 'number') {
          url = Constants.URL_ROUTING_AB_SHORTEST;
          body.tourids = properties.onTour;
        } else if (properties.type === 'shortest') {
          url = Constants.URL_ROUTING_AB_SHORTEST;
        } else {
          url = Constants.URL_ROUTING_AB;
          body.maxresults = 2;
        }
        const destination = this.markers.destination.getLngLat();
        // set destination point and intermediate points for ab route
        body.end = `${destination.lat},${destination.lng}`;
        // set intermediates
        if (properties.additionalPoints) {
          body.intermediate = '';
          properties.additionalPoints.forEach((point) => {
            body.intermediate += `${point.coordinates.lat},${point.coordinates.lng},`;
          });
          // cut off last comma
          body.intermediate = body.intermediate.substr(0, body.intermediate.length - 1);
        }
        if (this.markers.intermediates.length > 0) {
          if (!body.intermediate) { body.intermediate = ''; }
          this.markers.intermediates.forEach((point) => {
            const lngLat = point.getLngLat();
            if (properties.additionalPoints) {
              // check where those points belong to because this is a circular route with not enough mid points
              const closestPoint = this.util.findClosestPolylinePoint(point.getLngLat(), properties.selectedRoute);
              if (closestPoint.index < Math.round(properties.selectedRoute.length / 3)) {
                // point should be in the first half of the route
                body.intermediate = `${lngLat.lat},${lngLat.lng},` + body.intermediate + `,`;

              } else if (closestPoint.index < Math.round(properties.selectedRoute.length * 2 / 3)) {
                // in the middle of the route
                const firstPointLng = properties.additionalPoints[0].coordinates.lng
                const firstPointLngIndex = body.intermediate.indexOf(firstPointLng);
                body.intermediate = [body.intermediate.slice(0, firstPointLngIndex + firstPointLng.length + 1), `${lngLat.lat},${lngLat.lng},`, body.intermediate.slice(firstPointLngIndex + firstPointLng.length + 1)].join('') + `,`;
              } else {
                // in the end of the route
                body.intermediate += `,${lngLat.lat},${lngLat.lng},`;
              }

            } else {
              // this was a normal route with no added mid points
              body.intermediate += `${lngLat.lat},${lngLat.lng},`;
            }
          });
          // cut off last comma
          body.intermediate = body.intermediate.substr(0, body.intermediate.length - 1);
        }
      }
      // create request and handle response
      try {
        let solutions = [];
        const response: any = await this.http.get(url, {params: body}).toPromise();
        if (response.hasOwnProperty('solutions') && response.solutions.length > 0) {
          solutions = response.solutions;
        }
        return solutions;
      } catch (e) {
        if (e.status >= 500 && e.status < 600) {
          this.translate.get('services.util.server-down-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
          });
        } else if (e.error.error === 'Bad Request') {
          await this.util.handleErrorMsg({message: e.error.message});
        } else if (e.status !== 504) {
          const message = await this.translate.get('services.routing.error-calculation').toPromise();
          await this.util.handleErrorMsg({message});
        }
      } finally {
        this.routingRequestLock = false;
        if (properties.showLoadMask) {
          await this.util.dismissLoadingBar();
        }
      }
    }
  }

  /**
   * Navigates to RoutingPage, passing clicked POI or selected from search-page address as parameter
   */
  async useInRouting(poi, event: MouseEvent, type?: string) {
    let item;
    let lngLat: LngLat;
    let title: string;
    const mapVisible = window.innerWidth >= 1024;
    let subscription: Subscription;

    const startRouting = () => {
      this.router.navigate(['/tourenplaner']);
    };

    const addPoint = () => {
      this.addRoutingPointByMapClick(item.id, {
        lngLat,
        markerInfo: {title},
        hover: false
      });

      subscription.unsubscribe();
    };

    if (type) {
      if (type === 'address') {
        item = {id: 'destination'};
      } else {
        item = {id: type};
      }
    } else {
      item = await this.showAddToRoutePopover(event);
    }
    if (item) {
      if (type === 'address') {
        lngLat = new LngLat(poi.result.geometry.lng, poi.result.geometry.lat);
        title = poi.displayName;
      } else {
        lngLat = new LngLat(poi.geom.coordinates[0], poi.geom.coordinates[1]);
        if (poi.categorycodes[0] === this.nodeCategory) {
          title = this.util.getTranslation(poi).description.split('-')[1]
        } else {
          title = this.util.getTranslation(poi).name;
        }
      }
      console.log(this.router.url);
      if (this.router.url === '/tourenplaner') {
        addPoint();
      } else {
        if (this.router.url === '/suche') {
          subscription = this.events.subscribe('map:ready', () => {
            addPoint();
          });
          startRouting();
        } else if (mapVisible) {
          // on desktop, map will persist when changing views, so we have to listen for routing page to finish loading
          // before trying to set routing point
          subscription = this.events.subscribe('routing:page-load', () => {
            addPoint();
          });
          startRouting();
        } else {
          // on mobile the map will be destroyed and recreated when changing views, so we have to listen to the map ready
          // event before trying to set routing point
          subscription = this.events.subscribe('map:ready', () => {
            addPoint();
          });
          if (this.router.url === '/karte') {
            this.location.back();
            window.setTimeout(() => startRouting(), 500);
          } else {
            startRouting();
          }
        }
      }
    }

  }

  /**
   * When the user adds a point by map click, a popup is shown for the user to specify if the point should be added
   * as start, destination or intermediate marker. This information is passed on to addRoutingPointByMapClick.
   */
  showAddToRoutePopover(event: MouseEvent): Promise<any> {
    return new Promise(async (resolve) => {
      const labelStart = await this.translate.get('pages.routing.add-as-starting-point').toPromise();
      const labelIntermediate = await this.translate.get('pages.routing.add-as-intermediate-point').toPromise();
      const labelDestination = await this.translate.get('pages.routing.add-as-destination-point').toPromise();
      const listItems = [];
      console.log(this.markers.start);
      listItems.push({
        label: labelStart,
        id: 'start'
      });
      if (this.markers.start && this.markers.destination) {
        listItems.push({
          label: labelIntermediate,
          id: 'intermediate'
        });
      }
      listItems.push({
        label: labelDestination,
        id: 'destination'
      });
      const popover = await this.popoverCtrl.create({
        component: ListPopoverComponent,
        componentProps: {listItems},
        cssClass: 'add-to-route-popover',
        event
      });
      await popover.present();
      // resolved when a ListItem was selected and/or the menu closes
      const detail = await popover.onDidDismiss();
      if (detail.data) {
        resolve(detail.data);
      }
    });
  }

  /**
   * Creates a new RoutingMarker using data passed from MapComponent
   */
  addRoutingPointByMapClick(type: string, args: { lngLat: LngLat, marker?: Marker, markerInfo: any, hover?: boolean, index?: number }) {
    const index = args.index || null;
    let label = args.markerInfo.title;
    if (args.markerInfo.city) {
      label += ', ' + args.markerInfo.city;
    }
    const point: RoutingPoint = {
      label,
      coordinates: args.lngLat,
      hover: args.hover
    };
    point.clickHandler = this.onRoutingPointClick.bind(this, point);

    // remove info marker from map
    if (args.marker) {
      args.marker.remove();
    }

    this.events.publish('routing:set-marker', {point, type, index});
  }

  async onRoutingPointClick(point: RoutingPoint, event: Event) {
    console.log('RoutingProvider: onRoutingPointClick', point);
    event.preventDefault();
    event.stopPropagation();

    const btnAddAsDestination = await this.translate.get('pages.routing.add-as-destination-point').toPromise();
    const btnDelete = await this.translate.get('pages.routing.delete').toPromise();
    const btnMove = await this.translate.get('pages.routing.move').toPromise();


    let platform = 'md';
    if (this.platform.is('ios')) {
      platform = 'ios';
    }

    const mobile = this.platform.is('mobile');

    let equalsStartPoint = false;
    if (this.markers.start && this.markers.start.getLngLat) {
      const startCoordinates = this.markers.start.getLngLat().toArray();
      const pointCoordinates = LngLat.convert(point.coordinates).toArray();
      equalsStartPoint = (startCoordinates.length === pointCoordinates.length
        && startCoordinates.every((value, index) => value === pointCoordinates[index]));
    }

    console.log('equalsStartPoint: ' + equalsStartPoint);

    let lines = 1;
    let html = '';
    const setPoints = this.getSetPoints();
    if (equalsStartPoint && setPoints > 2) {
      html += `<ion-button class="button-add" color="primary" fill="clear">${btnAddAsDestination}</ion-button>`;
      lines++;
    }
    if (mobile) {
      html += `<ion-button class="button-move" color="primary" fill="clear">${btnMove}</ion-button>`;
      lines++;
    }
    html += `<ion-button class="button-delete" color="secondary" fill="clear">${btnDelete}</ion-button>`;

    // Routes can only be changed when not in navigation mode
    if (this.navigationService.state === NavigationState.OFF) {

      const popup = new Popup({closeButton: false, className: `routing-marker-popup lines-${lines}`})
        .setLngLat(point.coordinates)
        .setHTML(html)
        .addTo(this.mapService.map);

      const popupEl = popup.getElement();
      popupEl.getElementsByClassName('button-delete')[0].addEventListener('click', () => {
        this.events.publish('routing:delete-point', point);
        popup.remove();
      });
      if (mobile) {
        popupEl.getElementsByClassName('button-move')[0].addEventListener('click', () => {
          // this.events.publish('routing:enable-move-point', point);
          let target: any = event.target;
          if (Array.from(target.classList).indexOf('marker-poi-content') !== -1) {
            target = target.parentElement;
          }
          this.enableDragging(target.marker, true);
          popup.remove();
        });
      }
      if (equalsStartPoint && setPoints > 2) {
        popupEl.getElementsByClassName('button-add')[0].addEventListener('click', () => {
          this.events.publish('routing:start-as-destination');
          popup.remove();
        });
      }
    }
  }

  getSetPoints(): number {
    let count = 0;
    if (this.markers.start) {
      count++;
    }
    if (this.markers.destination) {
      count++;
    }
    count += this.markers.intermediates.length;
    return count;
  }

  enableDragging(marker: Marker, once: boolean = false) {
    marker.setDraggable(true);
    marker.on('dragend', () => {
      this.events.publish('map:marker-drag', {event, marker, end: true});
      marker.setDraggable(!once);
    });
  }

  /**
   * Shows a route on the map
   */
  showRoute(geom: LineString, tmp: boolean = false, fitBounds: boolean = true,
            offset?: { top: number, right: number, bottom: number, left: number }) {
    this.clearRoute(tmp);
    if (!tmp) {
      // always clear temporary route
      this.clearRoute(!tmp);
    }
    const layer = {
      id: tmp ? 'temporary-route' : 'custom-route',
      type: 'line',
      source: {
        type: 'geojson',
        data: geom
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': tmp ? '#5b686d' : '#008ac4',
        'line-opacity': 1.0,
        'line-width': {
          base: 1.5,
          stops: [
            [1, 0.5],
            [8, 4],
            [15, 8],
            [22, 10]
          ]
        },
      }
    };
    const direction = {
      id: 'direction-layer',
      type: 'symbol',
      source: {
        type: 'geojson',
        data: geom
      },
      layout: {
        'symbol-placement': 'line-center',
        // 'symbol-spacing': 200,
        'icon-allow-overlap': true,
        // 'icon-ignore-placement': true,
        'icon-image': 'direction_indicator',
        'icon-size': 0.4,
        visibility: 'visible'
      }
    };

    let hoverLayer;

    if (!tmp) {
      hoverLayer = {
        id: 'hover-route',
        type: 'line',
        source: {
          type: 'geojson',
          data: geom
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#008ac4',
          // 'line-color': '#7a1d7d',
          'line-opacity': 0.0,
          'line-width': 15
        }
      };

      this.mapService.addLayer(hoverLayer, fitBounds, ['toursbefore'], offset);
    }

    this.events.publish('layer:custom-route', layer);
    this.mapService.addLayer(layer, fitBounds, ['toursbefore'], offset);
    this.mapService.addLayer(direction, false, ['toursbefore'], offset);

    // activate checkbox
    const layerControl = this.globals.get('layerControl');
    layerControl.enableCustomTourControl(layer, hoverLayer);
  }

  /**
   * Removes a route from the map
   */
  clearRoute(tmp: boolean = false) {
    if (tmp) {
      this.mapService.removeLayer('temporary-route');
    } else if (this.mapService.map) {
      if (this.mapService.map.getLayer('custom-route')) {
        this.mapService.map.removeLayer('custom-route');
      }
      if (this.mapService.map.getLayer('direction-layer')) {
        this.mapService.map.removeLayer('direction-layer');
      }
      if (this.mapService.map.getSource('custom-route')) {
        this.mapService.map.removeSource('custom-route');
      }
      if (this.mapService.map.getSource('direction-layer')) {
        this.mapService.map.removeSource('direction-layer');
      }
      this.mapService.removeLayer('hover-route');
    }
    const layerControl = this.globals.get('layerControl');
    layerControl.disableCustomTourControl();
    this.events.publish('layer:custom-route-clear');
  }

  addRoutingMapElements() {
    const map = this.mapService.map;

    // add existing markers to map
    if (this.markers.start) {
      this.markers.start.addTo(map);
    }
    if (this.markers.intermediates.length >= 1) {
      this.markers.intermediates.forEach(marker => marker.addTo(map));
    }
    if (this.markers.destination) {
      this.markers.destination.addTo(map);
    }

    if (this.routeLayer) {
      // add route to map
      this.mapService.addLayer(this.routeLayer, true);
    } else {
      // zoom to marker extend
      this.zoomToMarkers();
    }

  }

  removeMapElements() {
    if (this.markers.start) {
      this.markers.start.remove();
      this.markers.start = null;
    }
    if (this.markers.intermediates && this.markers.intermediates.length > 0) {
      this.markers.intermediates.forEach(marker => marker.remove());
      this.markers.intermediates = [];
    }
    if (this.markers.destination) {
      this.markers.destination.remove();
      this.markers.destination = null;
    }
    this.clearRoute();
  }

  private zoomToMarkers(offset?: { top: number, right: number, bottom: number, left: number }) {
    const markers = this.getAllMarkers();
    if (markers.length === 1) {
      this.mapService.panTo(markers[0].getLngLat(), offset);
    } else if (markers.length > 1) {
      this.mapService.fitBounds(UtilService.getMarkerBounds(markers), 50, offset);
    }
  }

  /**
   * Returns an array of all markers currently set.
   * Can be used to obtain the number of markers set or to calculate marker bounds.
   */
  private getAllMarkers(): Marker[] {
    let markers = [];
    if (this.markers.start) {
      markers.push(this.markers.start);
    }
    if (this.markers.intermediates.length >= 1) {
      markers = markers.concat(this.markers.intermediates);
    }
    if (this.markers.destination) {
      markers.push(this.markers.destination);
    }
    return markers;
  }
}
