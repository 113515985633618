import {Injectable} from '@angular/core';
import {Constants} from '../var/constants';
import {HttpClient} from '@angular/common/http';
import {PopoverController} from '@ionic/angular';
import {UtilService} from './util.service';
import {Item} from '../lib/types/radrevier-ruhr';
import {SharePopoverComponent} from '../components/share-popover/share-popover.component';
import {LinkPopoverComponent} from '../components/link-popover/link-popover.component';
import {EmbedPopoverComponent} from '../components/embed-popover/embed-popover.component';
import {TranslateService} from '@ngx-translate/core';

import {Share} from '@capacitor/share';
import {Capacitor} from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  baseUrl = Constants.URL_BASE;

  constructor(
    private http: HttpClient,
    private popoverCtrl: PopoverController,
    private translate: TranslateService,
    private util: UtilService
  ) {
    this.initFacebook();
  }

  pinOnPinterest(title, url, text, image?: string) {
    const pinText = `${text} ${title}`;
    const pinLink = document.createElement('a');
    document.body.appendChild(pinLink);
    pinLink.href = `https://www.pinterest.com/pin/create/button/?url=${url}&description=${pinText}`;
    if (image) {
      pinLink.href += `&media=${image}`;
    }
    pinLink.target = '_blank';
    pinLink.click();
  }

  async shareByEmail(title, url, text, category) {
    const mailText = text + ' ' + title;
    const body = await this.translate.get('services.social.share-email-body', {category, url}).toPromise();
    const mailLink = document.createElement('a');
    document.body.appendChild(mailLink);
    mailLink.href = `mailto:EmpfängerIn?subject=${mailText}&body=${body}`;
    mailLink.click();
  }

  shareOnFacebook(title, url, text) {
    const win: any = window;
    win.FB.ui({
      method: 'share',
      href: url,
      quote: `${text} ${title}`
    });
  }

  shareOnTwitter(title, url, text) {
    const twitterText = `${text} ${title}`;
    const tweetLink = document.createElement('a');
    document.body.appendChild(tweetLink);
    tweetLink.href = `https://twitter.com/intent/tweet?text=${twitterText}&url=${url}`;
    tweetLink.target = '_blank';
    tweetLink.click();
  }

  /**
   * A Tour or a poi can be shared
   */
  public async shareInterest(item: Item, service?: string) {
    if (item) {
      let title;
      let text;
      let url;
      let category;
      if (item.hasOwnProperty('length')) {
        title = this.util.getTranslation(item).name;
        url = this.baseUrl + 'tour-details/' + item.id;
        text = await this.translate.get('services.social.share-interest-tour').toPromise();
        category = 'diese Tour';
      } else {
        title = this.util.getTranslation(item).name;
        url = this.baseUrl + 'ort-details/' + item.id;
        text = await this.translate.get('services.social.share-interest-place').toPromise();
        category = 'diesen Ort';
      }
      let image;
      if (item.media.length > 0) {
        image = item.media.find(medium => medium.contenttype === 'image/jpeg' && medium.usage === 'HIGHLIGHT');
        if (!image) {
          image = item.media.find(medium => medium.contenttype === 'image/jpeg' && medium.usage === 'GALLERY');
        }
      }
      if (Capacitor.isNativePlatform()) {
        this.shareWithApp(title, url, text, image);
      } else {
        await this.shareBrowser(title, url, text, service, category, image);
      }
    }
  }

  getServiceFromPopover(item: Item, event): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      const popover = await this.popoverCtrl.create({
        component: SharePopoverComponent,
        componentProps: {item},
        event
      });
      await popover.present();
      const detail = await popover.onDidDismiss();
      if (detail.data && detail.data.hasOwnProperty('service')) {
        resolve(detail.data.service);
      } else {
        reject('No service selected');
      }
    });
  }

  private initFacebook() {
    const win: any = window;
    // initialise facebook sdk after it loads if required
    if (!win.fbAsyncInit) {
      win.fbAsyncInit = () => {
        win.FB.init({
          appId: '552626831832660',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.1'
        });
      };
    }

    // load facebook sdk if required
    const url = 'https://connect.facebook.net/en_US/sdk.js';
    if (!document.querySelector(`script[src='${url}']`)) {
      const script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    }
  }

  private initPinterest() {
    // load pinterest sdk if required
    const url = 'https://assets.pinterest.com/js/pinit.js';
    if (!document.querySelector(`script[src='${url}']`)) {
      const script = document.createElement('script');
      script.src = url;
      script['data-pin-build'] = 'parsePins';
      document.body.appendChild(script);
    }
  }

  private initTwitter() {
    // load twitter sdk if required
    const url = 'https://platform.twitter.com/widgets.js';
    if (!document.querySelector(`script[src='${url}']`)) {
      const script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    }
  }

  private shareWithApp(title: string, url: string, text: string, image?: string) {
    // sharing from app using socialsharing plugin
    const options: any = {
      message: text, // not supported on some apps (Facebook, Instagram)
      subject: `${text} ${title}`, // fi. for email
      url
    };
    if (image) {
      options.files = image;
    }
    Share.share(options).then(() => {
      // Success!
    }).catch(() => {
      // Error!
    });
  }

  /**
   * A Tour or Poi can be shared via facebook, twitter, pinterest, email or link
   */
  private async shareBrowser(title: string, url: string, text: string,
                             service: string, category: string, image?: string) {
    switch (service) {
      case 'facebook':
        this.shareOnFacebook(title, url, text);
        break;
      case 'twitter':
        this.shareOnTwitter(title, url, text);
        break;
      case 'pinterest':
        this.pinOnPinterest(title, url, text, image);
        break;
      case 'email':
        this.shareByEmail(title, url, text, category);
        break;
      case 'link':
        await this.copyTourLink(url);
        break;
      case 'embed':
        await this.showEmbedDialog(url);
        break;
      default:
        console.log('service not supported: ' + service);
    }
  }

  private async showEmbedDialog(url) {
    const popover = await this.popoverCtrl.create({
      component: EmbedPopoverComponent,
      componentProps: {url},
      cssClass: 'embed-popover'
    });
    await popover.present();
  }

  /**
   * A Tour or Poi can be shared via a url
   */
  private async copyTourLink(url) {
    const popover = await this.popoverCtrl.create({
      component: LinkPopoverComponent,
      componentProps: {url},
      cssClass: 'link-popover'
    });
    await popover.present();
  }
}
