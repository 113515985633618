import {Component, OnInit} from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';
import {Tour} from '../../../../lib/types/radrevier-ruhr';
import {EventsService} from '../../../../services/events.service';

@Component({
  selector: 'app-tour-download-popover',
  templateUrl: './tour-download-popover.page.html',
  styleUrls: ['./tour-download-popover.page.scss'],
})
export class TourDownloadPopoverPage implements OnInit {

  tour: Tour;

  constructor(
    private events: EventsService,
    private navParams: NavParams,
    private popoverCtrl: PopoverController
  ) {
  }

  ngOnInit() {
    this.tour = this.navParams.get('tour');
  }

  /**
   * In the popover you can choose to download the tour as a GPX or KML track
   */
  async downloadTour(format: 'gpx+xml' | 'vnd.google-earth.kml+xml') {
    this.events.publish('tour:download', {tour: this.tour, format});
    await this.popoverCtrl.dismiss();
  }

}
