import {Injectable} from '@angular/core';
import {BusStop, Category, Item, Poi, PoiBrief, PoiTag} from '../lib/types/radrevier-ruhr';
import {LngLat, LngLatBounds, Popup, supported} from 'maplibre-gl';
import {ActionSheetController, Platform, PopoverController} from '@ionic/angular';
import {BusStopService} from './busstop.service';
import {DataService, DataType} from './data.service';
import {EventsService} from './events.service';
import {GlobalsService} from './globals.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MapService} from './map.service';
import {PositionService} from './position.service';
import {RoutingService} from './routing.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from './util.service';
import {ItemListEntry} from '../components/item-list/item-list.component';
import {Constants} from '../var/constants';
import * as turf from '@turf/turf';
import {NavigationExtras, Router} from '@angular/router';
import {Position} from '@capacitor/geolocation';
import {RoutingPoint} from './routing.service';

@Injectable({
  providedIn: 'root'
})
export class PoisService {

  mobile: boolean;
  poiCategories: Category[];
  webGlSupported: boolean = supported();
  poiCategoryCodes: number[] = Constants.categories;
  busStopCode = Constants.busStopCategory;
  nodeCategory = Constants.nodeCategory;

  actionSheet: HTMLIonActionSheetElement;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private busStopService: BusStopService,
    private dataService: DataService,
    private events: EventsService,
    private globals: GlobalsService,
    private http: HttpClient,
    private mapService: MapService,
    private popoverCtrl: PopoverController,
    private positionService: PositionService,
    private router: Router,
    private routingService: RoutingService,
    private translate: TranslateService,
    private util: UtilService,
    private platform: Platform
  ) {
    this.mobile = platform.is('mobile') && !platform.is('tablet');
    events.subscribe('map:poi-selection-change', (changed: Category) => this.setSelectedCategories({changed}));
    events.subscribe('poi:selection-change', (params) => this.showSelectedCategoriesInMap(params));
    events.subscribe('poi:removed-all', () => this.deselectAllCategories());
  }

  async setSelectedCategories(params: { entries?: ItemListEntry[]; changed?: Category, targetLocation?: RoutingPoint }) {
    let categories;
    if (params.entries) {
      categories = params.entries.map(entry => entry.category);
      this.globals.set('poiCategories', categories);
    } else {
      categories = this.globals.get('poiCategories');
    }

    let selectedCategories = this.globals.get('selected-poi-categories');
    if (!selectedCategories || selectedCategories.length === 0) {
      selectedCategories = [params.changed.code];
    } else {
      const index = selectedCategories.indexOf(params.changed.code);
      if (index !== -1) {
        selectedCategories.splice(index, 1);
      } else {
        selectedCategories.push(params.changed.code);
      }
    }
    if (selectedCategories.length !== 0) {
      this.globals.set('selected-poi-categories', selectedCategories);
    } else {
      this.globals.remove('selected-poi-categories');
    }
    if (window.innerWidth >= 1024) {
      this.events.publish('poi:selection-change', {categories, changed: params.changed});
    } else {
      const currentRoute = this.globals.get('currentRoute', '/start');
      if (currentRoute !== '/karte' && !currentRoute.includes('/tourenplaner')) {
        const options: any = {};
        if (params.changed.code === this.busStopCode) {
          options.showBusStops = true;
        } else {
          options.selectedCategoryCode = params.changed.code;
          const selectedEntry = params.entries.find(entry => entry.category.code === params.changed.code);
          if (selectedEntry.items.length > 0) {
            options.selectedPois = selectedEntry.items;
          }
        }
        if (params.targetLocation) { options.targetLocation = params.targetLocation; }
        await this.router.navigate(['/karte'], {state: options});

      } else {
        this.events.publish('poi:selection-change', {categories, changed: params.changed});
      }
    }
    console.log(selectedCategories);
    // if (code) {
    //   this.poisProvider.showPoiCategoryInMap(code, this.poiList);
    //   this.globals.set('selected-poi-category', code);
    // } else {
    //   this.mapService.clearMarkers();
    //   this.globals.remove('selected-poi-category');
    // }
  }

  removeSelectedCategories() {
    this.globals.remove('selected-poi-categories');
    // this.deselectAllCategories();
  }

  async updateBusStops() {
    this.mapService.rmBusStopLayer();
    const categories: Category[] = this.globals.get('poiCategories') as Category[];
    if (categories.find(category => category.code === this.busStopCode && category.selected)) {
      await this.showPoiCategoriesInMap([this.busStopCode]);
    }
  }

  async showSelectedCategoriesInMap(params?: any) {
    this.mapService.rmPoiClusterLayers(['pois', 'nodes']);
    this.mapService.rmBusStopLayer();
    const categories: Category[] = this.globals.get('poiCategories') as Category[];
    if (categories) { // if categories are not loaded, there are no selected categories
      const categorycodes: number[] = categories
        .filter(category => category.selected === true)
        .map(category => category.code);
      if (params && params.hasOwnProperty('fitBounds')) {
        await this.showPoiCategoriesInMap(categorycodes, params.fitBounds);
      } else {
        await this.showPoiCategoriesInMap(categorycodes);
      }
    }
  }

  /**
   * Retrieves POIs by category codes and displays them in the map
   */
  async showPoiCategoriesInMap(categorycodes: number[], zoomTo: boolean = false) {
    const pois: Poi[] = await this.getPois();
    const indexNodes = categorycodes.indexOf(this.nodeCategory);
    if (indexNodes !== -1) {
      categorycodes.splice(indexNodes, 1);
      const nodes = pois.filter(poi => poi.categorycodes.indexOf(this.nodeCategory) !== -1);
      this.mapService.addPoiClusterByCategory([this.nodeCategory], nodes, 'nodes', true, 25, this.onNodeClick.bind(this));
    }
    const indexBusStops = categorycodes.indexOf(this.busStopCode);
    if (indexBusStops !== -1) {
      categorycodes.splice(indexBusStops, 1);
      const busStops = this.busStopService.getBusStops();
      this.mapService.addBusStopLayer(busStops, this.onBusStopClick.bind(this), zoomTo);
    }
    let matchingPois = pois.filter(poi => {
      const matchingCode = poi.categorycodes.find(code => categorycodes.indexOf(code) !== -1);
      return matchingCode !== undefined;
    });

    console.log('retrieving tour pois in pois service');
    const tourPois: Poi[] = this.globals.get('selected-tour-pois');

    if (tourPois) {
      matchingPois = matchingPois.concat(tourPois);
      const tourPoisCategories = this.getCategoriesFromPois(tourPois);
      categorycodes = categorycodes.concat(tourPoisCategories);
    }

    const poisTags = this.globals.get('pois-tags');
    
    if (poisTags) {
      const selectedTags = poisTags.filter(function(tag) {
        return tag.selected;
      }).map(function(tag) { return tag.name; });

      console.log('received pois tags in pois service', poisTags);
      if (selectedTags.length > 0) {
        matchingPois = matchingPois.filter(poi => {
          if (poi.categorycodes.includes(3000)) {
            return true;
          } else {
            return selectedTags.some(tag => poi.localized.de.tagsList.includes(tag));
          }
        });
      }      
    }
    if (matchingPois.length > 0) {
      const selectedPoi = await this.globals.get('selected-poi-id');
      const fitBounds = (this.mobile && !selectedPoi && categorycodes.length === 1 && zoomTo) ? true : false;
      this.mapService.addPoiClusterByCategory(categorycodes, matchingPois, 'pois', true, 50, this.onPoiClick.bind(this), fitBounds);
    }
  }

  getCategoriesFromPois(pois) {
    const mapped = pois.map(poi => poi.categorycodes);
    const merged = [].concat(...mapped);
    const unique = Array.from(new Set<number>(merged));
    // might influence how the markers are clustered
    // if (unique.indexOf(this.nodeCategory) !== -1) {
    //   unique.splice(unique.indexOf(this.nodeCategory), 1);
    // }
    return unique;
  }

  async getPoiTags(categories) {
    let tags = [];
    let pois = [];
    if (categories && categories.length > 0) {
      for(var item of categories) {
        pois = pois.concat(item.items);
      }
      for(var item of pois){
        tags = tags.concat(item.localized['de'].tagsList);
      }
  
      let uniqueTags = [...new Set(tags)];
      const tagObjects = uniqueTags.map(tag => ({name: tag, selected: false}) as PoiTag);
      // console.log('unique tags', tagObjects);
      return tagObjects;
    } else {
      return [];
    }
    
  }

  async showPoisInMap(pois?: Poi[], categorycodes?: number[], fitBounds: boolean = false) {
    if (!pois) {
      pois = await this.getPois();
    }
    if (!categorycodes) {
      categorycodes = this.getCategoriesFromPois(pois);
    }
    this.mapService.addPoiClusterByCategory(categorycodes, pois, 'pois', true, 50, this.onPoiClick.bind(this), fitBounds);
  }

  async onBusStopClick(event) {
    console.log('busStop clicked');
    const uuid = event.features[0].properties.uuid;
    await this.showBusStopDetails(uuid);
  }

  async onNodeClick(event) {
    const id = event.features[0].properties.id;
    const pois = await this.getPois();
    const poi = pois.find(p => p.id === id);
    this.events.publish('map:click-node', {poi, event});
  }

  async onPoiClick(event, poi: Poi) {
    const currentRoute = this.globals.get('currentRoute', '/start');
    const selectedPoiId = this.globals.get('selected-poi-id');
    if (!poi) {
      const feature = event.features[0];
      const pois = await this.getPois();
      poi = pois.find(p => p.id === feature.properties.id);
    }
    const showPoiInfo = currentRoute !== '/tourenplaner' || poi.categorycodes[0] !== this.nodeCategory;
    if (showPoiInfo) {
      const buttonPlanTourText = await this.translate.get('pages.pois.plan-tour').toPromise();
      let header;
      let subHeader;
      if (poi.categorycodes[0] === this.nodeCategory) {
        subHeader = this.util.getTranslation(poi).name;
        header = await this.translate.get('categories.' + poi.categorycodes[0]).toPromise();
        const nodeItems = this.util.getTranslation(poi).description.split('-');
        header += ` ${nodeItems[nodeItems.length - 1]}`;
      } else {
        header = this.util.getTranslation(poi).name;
        subHeader = await this.translate.get('categories.' + poi.categorycodes[0]).toPromise();
        if (poi.categorycodes.length > 1) {
          for (let i = 1; i < poi.categorycodes.length; i++) {
            const categoryTitle = await this.translate.get('categories.' + poi.categorycodes[i]).toPromise();
            subHeader = subHeader + ', ' + categoryTitle;
          }
        }

      }
      if (this.mobile) {
        const buttons = [
        //   {
        //   cssClass: 'button-routing',
        //   text: buttonPlanTourText,
        //   handler: () => {
        //     if (currentRoute !== '/tourenplaner') {
        //       this.routingService.useInRouting(poi, null, 'destination');
        //     } else {
        //       this.routingService.useInRouting(poi, null);
        //     }
        //   }
        // }
      ];
        if (poi.categorycodes[0] !== this.nodeCategory && selectedPoiId !== poi.id) {
          buttons.push({
            cssClass: 'button-details',
            text: 'Details',
            handler: () => {
              this.showPoiDetails(poi, 'map');
            }
          });
        } else {
          buttons[0].cssClass += ' single-button';
        }

        this.actionSheet = await this.actionSheetCtrl.create({
          header,
          subHeader,
          cssClass: 'poi-preview',
          buttons
        });
        await this.actionSheet.present();
      } else {
        const coordinates = poi.geom.coordinates;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let platform = 'md';
        if (this.platform.is('ios')) {
          platform = 'ios';
        }

        let html = `<div class="poi-marker-popup-header">
                                <div class="poi-marker-popup-title">${header}</div>
                                <div class="poi-marker-popup-subtitle">${subHeader}</div>
                            </div>
                            <ion-footer class="footer footer-${platform}">
                                <ion-toolbar class="toolbar toolbar-${platform}">
                                    <div class="toolbar-background toolbar-background-${platform}"></div>
                                    <ion-buttons class="bar-buttons bar-buttons-${platform}" slot="end">`;
                                        // <ion-button class="button-routing bar-button bar-button-${platform}
                                        // bar-button-default bar-button-default-${platform}
                                        // bar-button-${platform}-primary" color="primary"> ` + buttonPlanTourText + ` </ion-button>`

        if (poi.categorycodes[0] !== this.nodeCategory && selectedPoiId !== poi.id) {
          html += `<ion-button
                                class="button-details bar-button bar-button-${platform} bar-button-default bar-button-default-${platform}
                                bar-button-${platform}-secondary" color="secondary">
                                Details
                            </ion-button>`;
        }
        html += `</ion-buttons>
                            </ion-toolbar>
                        </ion-footer>`;

        const popup = new Popup({closeButton: false, className: 'poi-marker-popup'})
          .setLngLat(coordinates)
          .setHTML(html)
          .addTo(this.mapService.map);

        const popupEl = popup.getElement();
        // popupEl.getElementsByClassName('button-routing')[0].addEventListener('click', (e: MouseEvent) => {
        //   e.stopPropagation();
        //   e.preventDefault();
        //   this.routingService.useInRouting(poi, e).then(() => {
        //     popup.remove();
        //   });
        // });

        if (poi.categorycodes[0] !== this.nodeCategory && selectedPoiId !== poi.id) {
          popupEl.getElementsByClassName('button-details')[0].addEventListener('click', (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            this.showPoiDetails(poi);
            popup.remove();
          });
        }
      }
    }
  }

  /**
   * Navigates to PoiDetails view, providing a given POI as NavParam. Optionally, an origin can be provided.
   * If the map is visible, current map bounds will be saved in Globals provider, so they can be restored when
   * navigating back.
   */
  async showPoiDetails(poi: PoiBrief, origin?: string) {
    if (window.innerWidth >= 1024 && this.webGlSupported) {
      // save current map bounds
      this.globals.set('previous-bounds', this.mapService.getBbox());
    }
    if (poi.categorycodes.indexOf(this.busStopCode) !== -1) {
      await this.showBusStopDetails(poi.uuid);
    } else {
      const extras: NavigationExtras = {};
      if (origin) {
        extras.queryParams = {origin};
      }
      await this.router.navigate(['/ort-details/' + poi.id], extras);
    }
  }

  async showBusStopDetails(uuid: string) {
    await this.router.navigate(['/haltestelle/' + uuid]);
  }

  getPoiDetails(id: number): Promise<any> {
    return this.http.get(Constants.URL_POI + id).toPromise();
  }

  getAvailableBicycles(id: number): Promise<any> {
    return this.http.get(Constants.URL_NEXTBIKE + '?place=' + id).toPromise();
  }

  async getPoiCategories(quiet: boolean = false): Promise<Category[]> {
    let categories = this.globals.get('poiCategories') as Category[];
    if (!categories) {
      categories = await this.dataService.getData(DataType.poiCategories, quiet);

      // why?
      // categories = categories.filter((category) => {
      //     return category.code !== 381276;
      // });
      // categories.push({
      //   code: this.busStopCode,
      //   localized: {
      //     de: {
      //       packet: 'main',
      //       category: 'main',
      //       function: 'Haltestelle'
      //     }
      //   }
      // });
      this.globals.set('poiCategories', categories);
      this.events.publish('poi:categories-loaded');
    }
    return categories;
  }

  async getAllPois(): Promise<ItemListEntry[]> {
    const pois = await this.getPois();
    const emptyList = await this.createPoiList();
    const list = this.sortPoisIntoList(pois, emptyList);
    this.globals.set('poiList', list);
    return list;
  }

  /**
   * The current location of the user is used to calculate the POIs in the immediate vicinity.
   */
  async getPoisByPosition(center, radius?: number, includeBusStops: boolean = false): Promise<ItemListEntry[]> {
    if (radius && radius < 11000 && center) {
      // const center = [position.lng, position.lat];
      // const center = [7.4002233, 51.5079705]; // dortmund mitte
      // create 5km circle around user position
      const circle = turf.circle(center, radius, {steps: 16, units: 'meters'});
      // get all pois and convert to featurecollection
      const pois = await this.getPois();
      const points = turf.featureCollection(pois.map((poi: Poi) => turf.point(poi.geom.coordinates, {poi})));
      // get features within circle
      const pointsWithin = turf.pointsWithinPolygon(points, circle);
      let poiList: ItemListEntry[];
      if (pointsWithin.features.length > 0) {
        // save distance for each poi
        pointsWithin.features.forEach(feature => {
          // calculate distance to center and save as poi property
          feature.properties.poi.distance = turf.distance(center, feature.geometry, {units: 'meters'});
        });
        // extract pois stored as feature attribute
        const poisWithin = pointsWithin.features.map(feature => feature.properties.poi);
        const emptyList = await this.createPoiList();
        poiList = this.sortPoisIntoList(poisWithin, emptyList);
        // order by distance
        poiList.forEach(category => {
          category.sortation = {
            sortBy: 'DISTANCE',
            direction: 'ASC'
          };
          category.items.sort((a, b) => a.distance < b.distance ? -1 : 1);
        });
        this.globals.set('poiList', poiList);
      } else {
        poiList = [];
      }
      // include busStops if requested
      if (includeBusStops && radius) {
        const busStops: BusStop[] = await this.busStopService.getBusStopsByPosition(center, radius);
        busStops.forEach(feature => {
          // calculate distance to center and save as busStop property
          feature.distance = turf.distance(center, feature.geom, {units: 'meters'});
        });
        poiList = this.sortPoisIntoList(busStops, poiList);
      }
      return poiList;
    } else {
      return this.getAllPois();
    }
  }

  /**
   * The current location of the user is used to query the POIs in the immediate vicinity.
   *
   * @deprecated
   */
  async getPoisByPositionRemote(position: Position): Promise<ItemListEntry[]> {
    const coords = position.coords.latitude + ',' + position.coords.longitude;
    const options = {
      params: new HttpParams()
        .set('position', coords)
        .set('maxdistance', '8000')
    };
    const pois = (await this.http.get(Constants.URL_POIS, options).toPromise() as any).content;
    let list;
    if (pois.length > 0) {
      const emptyList = await this.createPoiList();
      list = this.sortPoisIntoList(pois.content, emptyList);
      this.globals.set('poiList', list);
      return list;
    } else {
      list = await this.getAllPois();
      return list;
    }
  }

  /**
   * POIs within a BBOX are queried by the server.
   * Only the pois that are within the current map section are displayed.
   */
  async getPoisByBounds(bounds: LngLatBounds, includeBusStops: boolean = false): Promise<ItemListEntry[]> {
    const ne: LngLat = bounds.getNorthEast();
    const sw: LngLat = bounds.getSouthWest();

    const pois = await this.getPois();
    const poisInBounds = pois.filter((poi: PoiBrief) => {
      const [lng, lat] = poi.geom.coordinates;
      return lng <= ne.lng && lng >= sw.lng && lat <= ne.lat && lat >= sw.lat;
    });

    let poiList = await this.createPoiList();
    poiList = this.sortPoisIntoList(poisInBounds, poiList);

    if (includeBusStops) {
      const busStops: BusStop[] = await this.busStopService.getBusStopsByBounds(bounds);
      poiList = this.sortPoisIntoList(busStops, poiList);
    }

    return poiList;
  }

  async createPoiList(quiet: boolean = false): Promise<ItemListEntry[]> {
    const list = [];
    const categories = await this.getPoiCategories(quiet);
    // categories.sort((a: Category, b: Category) => {
    //   const aName = this.util.getTranslation(a).function.toLowerCase();
    //   const bName = this.util.getTranslation(b).function.toLowerCase();
    //
    //   if (aName > bName) {
    //     return 1;
    //   } else if (aName < bName) {
    //     return -1;
    //   } else {
    //     return 0;
    //   }
    // });
    for (const category of categories) {
      category.icon = category.code.toString(10);
      const entry: ItemListEntry = {category, items: [], type: 'POI'};
      if (category.code === this.busStopCode) {
        entry.visibleWhenEmpty = true;
        entry.mouseoverText = 'pages.pois.busstop-hover';
      }
      list.push(entry);
    }
    return list;
  }

  async checkCategoryActive(categoryCode: number): Promise<boolean> {
    const categories = this.globals.get('poiCategories') as Category[];
    const category = categories.find(cat => cat.code === categoryCode);
    // console.log('is category selected?', category.selected, category.userSelected);
    return category.selected;
  }

  async setCategories(categoryCodes: number[], active: boolean, publishEvent: boolean = true, fitBounds: boolean = true) {
    let categories: Category[];
    const doSet = () => {
      const setCategories = categories.filter(cat => categoryCodes.indexOf(cat.code) !== -1);
      setCategories.forEach(category => {
        if ((active && !category.selected) // select category if not selected
          || !active && !category.userSelected) { // deselect category if category has not been selected by the user
          category.selected = active;
          category.userSelected = false;
        }
      });

      this.globals.set('poiCategories', categories);
      if (publishEvent) {
        this.events.publish('poi:selection-change', {categories, changed: categoryCodes, fitBounds: fitBounds});
      }
    };

    categories = (this.globals.get('poiCategories') as Category[]);
    if (!categories) {
      this.events.subscribe('poi:categories-loaded', () => {
        categories = (this.globals.get('poiCategories') as Category[]);
        doSet();
      });
    } else {
      doSet();
    }
  }

  async deselectAllCategories(publishEvent: boolean = true) {
    let categories: Category[];
    const categoryCodes = [];

    const deselect = () => {
      const setCategories = categories.filter(cat => categoryCodes.indexOf(cat.code) !== -1);
      categories.forEach(category => {
        if (category.selected !== false) {
          categoryCodes.push(category.code);
        }
        category.selected = false;
      });

      this.globals.set('poiCategories', categories);
      if (publishEvent) {
        this.events.publish('poi:selection-change', {categories, changed: categoryCodes});
      }
    };

    categories = (this.globals.get('poiCategories') as Category[]);
    if (!categories) {
      this.events.subscribe('poi:categories-loaded', () => {
        categories = (this.globals.get('poiCategories') as Category[]);
        deselect();
      });
    } else {
      deselect();
    }

  }

  private async getPois(): Promise<Poi[]> {
    const pois = await this.dataService.getData(DataType.pois) as Poi[];
    console.log('fetched ' + pois.length + ' pois');
    pois[0].categorycodes = [3003]; // TODO Remove
    pois.forEach(item => {
      if (item.localized['de'].tags.length === 0) {
        item.localized['de'].tagsList = [];
      } else {
        item.localized['de'].tagsList = item.localized['de'].tags.split(/(?:,|;)+/);
      }
    });
  
    // if (includeBusStops) { 
    //   const featureCollection = await this.busStopService.getBusStops();
    //   console.log('fetched ' + featureCollection.features.length + ' busStops');
    //   featureCollection.features.forEach(feature => {
    //     if (feature.geometry) {
    //       pois.push({
    //         uuid: feature.properties['id'],
    //         categorycodes: [this.busStopCode],
    //         localized: {
    //           de: {
    //             name: feature.properties['name']
    //           }
    //         },
    //         geom: {
    //           type: 'Point',
    //           coordinates: feature.geometry.coordinates as [number, number]
    //         }
    //       });
    //     }
    //   });
    // }
    console.log('total: ' + pois.length);
    return pois;
  }

  private sortPoisIntoList(pois: any, list: ItemListEntry[]): ItemListEntry[] {
    let filteredPoi = [];

    // filter the pois if a list of pois that are to be shown was sent
    const requestedIDs = this.globals.get('requestedPoiIDs');
    if (requestedIDs !== undefined && requestedIDs.length > 0) {
      requestedIDs.forEach(id => {
        const foundpoi = pois.filter(el => el.id === id);
        if (foundpoi.length > 0) {
          filteredPoi.push(foundpoi[0]);
        }
      });
    } else {
      filteredPoi = pois;
    }

    // sort pois into categories
    filteredPoi.forEach((poi: PoiBrief) => {
      if (poi.categorycodes.length > 0) {
        // The list with poi-categories is empty, so every single poi must be assigned to a category.
        // Every poi has a specific category code, this code is also deposited in the poi-categories list.
        // Once the matching entry has been found in the categorie list, the poi will be added to the category.
        poi.categorycodes.forEach((code: number) => {
          // Returns the value of the first element in the array where predicate is true, and undefined otherwise.
          const poiEntry = list.find(entry => entry.category.code === code);
          if (poiEntry) {
            poiEntry.items.push(poi);
          }
        });
      }
    });
    // sort all entries alphabetically ascending
    list.forEach((entry: ItemListEntry) => {
      if (!entry.sortation) {
        entry.items.sort((a: Item, b: Item) => {
          let aName; let bName;
          if (entry.category.code === this.nodeCategory) {
            aName = this.util.getTranslation(a).description;
            bName = this.util.getTranslation(b).description;
          } else {
            aName = this.util.getTranslation(a).name;
            bName = this.util.getTranslation(b).name;
          }

          return aName < bName ? -1 : 1;
        });
        entry.sortation = {sortBy: 'NAME', direction: 'ASC'};
      }
    });
    return list;
  }
}
