import {Injectable} from '@angular/core';
import {LngLat, LngLatBounds, supported} from 'maplibre-gl';
import {AlertController, ModalController, Platform, PopoverController} from '@ionic/angular';
import {DataService, DataType} from './data.service';
import {EventsService} from './events.service';
import {GlobalsService} from './globals.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MapService} from './map.service';
import {MatomoTracker} from 'ngx-matomo';
import {PoisService} from './pois.service';
import {PositionService} from './position.service';
import {SocialService} from './social.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user.service';
import {UtilService} from './util.service';
import {Poi, Tour, TourBrief} from '../lib/types/radrevier-ruhr';
import {Constants} from '../var/constants';
import * as turf from '@turf/turf';
import {ListPopoverComponent} from '../components/list-popover/list-popover.component';
import {SharePopoverComponent} from '../components/share-popover/share-popover.component';
import {TourDownloadPopoverPage} from '../pages/tour-details/popovers/tour-download-popover/tour-download-popover.page';
import {Router} from '@angular/router';
import {SaveCustomTourModalPage} from '../pages/routing/modals/save-custom-tour-modal/save-custom-tour-modal.page';
import {Location} from '@angular/common';
import {StorageService} from './storage.service';
import {Position} from '@capacitor/geolocation';

import {Directory, Encoding, Filesystem, WriteFileResult} from '@capacitor/filesystem';
import {Capacitor} from '@capacitor/core';
import {Share} from '@capacitor/share';
import {Device} from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class ToursService {

  webGlSupported: boolean = supported();

  constructor(
    private alertCtrl: AlertController,
    private dataService: DataService,
    private events: EventsService,
    private globals: GlobalsService,
    private http: HttpClient,
    private location: Location,
    private mapService: MapService,
    private matomoTracker: MatomoTracker,
    private modalCtrl: ModalController,
    private platform: Platform,
    private poisService: PoisService,
    private positionService: PositionService,
    private popoverCtrl: PopoverController,
    private router: Router,
    private social: SocialService,
    private storage: StorageService,
    private translate: TranslateService,
    private userService: UserService,
    private util: UtilService
  ) {
  }

  async addTour(tour: Tour, fitBounds: boolean = false,
                includeStartEnd: boolean = false, includeMarkers: boolean = false, custom: boolean = false,
                savePreviousBounds: boolean = true) {
    if (window.innerWidth >= 1024 && this.webGlSupported && savePreviousBounds) {
      // save current map bounds
      this.globals.set('previous-bounds', this.mapService.getBbox());
    }
    const mode = custom === true ? 'exceptRouting' : 'all';
    this.mapService.clear(mode);
    let id: string;
    if (tour && tour.id) {
      id = tour.id.toString();
    } else {
      id = 'usertour';
    }
    const tourLayer = {
      id,
      type: 'line',
      source: {
        type: 'geojson',
        data: tour.geom
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#008ac4',
        'line-opacity': 1.0,
        'line-width': {
          base: 1.5,
          stops: [
            [1, 0.5],
            [8, 4],
            [15, 8],
            [22, 10]
          ]
        },
      }
    };
    const direction = {
      id: 'direction-layer',
      type: 'symbol',
      source: {
        type: 'geojson',
        data: tour.geom
      },
      layout: {
        'symbol-placement': 'line-center',
        // 'symbol-spacing': 200,
        'icon-allow-overlap': true,
        // 'icon-ignore-placement': true,
        'icon-image': 'direction_indicator',
        'icon-size': 0.4,
        visibility: 'visible'
      }
    };
    this.mapService.addLayer(tourLayer, fitBounds, ['toursbefore']);
    this.mapService.addLayer(direction, false, ['toursbefore']);
    if (includeMarkers) {
      if (tour.poi && tour.poi.length >= 1) {
        await this.poisService.showPoisInMap(tour.poi);
      }
    }
    if (includeStartEnd) {
      // add start/end markers after pois so they will be on top
      if (tour.geomstart) {
        this.mapService.addMarker(tour.geomstart.coordinates as [number, number], 'glyph-A', {
          clickHandler: null,
          draggable: false,
          type: 'routing',
          zoomTo: false
        });
      }
      if (tour.geomend
        && tour.geomend.coordinates[0] !== tour.geomstart.coordinates[0]
        && tour.geomend.coordinates[1] !== tour.geomstart.coordinates[1]) {
        this.mapService.addMarker(tour.geomend.coordinates as [number, number], 'glyph-B', {
          clickHandler: null,
          draggable: false,
          type: 'routing',
          zoomTo: false
        });
      }
    }
  }

  async getTourDetails(tourId: number, userTour: boolean = false): Promise<Tour> {
    const doGet = async () => {
      try {
        tour = await this.http.get(Constants.URL_TOUR + tourId).toPromise() as Promise<Tour>;
      } catch (e) {
        if (e.status >= 500 && e.status < 600) {
          this.translate.get('services.util.server-down-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
          });
        } else if (e.status === 404) {
          this.translate.get('services.util.no-tour-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
            this.router.navigate(['/touren/highlights'], { replaceUrl: true });
          });
        } else {
          await this.util.handleErrorMsg(e);
        }
      }
    };

    let tour: Tour;
    if (userTour) {
      const user = await this.userService.getUser();
      if (user.unregistered) {
        tour = user.mytours.find(myTour => myTour.id === tourId);
      } else {
        await doGet();
      }
    } else {
      await doGet();
    }
    if (tour && tour.poi && tour.poi.length >= 1) {
      // move highlight pois to top of list
      const highlights: Poi[] = [];
      const regulars: Poi[] = [];
      tour.poi.forEach(poi => {
        if (poi.type === 'HIGHLIGHT') {
          highlights.push(poi);
        } else {
          regulars.push(poi);
        }
      });
      tour.poi = highlights.concat(regulars);
    }
    return tour;
  }

  async getTours(
    types?: ('COMMUNITY' | 'CUSTOM' | 'HIGHLIGHT' | 'NORMAL')[],
    orderBy: 'name' | 'distance' = 'name',
    radius?: number,
    center?
  ): Promise<Tour[]> {
    console.log('getTours: ' + types + ' ' + orderBy + ' ' + radius);
    let tours = await this.dataService.getData(DataType.tours) as Tour[];

    if (types && types.length > 0) {
      tours = tours.filter(tour => types.indexOf(tour.type) !== -1);
    }
    if (orderBy === 'name' || radius === 11000 || radius === null) {
      tours = this.orderByName(tours);
      // console.log(tours);
    } else {
      try {
        tours = await this.orderByDistance(tours, radius, center);
      } catch (e) {
        console.log(e);
        tours = this.orderByName(tours);
      }
    }
    return tours;
  }

  async getToursByBounds(
    types?: ('COMMUNITY' | 'CUSTOM' | 'HIGHLIGHT' | 'NORMAL')[],
    orderBy: 'name' | 'distance' = 'name',
    bounds?: LngLatBounds
  ): Promise<Tour[]> {
    const ne: LngLat = bounds.getNorthEast();
    const sw: LngLat = bounds.getSouthWest();
    
    const tours = await this.getTours(types, orderBy);
    const toursInBounds = tours.filter((tour: TourBrief) => {
      const linestring = tour.geom.coordinates;
      return linestring.some(([lng, lat]) => lng <= ne.lng && lng >= sw.lng && lat <= ne.lat && lat >= sw.lat);
    });
    return toursInBounds;
  }

  orderByName(tours: Tour[]): Tour[] {
    return tours.sort((a: Tour, b: Tour) => {
      const nameA = this.util.getTranslation(a).name.toUpperCase().trim();
      const nameB = this.util.getTranslation(b).name.toUpperCase().trim();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  async orderByDistance(tours: Tour[], radius?: number, center?) {
    // 1. get user position
    console.log('orderByDistance', radius);
    // let position: Position;
    // try {
    //   position = await this.positionService.getPosition();
    // } catch (e) {
    //   position = this.globals.get('lastKnownPosition').position;
    // }

    if (center) {
      console.log(center);
      // 2. get distance for each tour to user position (getDistanceToPoint in tours-provider)
      tours.forEach(tour => tour.distance = this.getDistanceToPoint(tour, center));
      if (radius) {
        tours = tours.filter(tour => tour.distance <= radius);
      }
      // 3. sort by distance ascending
      return tours.sort((a: Tour, b: Tour) => a.distance - b.distance);
    } else {
      return tours;
    }
  }

  async getNearTours(lngLat: LngLat, maxdistance: number = 5000): Promise<Tour[]> {
    const center = [lngLat.lng, lngLat.lat];
    // create 5km circle around user position
    const circle = turf.circle(center, maxdistance, {steps: 16, units: 'meters'});
    // get all tours
    let tours = await this.dataService.getData(DataType.tours);
    // convert each tour to a set of points and intersect with circle
    tours = tours.filter((tour: Tour) => {
      const points = turf.explode(tour.geom);
      const pointsWithin = turf.pointsWithinPolygon(points, circle);
      return pointsWithin.features.length > 0;
    });
    if (tours.length >= 1) {
      const highlightTours: Tour[] = [];
      const normalTours: Tour[] = [];
      const communityTours: Tour[] = [];
      tours.forEach(tour => {
        if (tour.type === 'HIGHLIGHT') {
          highlightTours.push(tour);
        } else if (tour.type === 'COMMUNITY') {
          communityTours.push(tour);
        } else {
          normalTours.push(tour);
        }
      });
      tours = highlightTours.concat(normalTours).concat(communityTours);
    }
    return tours;
  }

  /**
   * @deprecated
   */
  async getNearToursRemote(lngLat: LngLat, maxdistance: number = 1500): Promise<Tour[]> {
    const position = `${lngLat.lat},${lngLat.lng}`;
    const params = {position, maxdistance: maxdistance.toString()};
    return (await this.http.get(Constants.URL_TOURS, {params}).toPromise() as any).content;
  }

  getDistanceToPoint(tour: Tour, center): number {
    // const point = turf.point([7.4002233, 51.5079705]);
    const point = turf.point(center);
    const line = turf.lineString(tour.geom.coordinates);

    return turf.pointToLineDistance(point, line, {units: 'meters'});
  }

  /**
   * A tour is downloaded either as a GPX or as a KML track.
   */
  public async downloadTour(tour: Tour, contentType: 'gpx+xml' | 'vnd.google-earth.kml+xml'): Promise<any> {
    this.matomoTracker.trackEvent('tour', 'download:' + contentType, 'id', Number.parseInt(tour.id, 10));
    if (Capacitor.isNativePlatform()) {
      let folderDefinition, folderString;
      const info = await Device.getInfo();
      let newAndroidVersion: boolean;
      if (info.operatingSystem == 'android' && parseInt(info.osVersion) > 9) {
          console.log('Using "External" for new Android versions; scoped applications folder');
          folderDefinition = Directory.External;
          folderString = 'Daten';
          newAndroidVersion = true;
        } else {
          console.log('Using External storage for old Android versions; resolves to Documents directory on iOS');
          folderDefinition = Directory.ExternalStorage;
          folderString = 'Download';
          newAndroidVersion = false;
        }

      try {
        const [url, name] = await this.downloadTourApp(tour, contentType, folderDefinition);
        await this.showAfterDownloadDialog(url, name, folderString, contentType, newAndroidVersion);
      } catch (e) {
        console.log(e);
      }
    } else {
      return this.downloadTourBrowser(tour, contentType);
    }
  }

  /**
   * With dev.eftas server - 401 Unauthorized
   */
  async getCustomRoute(tourId) {
    const user = await this.storage.get('user');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Basic ' + btoa(user.username + ':' + user.password));
    return this.http.get(Constants.URL_CUSTOM_ROUTE + '/' + tourId, {headers}).toPromise();
  }

  /**
   * Encoding for the correct form data
   */
  getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property of toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  async showTourMenu(tour: Tour, event: MouseEvent, origin?: string) {
    const listItems = [];
    const userTour = await this.userService.isOwnTour(tour.id);
    const userLoggedIn = await this.userService.isLoggedIn();
    // if (Capacitor.isNativePlatform()) {
    // const labelAdjust = await this.translate.get('pages.tour-details.menu-edit-tour').toPromise();
    // listItems.push({
    //   id: 'adjust',
    //   label: labelAdjust
    // });
    if ((this.platform.is('ios') || this.platform.is('android')) && tour.navigationinstructions !== null) {
      const labelNav = await this.translate.get('pages.tour-details.menu-navigate-tour').toPromise();
      listItems.push({
        id: 'navigate',
        label: labelNav
      });
    }
    // }
    if (tour.status === 'ACTIVE') {
      const labelShare = await this.translate.get('pages.tour-details.menu-share').toPromise();
      listItems.splice(1, 0, {
        id: 'share',
        label: labelShare
      });
    }
    if (tour.status === 'ACTIVE' || userTour && userLoggedIn) {
      const labelExport = await this.translate.get('pages.tour-details.menu-export').toPromise();
      listItems.splice(2, 0, {
        id: 'download',
        label: labelExport
      });
    }
    if (userTour) {
      const labelEdit = await this.translate.get('pages.tour-details.edit-tour').toPromise();
      listItems.push({
        id: 'edit',
        label: labelEdit
      });
      const deleteLabel = await this.translate.get('pages.user-tours.prompt-confirm-delete-title').toPromise();
      listItems.push({
        id: 'delete',
        label: deleteLabel
      });
    }
    const popover = await this.popoverCtrl.create({
      component: ListPopoverComponent,
      componentProps: {listItems},
      event
    });
    await popover.present();
    const detail = await popover.onDidDismiss();
    if (detail.data) {
      await this.onPopoverItemSelect(detail.data, tour, event, origin);
    }
  }

  async onPopoverItemSelect(data: any, tour: Tour, event, origin?: string) {
    if (data && data.id) {
      switch (data.id) {
        case 'share':
          await this.shareTour(tour, event);
          break;
        case 'download':
          await this.createPopoverChooseFormat(tour, 'download', event);
          break;
        case 'adjust':
          await this.startNavigation(tour, false, true, origin);
          break;
        case 'navigate':
          await this.startNavigation(tour, true, false, origin);
          break;
        case 'edit':
          await this.editCustomTour(tour);
          break;
        case 'delete':
          await this.deleteCustomTour(tour);
          break;
      }
    }
  }

  async shareTour(tour: Tour, event) {
    let service: string;
    if (Capacitor.isNativePlatform()) {
      await this.social.shareInterest(tour);
    } else {
      try {
        service = await this.social.getServiceFromPopover(tour, event);
        await this.social.shareInterest(tour, service);
      } catch (e) {
        console.log(e);
      }
    }
  }

  /**
   * The Popover is a view that floats above an app’s content.
   * Popovers provide an easy way to present or gather information from the user.
   * The user can choose between the formats kml and gpx
   */
  async createPopoverChooseFormat(tour, typeOf: string, event?) {
    let options: any;
    if (typeOf === 'download') {
      options = {
        component: TourDownloadPopoverPage,
        componentProps: {tour}
      };
    } else {
      options = {
        component: SharePopoverComponent,
        componentProps: tour
      };
    }
    if (event) {
      options.event = event;
    }
    const popover = await this.popoverCtrl.create(options);
    await popover.present();
  }

  async editCustomTour(tour: Tour) {
    const modal = await this.modalCtrl.create({
      component: SaveCustomTourModalPage,
      componentProps: {tour, name: tour.localized.de.name}
    });
    await modal.present();
    const detail = await modal.onDidDismiss();
    if (detail.data) {
      await this.saveCustomTour(detail.data);
    }
  }

  async saveCustomTour(tour: Tour) {
    const showErrorAlert = async () => {
      const header = await this.translate.get('services.error.title').toPromise();
      const subHeader = await this.translate.get('services.error.unknownError').toPromise();
      const btnCloseText = await this.translate.get('shared.close').toPromise();
      const alert = await this.alertCtrl.create({
        header,
        subHeader,
        buttons: [btnCloseText]
      });
      await alert.present();
    };

    // save tour in user profile
    await this.userService.saveTourChanges(tour);
    try {
      await this.dataService.update(false, DataType.tours);
      const button = event.target as HTMLButtonElement;
      button.disabled = true;
      await this.showSuccessAlert(tour.localized.de.name);
      this.events.publish('usertour:edited', tour);
    } catch (error) {
      if (error.message) {
        console.log(error.message);
        await showErrorAlert();
      }
    }
  }

  async showSuccessAlert(title: string) {
    const header = await this.translate.get('pages.routing.alert-save-success-title').toPromise();
    const message = await this.translate.get('pages.routing.alert-save-success-msg', {title}).toPromise();
    const btnCloseText = await this.translate.get('shared.close').toPromise();
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [btnCloseText]
    });
    await alert.present();
  }

  async deleteCustomTour(tour) {
    const header = await this.translate.get('pages.user-tours.prompt-confirm-delete-title').toPromise();
    const subHeader = await this.translate.get('pages.user-tours.prompt-confirm-delete-msg', {
      title: tour.localized.de.name
    }).toPromise();
    const btnCancel = await this.translate.get('shared.cancel').toPromise();
    const btnDelete = await this.translate.get('pages.user-tours.delete').toPromise();

    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      buttons: [{
        text: btnCancel,
        role: 'cancel'
      }, {
        text: btnDelete,
        handler: () => {
          this.proceedDeleteTour(tour);
        }
      }]
    });
    await alert.present();
  }

  async proceedDeleteTour(tour: Tour) {
    this.userService.deleteCustomTour(tour)
      .then(async () => {
        // After a successful deletion, go back to the previous page.
        const header = await this.translate.get('pages.user-tours.prompt-confirm-delete-title').toPromise();
        const msg = await this.translate.get('pages.user-tours.success-delete-msg', {
          title: tour.localized.de.name
        }).toPromise();
        const successAlert = await this.alertCtrl.create({
          header: header,
          subHeader: msg,
          buttons: ['OK']
        });
        await successAlert.present();
        await this.location.back();
      })
      .catch(async () => {
        const msg = await this.translate.get('pages.user-tours.error-delete-msg').toPromise();
        await this.util.handleErrorMsg({message: msg});
      });
  }

  async showSimpleTour(geom) {
    this.mapService.rmSimpleTour();
    const tourLayer = {
      id: 'simple-tour',
      type: 'line',
      source: {
        type: 'geojson',
        data: geom
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#008ac4',
        'line-opacity': 1.0,
        'line-width': {
          base: 1.5,
          stops: [
            [1, 0.5],
            [8, 4],
            [15, 8],
            [22, 10]
          ]
        },
      }
    };
    this.mapService.addSimpleTour(tourLayer);
  }

  /**
   * Work in progress, to be used in routing to automatically determine intermediate points of round tours
   */
  getIntermediatePoints(tour: Tour, amount: number = 2): number[][] {
    const coordinates = [];
    const step = Math.floor(tour.geom.coordinates.length / (amount + 1));
    for (let i = 1; i <= amount; i++) {
      coordinates.push(tour.geom.coordinates[step * amount]);
    }
    return coordinates;
  }

  private async downloadTourBrowser(tour: Tour, contentType: 'gpx+xml' | 'vnd.google-earth.kml+xml'): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('Accept', `application/${contentType}`);
      const ext = contentType === 'gpx+xml' ? '.gpx' : '.kml';
      this.http.get(Constants.URL_TOUR + tour.id, {headers, responseType: 'text'}).subscribe(response => {
        const file = new Blob([response], {type: `application/${contentType}`});
        const url = window.URL.createObjectURL(file);
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = url;
        downloadLink.download = this.util.getTranslation(tour).name + ext;
        downloadLink.click();
        window.URL.revokeObjectURL(url);
        resolve(null);
      }, error => {
        if (error.status >= 500 && error.status < 600) {
          this.translate.get('services.util.server-down-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
          });
        } else {
          this.util.handleErrorMsg(error);
        }
        // reject(error);
      });
    });
  }

  private async downloadTourApp(tour: Tour, contentType: 'gpx+xml' | 'vnd.google-earth.kml+xml', downloadFolder) {
    // retrieve content from api
    const headers = new HttpHeaders().set('Accept', `application/${contentType}`);
    let response;
    try {
      response = await this.http.get(Constants.URL_TOUR + tour.id, {headers, responseType: 'text'}).toPromise();
    } catch (e) {
      console.error('Unable to download content', e);
    }

    // write content to file
    const name = this.util.getTranslation(tour).name;
    const ext = contentType === 'gpx+xml' ? '.gpx' : '.kml';
    const info = await Device.getInfo();
    let filepath;
    if (info.operatingSystem == 'android' && parseInt(info.osVersion) < 10) {
      // create base dir (will not overwrite if already existing)
      const dir = 'Download';
      try {
        await Filesystem.mkdir({
          path: dir,
          directory: downloadFolder,
          recursive: false,
        });
      } catch (e) {
        console.error('Unable to make directory', e);
      } finally {
        filepath = dir + '/' + name + ext;
      }
    } else {
      filepath = name + ext;
    }

    try {
      const result = await Filesystem.writeFile({
        path: filepath,
        data: response,
        directory: downloadFolder,
        encoding: Encoding.UTF8
      });
      return [result.uri, name];
    } catch (e) {
      console.error('Unable to write to file', e);
    }
  }

  private async showAfterDownloadDialog(url: string, name: string, folder: string, contentType: string, newAndroid: boolean) {
    const ext = contentType === 'gpx+xml' ? 'GPX' : 'KML';
    const alertMessage = newAndroid === true ? 'Die Tour ' + name + ' steht bereit.' : 'Die Tour ' + name + ' wurde im Verzeichnis ' + folder + ' gespeichert.';
    const alert = await this.alertCtrl.create({
      header: ext + ' verfügbar',
      message: alertMessage,
      buttons: [{
        text: 'Schließen',
        role: 'cancel'
      }, {
        text: 'Teilen',
        handler: () => {
          this.openDownloadedFile(url, name);
        }
      }]
    });
    await alert.present();
  }

  private async openDownloadedFile(url: string, name: string) {
    await Share.share({
      url,
      dialogTitle: name,
    });
  }

  private async startNavigation(tour: Tour, immediate: boolean = false, edit: boolean = false, origin?: string) {
    const doStart = async () => {
      await this.router.navigate(['/tourenplaner/' + tour.id], {queryParams: {immediate, edit}});
    };

    this.globals.set('user-tour-adjusting', edit);
    if (origin === 'map') {
      this.location.back();
      window.setTimeout(() => doStart(), 500);
    } else {
      await doStart();
    }
  }
}
