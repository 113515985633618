import {Injectable} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {EventsService} from './events.service';
import {GlobalsService} from './globals.service';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../var/constants';
import {StorageService} from './storage.service';

import {Geolocation, Position, WatchPositionCallback} from '@capacitor/geolocation';

import {registerPlugin} from '@capacitor/core';
import {BackgroundGeolocationPlugin, CallbackError, Location} from '@capacitor-community/background-geolocation';

import {ReplaySubject} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BackgroundGeolocation = registerPlugin('BackgroundGeolocation') as BackgroundGeolocationPlugin;

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  geolocationSubject: ReplaySubject<GeolocationPosition>;
  watcherId;

  backgroundGeolocationSubject: ReplaySubject<GeolocationPosition>;
  backgroundWatcherId;

  requestPending = false;


  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
    private globals: GlobalsService,
    private platform: Platform,
    private storage: StorageService,
    private translate: TranslateService,
  ) {
  }

  /**
   * Retrieves the user's position
   */
  async getPosition(timeout?: number): Promise<Position> {
    timeout = timeout || 5000;
    try {
      return await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout,
        maximumAge: 30000
      });
    } catch (e) {
      if (this.platform.is('ios')) {
        const header = await this.translate.get('services.position.ios-enable-location-title').toPromise();
        const subHeader = await this.translate.get('services.position.ios-enable-location-message').toPromise();
        const btnClose = await this.translate.get('shared.close').toPromise();
        const alert = await this.alertCtrl.create({
          header,
          subHeader,
          buttons: [{
            text: btnClose,
            role: 'cancel'
          }]
        });
        await alert.present();
      }
      console.error(e);
    }
  }

  /**
   * Watches the user's position
   */
  watchPosition(): ReplaySubject<GeolocationPosition> {
    if (!this.watcherId) {
      this.geolocationSubject = new ReplaySubject<GeolocationPosition>(1);
      Geolocation.watchPosition(Constants.GEOLOCATION_OPTIONS, (position: Position, err?: any) => {
        if (err) {
          console.log(err);
        } else if (position) {
          this.geolocationSubject.next(position);
        }
      }).then(watcherId => this.watcherId = watcherId);
    }
    return this.geolocationSubject;
  }

  clearWatch() {
    if (this.watcherId) {
      Geolocation.clearWatch({id: this.watcherId}).then(() => this.watcherId = null);
    }
  }

  startBackgroundWatch(): ReplaySubject<GeolocationPosition> {
    if (!this.backgroundWatcherId) {
      let backgroundTitle = '';
      this.translate.get('services.navigation.bg-notification-title')
        .subscribe(translation => backgroundTitle = translation);
      let backgroundMessage = '';
      this.translate.get('services.navigation.bg-notification-text')
        .subscribe(translation => backgroundMessage = translation);

      this.backgroundGeolocationSubject = new ReplaySubject<GeolocationPosition>(1);
      BackgroundGeolocation.addWatcher({
        // If the "backgroundMessage" option is defined, the watcher will
        // provide location updates whether the app is in the background or the
        // foreground. If it is not defined, location updates are only
        // guaranteed in the foreground. This is true on both platforms.

        // On Android, a notification must be shown to continue receiving
        // location updates in the background. This option specifies the text of
        // that notification.
        backgroundMessage,

        // The title of the notification mentioned above. Defaults to "Using
        // your location".
        backgroundTitle,

        // Whether permissions should be requested from the user automatically,
        // if they are not already granted. Defaults to "true".
        requestPermissions: true,

        // If "true", stale locations may be delivered while the device
        // obtains a GPS fix. You are responsible for checking the "time"
        // property. If "false", locations are guaranteed to be up to date.
        // Defaults to "false".
        stale: false,

        // The minimum number of metres between subsequent locations. Defaults
        // to 0.
        distanceFilter: 0
      }, (location: Location, error: CallbackError) => {
        const geolocation: GeolocationPosition = {
          coords: {
            accuracy: location.accuracy,
            altitude: location.altitude,
            altitudeAccuracy: location.altitudeAccuracy,
            heading: location.bearing,
            latitude: location.latitude,
            longitude: location.longitude,
            speed: location.speed
          },
          timestamp: location.time
        };

        if (error) {
          if (error.code === 'NOT_AUTHORIZED') {
            if (window.confirm(
              'This app needs your location, ' +
              'but does not have permission.\n\n' +
              'Open settings now?'
            )) {
              // It can be useful to direct the user to their device's
              // settings when location permissions have been denied. The
              // plugin provides the 'openSettings' method to do exactly
              // this.
              BackgroundGeolocation.openSettings();
            }
          }
          console.error(error);
        }
        this.backgroundGeolocationSubject.next(geolocation);
      }).then(watcherId => this.backgroundWatcherId = watcherId);
    }
    return this.backgroundGeolocationSubject;
  }

  stopBackgroundWatch() {
    if (this.backgroundWatcherId) {
      BackgroundGeolocation.removeWatcher({id: this.backgroundWatcherId}).then(() => this.backgroundWatcherId = null);
    }
  }
}
