import {Injectable} from '@angular/core';
import {Poi, Tour} from '../lib/types/radrevier-ruhr';
import {GeocodingService, MarkerInfo} from './geocoding.service';
import * as turf from '@turf/turf';
import {Geometry} from 'geojson';
import {EventsService} from './events.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PositionService} from './position.service';
import {UtilService} from './util.service';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../var/constants';
import {Position} from '@capacitor/geolocation';

export class SearchOptions {
  includePois: boolean;
  includeTours: boolean;
  includeAddresses: boolean;
  minChars: number;
  namesOnly: boolean;
  favorNear: boolean;
}

export class SearchResults {
  pois?: Poi[];
  tours?: Tour[];
  addresses?: MarkerInfo[];
}

export class AbstractSearchResult {
  name: string;
  geom: Geometry;
  highlight: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  position: Position;
  waitingForPos = false;

  constructor(
    private events: EventsService,
    private geocoding: GeocodingService,
    private http: HttpClient,
    private positionService: PositionService,
    private util: UtilService,
    private translate: TranslateService,
  ) {
  }

  async search(val: any, options: SearchOptions = {
    includePois: true, includeTours: true, includeAddresses: true, minChars: 3,
    namesOnly: false, favorNear: false
  }): Promise<SearchResults> {
    if (options.favorNear) {
      // start acquiring user position now to save time later
      this.waitingForPos = true;
      this.positionService.getPosition().then(position => {
        this.position = position;
        this.events.publish('search:position');
        this.waitingForPos = false;
      }).catch(reason => {
        console.log(reason);
        this.events.publish('search:position');
        this.waitingForPos = false;
      });
    }

    const result = {pois: [], tours: [], addresses: []};
    let value;
    if (typeof val !== 'string' && val.length && val.length > 0) {
      value = val[0];
    } else if (typeof val === 'string') {
      value = val;
    }
    if (value) {
      if (value.trim() !== '' && value.length >= options.minChars) {
        if (options.includePois) {
          result.pois = await this.searchPois(value, options.namesOnly, options.favorNear);
        }

        if (options.includeTours) {
          result.tours = await this.searchTours(value, options.favorNear);
        }

        if (options.includeAddresses) {
          result.addresses = await this.searchAddresses(value, false, options.favorNear);
        }
      }
      return result;
    }
  }

  public async searchAddresses(term: string, restrictToName: boolean = false, favorNear?: boolean): Promise<MarkerInfo[]> {
    let addresses: MarkerInfo[] = [];
    try {
      addresses = await this.geocoding.search(term);
      if (restrictToName) {
        const terms = UtilService.removeUmlaute(term.toLowerCase()).split(/\W+/);
        addresses = addresses.filter(address => {
          // @ts-ignore
          for (let [key, value] of Object.entries(address.result.components)) {
            value = UtilService.removeUmlaute((value as string).toLowerCase());
            if (terms.every(part => (value as string).indexOf(part) !== -1)) {
              return true;
            }
          }
          return false;
        });
      }
      for (const markerInfo of addresses) {
        const name = markerInfo.displayName;
        const highlight = false;
        const geom = markerInfo.geom;
        markerInfo.significance = await this.getResultSignificance({name, highlight, geom}, term, favorNear);
      }
    } catch (e) {
      console.log('error searching for addresses containing: ', term.toLowerCase());
    }
    return addresses;
  }

  private async searchPois(term: string, restrictToName: boolean = false, favorNear?: boolean): Promise<Poi[]> {
    const params = new HttpParams().set('q', term.toLowerCase());
    let pois: Poi[] = [];
    let url = Constants.URL_SEARCH_POIS;
    if (restrictToName) {
      url = Constants.URL_SEARCH_POIS_TITLE;
    }
    try {
      const response: any = await this.http.get(url, {params}).toPromise();
      if (response.hasOwnProperty('content')) {
        pois = response.content;
        if (restrictToName) {
          const terms = UtilService.removeUmlaute(term).split(/\W+/);
          pois = pois.filter(poi => {
            const title = UtilService.removeUmlaute(this.util.getTranslation(poi).name);
            return terms.every(value => title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
          });
        }
        for (const poi of pois) {
          const name = this.util.getTranslation(poi).name;
          const highlight = poi.type === 'HIGHLIGHT';
          const geom = poi.geom;
          poi.significance = await this.getResultSignificance({name, highlight, geom}, term, favorNear);
        }
      }
    } catch (e) {
      if (e.status >= 500 && e.status < 600) {
        this.translate.get('services.util.server-down-error').subscribe(value => {
          this.util.handleErrorMsg({message: value});
        });
      } else {
        await this.util.handleServerError(e.error);
      }
      console.log('Error searching for POIs: ', e);
    }
    return pois;
  }

  private async searchTours(term: string, favorNear?: boolean): Promise<Tour[]> {
    const params = new HttpParams().set('q', term.toLowerCase());
    let tours: Tour[] = [];
    try {
      const response: any = await this.http.get(Constants.URL_SEARCH_TOURS, {params}).toPromise();
      if (response.hasOwnProperty('content')) {
        tours = response.content;
        for (const tour of tours) {
          const name = this.util.getTranslation(tour).name;
          const highlight = tour.type === 'HIGHLIGHT';
          const geom = tour.geom;
          tour.significance = await this.getResultSignificance({name, highlight, geom}, term, favorNear);
        }
      }
    } catch (e) {
      console.log('Error searching for Tours: ', e);
    }
    return tours;
  }

  private getResultSignificance(result: AbstractSearchResult, term: string, favorNear?: boolean): Promise<number> {
    const doGet = (): number => {
      // set initial significance of result by whether its name matches the term searched for
      let significance = !!result.name.match(new RegExp(term, 'i')) ? 1 : -1;
      if (result.highlight) {
        // increase significance of highlights
        significance++;
      }
      // favor near results
      if (favorNear && this.position) {
        const coords = [this.position.coords.longitude, this.position.coords.latitude];
        let nearestPoint;
        // Differentiate between pois and tours
        if (result.geom.type === 'Point') {
          nearestPoint = result.geom.coordinates;
        } else if (result.geom.type === 'LineString') {
          nearestPoint = turf.nearestPointOnLine(result.geom, coords);
        }
        if (nearestPoint) {
          // Calculate distance between geom and position
          const distance = turf.distance(coords, nearestPoint, {units: 'kilometers'});
          // increase significance of results within 5km radius decreasingly (0km = 2 increase, 5 km = 0 increase)
          // and decrease significance further on.
          if (distance > 0) {
            significance += 2 - 0.4 * distance;
          }
        }
      }
      return significance;
    };

    return new Promise<number>((resolve) => {
      if (favorNear && !this.position && this.waitingForPos) {
        this.events.subscribe('search:position', () => resolve(doGet()));
      } else {
        resolve(doGet());
      }
    });
  }
}
