import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Constants} from '../var/constants';
import {BusStop} from '../lib/types/radrevier-ruhr';
import {DataService} from './data.service';
import {LngLatBounds} from 'maplibre-gl';
import {Position} from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class BusStopService {

  headers = new HttpHeaders({
    accept: 'text/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  baseUrl = Constants.URL_PROXY + 'http://openservice.vrr.de/vrr/';
  busStops: BusStop[] = [];
  busStopCategory = Constants.busStopCategory;

  constructor(
    private dataService: DataService,
    private http: HttpClient
  ) {
  }

  /**
   *
   * @param pin
   * @param attr
   * @private
   */
  private static getAttribute(pin: any, attr: string): string {
    let value = '';
    if (pin.attrs) {
      const result = pin.attrs.find(attribute => attribute.name === attr);
      if (result.value) {
        value = result.value;
      }
    }
    return value;
  }

  getBusStops(): BusStop[] {
    return this.busStops;
  }

  async getBusStopsByBounds(bounds: LngLatBounds): Promise<BusStop[]> {
    const params = new HttpParams({
      fromObject: {
        jsonp: '',
        boundingBox: '',
        boundingBoxLU: `${bounds.getWest()}:${bounds.getSouth()}:WGS84[DD.DDDDD]`,
        boundingBoxRL: `${bounds.getEast()}:${bounds.getNorth()}:WGS84[DD.DDDDD]`,
        coordOutputFormat: 'WGS84[DD.DDDDD]',
        type_1: 'STOP',
        outputFormat: 'json',
        inclFilter: '1'
      }
    });
    return this.requestBusStops(params);
  }

  async getBusStopsByPosition(center, radius?: number): Promise<BusStop[]> {
    let busStops = [];
    if (radius <= 5000) {
      const lng = (Math.round(center[0] * 1e+5) / 1e+5).toString();
      const lat = (Math.round(center[1] * 1e+5) / 1e+5).toString();
      const params = new HttpParams({
        fromObject: {
          jsonp: '',
          itdLPxx_x: lng,
          itdLPxx_y: lat,
          itdLPxx_mapName: 'WGS84[DD.DDDDD]',
          coord: `${lng}:${lat}:WGS84[DD.DDDDD]`,
          coordOutputFormat: 'WGS84[DD.DDDDD]',
          radius_1: radius.toString(),
          type_1: 'STOP',
          outputFormat: 'json',
          inclFilter: '1'
        }
      });
      busStops = await this.requestBusStops(params);
    }
    return busStops;
  }

  async getBusStop(uuid: any): Promise<BusStop> {
    let busStop: BusStop = null;
    if (this.busStops) {
      // try to retrieve busStop from locally saved data
      busStop = this.busStops.find(stop => stop.uuid === uuid);
    }
    if (!busStop) {
      // otherwise try to get busStop from remote
      const params = new HttpParams({
        fromObject: {
          name_sf: uuid,
          type_sf: 'stopID',
          language: 'de',
          coordOutputFormat: 'WGS84',
          outputFormat: 'JSON'
        }
      });
      const response: any = await this.http.get(this.baseUrl + 'XSLT_STOPFINDER_REQUEST', {
        headers: this.headers,
        params
      }).toPromise();
      if (response.stopFinder && response.stopFinder.points && response.stopFinder.points.point) {
        const point = response.stopFinder.points.point;
        // set stop type
        const coordinates = point.ref.coords.split(',').map(coordinate => (Number(coordinate) / 1e+6));
        busStop = {
          uuid,
          id: point.ref.gid,
          categorycodes: [this.busStopCategory],
          localized: {
            de: {
              name: point.name
            }
          },
          geom: {
            type: 'Point',
            coordinates
          },
          stoptype: null,
          cached: false
        };
      }
    }
    return busStop;
  }

  async getBusStopSchedule(id: string) {
    console.log('id: ' + id);
    // fetch info by id from vrr api
    const body = new HttpParams({
      fromObject: {
        itdLPxx_id: id,
        name_dm: id,
        type_dm: 'stopID',
        mode: 'direct',
        limit: '5',
        language: 'de',
        useRealtime: '1',
        outputFormat: 'JSON'
      }
    });
    return this.http.post(this.baseUrl + 'XSLT_DM_REQUEST', body, {headers: this.headers}).toPromise();
  }

  private async requestBusStops(params: HttpParams): Promise<BusStop[]> {
    const response: any = await this.http.get(this.baseUrl + 'XSLT_COORD_REQUEST', {
      headers: this.headers,
      params
    }).toPromise();
    this.busStops = [];

    if (response.pins) {
      response.pins.forEach(pin => {
        // set stop type
        const id = BusStopService.getAttribute(pin, 'STOP_GLOBAL_ID');
        const name = BusStopService.getAttribute(pin, 'STOP_NAME_WITH_PLACE');
        const stoptype = BusStopService.getAttribute(pin, 'STOP_MAJOR_MEANS');
        const coordinates = pin.coords.split(',').map(coordinate => Number(coordinate));

        this.busStops.push({
          uuid: pin.id,
          id,
          categorycodes: [this.busStopCategory],
          localized: {
            de: {
              name
            }
          },
          geom: {
            type: 'Point',
            coordinates
          },
          stoptype,
          cached: true
        });
      });
    }
    return this.busStops;
  }

}
