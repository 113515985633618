import {Component, ViewChild} from '@angular/core';
import {IonList, NavParams, PopoverController} from '@ionic/angular';
import {List} from 'postcss/lib/list';

export class ListPopoverItem {
  id: any;
  label: string;
  cls?: string;
  disabled?: boolean;
  iconRight?: string;
  iconRightOnClick?: any;
  hasFocus?: boolean;
}

@Component({
  selector: 'app-list-popover',
  templateUrl: './list-popover.component.html',
  styleUrls: ['./list-popover.component.scss'],
})
export class ListPopoverComponent {

  @ViewChild(IonList) list: IonList;

  listItems: ListPopoverItem[];
  disabled: boolean;

  constructor(
    navParams: NavParams,
    private popoverCtrl: PopoverController
  ) {
    this.listItems = navParams.get('listItems');
    this.disabled = !!navParams.get('disabled');
  }

  async onSelect(item: ListPopoverItem) {
    if (!(this.disabled || item.disabled)) {
      await this.popoverCtrl.dismiss(item);
    }
  }

  async onKeyUp(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      const index = this.listItems.findIndex(listItem => listItem.hasFocus);
      if (event.key === 'ArrowUp') { // arrow up
        if (index > 0) {
          this.listItems[index].hasFocus = false;
        }
        if (index > 0) {
          // TODO shift focus
          // const listEl = this.list.getNativeElement() as HTMLElement;
          // const buttons = listEl.getElementsByTagName('button');
          // buttons.item(index - 1).focus();
          // this.listItems[index - 1].hasFocus = true;
        }
      } else if (event.key === 'ArrowDown') { // arrow down
        if (index !== -1 && index !== this.listItems.length - 1) {
          this.listItems[index].hasFocus = false;
        }
        if (index !== this.listItems.length - 1) {
          // TODO shift focus
          // const listEl = this.list.getNativeElement() as HTMLElement;
          // const buttons = listEl.getElementsByTagName('button');
          // buttons.item(index + 1).focus();
          // this.listItems[index + 1].hasFocus = true;
        }
      }
    }
  }

  onItemIconRightClick(event: MouseEvent, item: ListPopoverItem) {
    item.iconRightOnClick.call(this, event);
  }
}
