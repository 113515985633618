import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, Platform, ToastController} from '@ionic/angular';

import {TranslateService} from '@ngx-translate/core';
import {LineString, Point} from 'geojson';
import {GeoJSONSource, LngLat, Marker} from 'maplibre-gl';
import * as turf from '@turf/turf';

import {EventsService} from './events.service';
import {MapService} from './map.service';
import {PositionService} from './position.service';
import {UtilService} from './util.service';
import {
  Direction,
  NavigationInstruction,
  NavigationState,
  PositionBuffer,
  RoutingSolution,
  SnappedLocation
} from '../lib/types/radrevier-ruhr';
import {Constants} from '../var/constants';
import {KeepAwake} from '@capacitor-community/keep-awake';
import {Position} from '@capacitor/geolocation';
import {Capacitor} from '@capacitor/core';
import {Location} from '@capacitor-community/background-geolocation';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  arrowImageAdded = false;
  // Proximity distance in metres
  proximityDistance = 25;
  currentNodeAndPosition = {currentNode: 0, position: 'before', distance: undefined};
  state = NavigationState.OFF;
  positionBuffer: PositionBuffer = new PositionBuffer(2);
  audio: HTMLAudioElement;
  routeLeftTimeoutId: any;
  // the current segment of the route line string
  // TODO: Store in some class (currentNodeAndPosition)
  currentSegment = 0;

  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
    private http: HttpClient,
    private mapService: MapService,
    private platform: Platform,
    private positionService: PositionService,
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {
  }

  /**
   * Return the distance to the next node rounded to the nearest 10 metres
   */
  static roundedDistanceToNextNode(snappedPoint: Point, nextInstruction: Point, route: LineString): number {
    const distance = UtilService.distanceOnLineV2(snappedPoint, nextInstruction, route, 0);
    return Math.abs(Math.round(distance / 10) * 10);
  }

  /**
   * Returns the remaining distance to destination from a given position
   */
  static calculateRemainingDistance(lngLat: LngLat, route: RoutingSolution): number {
    console.log('calculating remaining distance');
    const point: Point = {
      type: 'Point',
      coordinates: [lngLat.lng, lngLat.lat]
    };
    const nearest = turf.pointOnLine(route.geom, point);
    const remainingRouteGeom: LineString = {
      type: 'LineString',
      coordinates: route.geom.coordinates.slice(nearest.properties.index)
    };
    const length = turf.length({type: 'Feature', geometry: remainingRouteGeom, properties: null}, {units: 'meters'});
    console.log('remaining distance', length);
    return length;
  }

  /**
   * Fill an array consisting of instruction indices together with their Euclidian distances to the snapped location.
   * Sort them after distance in ascending order.
   *
   */
  static getNearestInstructionsOnRouteEuclidian(snappedLngLat: LngLat, route: RoutingSolution) {
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]);
    const instructions = route.instructions;
    // Define an array of objects to store euclidian distances to each of the instructions
    const euclDistances: Array<{ instructionIndex: number; distance: number }> = [];
    for (let i = 0; i < instructions.length; i++) {
      const euclDistance = turf.distance(snappedPoint.geometry, instructions[i].point, {units: 'meters'});
      euclDistances[i] = {instructionIndex: i, distance: euclDistance};
    }
    const compareDistances = (dist1, dist2) => {
      if (dist1.distance > dist2.distance) {
        return 1;
      }
      if (dist1.distance < dist2.distance) {
        return -1;
      }
      return 0;
    };
    euclDistances.sort(compareDistances);
    return euclDistances;
  }


  /**
   */
  static filterSmallDistances(euclDistances: Array<{ instructionIndex: number; distance: number }>, n: number): Array<number> {
    const nearestInstructions: Array<number> = [];
    for (let i = 0; i < n; i++) {
      nearestInstructions[i] = euclDistances[i].instructionIndex;
    }
    return nearestInstructions;
  }

  /**
   * Find the nearest instruction to a snapped position.
   * Therefor compare the distances from the snapped position to a given set of instruction nodes.
   *
   * @deprecated Use new {@link getNearestInstructionOnRouteWithStartNode} method instead.
   */
  static getNearestInstructionOnRouteForSelectedInstructions(snappedLngLat: LngLat, route: RoutingSolution,
                                                             nearestInstructionsIdx: Array<number>): number {
    // const routeFeature: turf.helpers.Feature<LineString> = turf.lineString(route.geom.coordinates);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]);
    let nextInstructionIndex = 0;
    let minDistance = Infinity;
    const instructions = route.instructions;
    for (let i = 0; i < nearestInstructionsIdx.length; i++) {
      const positionToInstructionDist: number = Math.abs(UtilService.distanceOnLine(snappedPoint.geometry,
        instructions[nearestInstructionsIdx[i]].point, route.geom));
      if (positionToInstructionDist < minDistance) {
        minDistance = positionToInstructionDist;
        nextInstructionIndex = i;
      }
    }
    return nearestInstructionsIdx[nextInstructionIndex];
  }

  /**
   * Build verbose navigation instruction (necessary for routing page)
   * and short navigation instruction with audio files (necessary for turn-by-turn navigation).
   */
  async buildInstructions(instruction: NavigationInstruction, distance?: number) {
    if (instruction) {
      // First part is the distance
      instruction.shortTextPart1 = '';
      instruction.shortTextPart2 = '';
      if (distance !== undefined && distance >= 10) {
        instruction.shortTextPart1 += UtilService.capitalize(
          await this.translate.get('services.navigation.distance', {distance}).toPromise()
        );
      } else if (distance < 10) {
        instruction.shortTextPart1 += await this.translate.get('services.navigation.now').toPromise();
      }
      if (instruction.start === true) {
        // Start node
        instruction.shortTextPart1 = await this.translate.get('services.navigation.instruction-DEPART').toPromise();
        instruction.longText = await this.translate.get('services.navigation.instruction-DEPART').toPromise();
        instruction.iconcls = 'depart';
        instruction.audioFile = 'assets/audio/start.m4a';
      } else if (instruction.stop === true) {
        // End node
        instruction.shortTextPart1 = await this.translate.get('services.navigation.instruction-ABSTOP').toPromise();
        instruction.longText = await this.translate.get('services.navigation.instruction-ABSTOP').toPromise();
        instruction.iconcls = 'arrive';
        instruction.audioFile = 'assets/audio/end.m4a';
      } else {
        // Any other node
        // TODO: multilanguage audio files
        if (instruction.direction === Direction.STRAIGHT_AHEAD) {
          instruction.iconcls = 'continue';
          instruction.audioFile = 'assets/audio/straight_ahead.m4a';
        }
        if (instruction.direction === Direction.SLIGHT_LEFT) {
          instruction.iconcls = 'turn_slight_left';
          instruction.audioFile = 'assets/audio/slight_left.m4a';
        }
        if (instruction.direction === Direction.LEFT) {
          instruction.iconcls = 'turn_left';
          instruction.audioFile = 'assets/audio/left.m4a';
        }
        if (instruction.direction === Direction.SHARP_LEFT) {
          instruction.iconcls = 'turn_sharp_left';
          instruction.audioFile = 'assets/audio/sharp_left.m4a';
        }
        if (instruction.direction === Direction.BACKWARDS) {
          instruction.iconcls = 'continue_uturn';
        }
        if (instruction.direction === Direction.SHARP_RIGHT) {
          instruction.iconcls = 'turn_sharp_right';
          instruction.audioFile = 'assets/audio/sharp_right.m4a';
        }
        if (instruction.direction === Direction.RIGHT) {
          instruction.iconcls = 'turn_right';
          instruction.audioFile = 'assets/audio/right.m4a';
        }
        if (instruction.direction === Direction.SLIGHT_RIGHT) {
          instruction.iconcls = 'turn_slight_right';
          instruction.audioFile = 'assets/audio/slight_right.m4a';
        }
      }
      // Append street name if available
      if (instruction.name) {
        const streetName = await this.translate.get('services.navigation.on').toPromise() + instruction.name;
        instruction.shortTextPart2 += streetName;
        instruction.longText += streetName;
      } else if (instruction.direction &&
        instruction.direction !== Direction.STRAIGHT_AHEAD &&
        instruction.direction !== Direction.BACKWARDS) {
        const turn = await this.translate.get('services.navigation.turn').toPromise();
        instruction.shortTextPart2 += turn;
        instruction.longText += turn;
      }
      // Append distance to instruction
      if (distance !== undefined && distance >= 10) {
        const distanceText = await this.translate.get('services.navigation.distance', {distance}).toPromise();
        instruction.longText += ' ' + distanceText;
        if (instruction.stop === true) {
          instruction.shortTextPart2 = distanceText;
        }
      }
    }
  }

  /**
   * Await navigation entry at a certain location.
   * An awaited navigation turns active when the user approaches proximity to the route and this proximity is stable.
   *
   */
  async awaitNavigation(route: RoutingSolution, instructions: NavigationInstruction[]) {
    this.state = NavigationState.AWAITED;
    console.log('State: ' + this.state);

    if (Capacitor.isNativePlatform) {
      try {
        await KeepAwake.keepAwake();
      } catch (e) {
        console.log(e);
      }

      this.positionService.startBackgroundWatch()
        .subscribe((geolocation: GeolocationPosition) => this.navigate(route, geolocation, instructions));
    } else {
      this.positionService.watchPosition()
        .subscribe((geolocation: GeolocationPosition) => this.navigate(route, geolocation, instructions));
    }

    await this.setupNavigationView(route);

    // Reset instruction to pending instruction if instruction was set before
    const pendingInstructionText = 'Start in der Nähe der Tour!';
    const pendingInstruction = new NavigationInstruction(pendingInstructionText, 'depart');
    this.events.publish('navigation:update-instruction-panel', pendingInstruction, this.state);

    // Reset remaining distance if it was calculated before
    const distance = null;
    this.events.publish('navigation:remaining-distance', distance);
  }

  /**
   * React on changes of the user's location and navigate the user.
   *
   */
  async navigate(route: RoutingSolution, position: Position, instructions: NavigationInstruction[]) {
    if (position.coords !== undefined) {
      // FIXME: Do we still need the position buffer?
      this.positionBuffer.add(position);
      const currentLngLat = new LngLat(position.coords.longitude, position.coords.latitude);
      //const snappedLocation: SnappedLocation = await this.snapToRoute(position, route, this.proximityDistance);
      const snappedLocation: SnappedLocation = await this.snapToRouteV2(position, route, this.proximityDistance, this.currentSegment);
      // If user is near enough to snap to route
      if (snappedLocation !== null) {
        const snappedLngLat: LngLat = snappedLocation.lngLat;
        // Add navigation arrow to snapped position if snapped
        this.showOwnPosition(snappedLngLat);
        //const currentNodeAndPosition = await this.getCurrentNodeAndPosition(snappedLngLat, route, this.currentSegment);
        console.log('in navigate, calling getCurrentNodeAndPositionV2');
        const currentNodeAndPosition = this.getCurrentNodeAndPositionV2(snappedLngLat, route, this.currentSegment);
        if (this.state === NavigationState.LEFT || this.state === NavigationState.LEFT_TOLD) {
          // Clear timeout after which leaving of route is being warned
          clearTimeout(this.routeLeftTimeoutId);
        }
        const oldIndex: number = this.currentNodeAndPosition.currentNode;
        const newIndex: number = currentNodeAndPosition.currentNode;
        const oldPosition: string = this.currentNodeAndPosition.position;
        const newPosition: string = currentNodeAndPosition.position;
        if (oldIndex !== newIndex || oldPosition !== newPosition) {
          // Current index or position have changed since last position update, or if route has been left
          this.currentSegment = await this.getCurrentSegment(snappedLocation, route.geom.coordinates, this.currentSegment);
          console.log('Current node and/or position changed to: ' + JSON.stringify(currentNodeAndPosition)
            + '. Current segment number: ' + this.currentSegment);
          if (newPosition === 'on') {
            // Position has just changed from 'before' to 'on'
            console.log('Focus node ' + newIndex);
            this.preFocusNode(route, instructions[newIndex], snappedLngLat);
            if (this.state !== NavigationState.LEFT_TOLD) {
              this.focusNode(newIndex, instructions);
            }
            if (newIndex === 0) {
              console.log('Reset current segment when first navigation node is reached.');
              this.currentSegment = 0;
            }
            if (newIndex === instructions.length - 1) {
              // End navigation if last node was just played
              console.log('Last navigation node reached.');
              //await this.finishNavigation();
            }
          } else if (newPosition === 'before') {
            console.log('Prefocus node ' + newIndex);
            this.preFocusNode(route, instructions[newIndex], snappedLngLat);

          } else {
            console.error('Unknown position');
          }
        }
        // FIXME: Don't assign global varibales here, do it differently
        this.currentNodeAndPosition = currentNodeAndPosition;
        if (this.state === NavigationState.LEFT_TOLD) {
          // Route has just been reclaimed
          this.audio = UtilService.playAudio('assets/audio/route_reclaimed.m4a', this.audio);
        }
        if (this.state === NavigationState.LEFT || this.state === NavigationState.LEFT_TOLD
          || this.state === NavigationState.AWAITED || this.state === NavigationState.OFF) {
          // Switch state to active
          this.state = NavigationState.ACTIVE;
          console.log('State: ' + this.state);
        }
        const distance = NavigationService.calculateRemainingDistance(snappedLngLat, route);
        this.events.publish('navigation:remaining-distance', distance);
        // Update instruction with new distance
        const instruction: NavigationInstruction = instructions[currentNodeAndPosition.currentNode];
        await this.buildInstructions(instruction, currentNodeAndPosition.distance);
        this.events.publish('navigation:update-instruction-panel', instruction, this.state);
        this.events.publish('navigation:on-route');
      } else {
        // If user is away from the route add navigation arrow to current position
        this.showOwnPosition(currentLngLat);
        if (this.state === NavigationState.OFF) {
          // Switch state to 'awaited' to prevent multiple calls of the following method
          this.state = NavigationState.AWAITED;
          this.showNavigationNode(route, this.proximityDistance, instructions[0]);
        }
        if (this.state === NavigationState.ACTIVE) {
          // Switch state to 'left'
          this.state = NavigationState.LEFT;
          console.log('State: ' + this.state);
          console.warn('left route');
          this.routeLeftTimeoutId = setTimeout(async () => {
            // Only warn 20 seconds after state was set to 'left'
            this.state = NavigationState.LEFT_TOLD;
            const instruction = await this.translate.get('services.navigation.instruction-LEFT_ROUTE').toPromise();
            this.events.publish('navigation:update-instruction-panel',
              new NavigationInstruction(instruction), this.state);
            this.events.publish('navigation:left-route');
            this.audio = UtilService.playAudio('assets/audio/route_left.m4a', this.audio);
          }, 20000);
        }
      }
    }
  }

  /**
   * Show own position and orientation as an arrow.
   * The arrow is later rotated in map-component
   */
  public showOwnPosition(currentLngLat: LngLat) {
    if (!this.arrowImageAdded) {
      // Add own posion marker for the first time
      this.mapService.map.loadImage('https://i.imgur.com/REoK7Vo.png', (error, image) => {
        if (error) {
          throw error;
        }
        // ..add image to the map
        this.mapService.map.addImage('navigationArrow', image);
        // ..and reference it with a coordinate
        this.mapService.addLayer({
          id: Constants.LAYER_NAVIGATION_OWN_POSITION,
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [currentLngLat.lng, currentLngLat.lat]
                }
              }]
            }
          },
          layout: {
            'icon-image': 'navigationArrow',
            'icon-size': 0.5,
            'icon-rotation-alignment': 'map',
            'icon-rotate': 0,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true
          }
        });
      });
      this.arrowImageAdded = true;
    } else {
      // .. or update data any subsequent time
      const source = this.mapService.map.getSource(Constants.LAYER_NAVIGATION_OWN_POSITION) as GeoJSONSource;
      if (source !== undefined) {
        source.setData({
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [currentLngLat.lng, currentLngLat.lat]
            }
          }]
        });
        const rotation: number = this.positionBuffer.getRotation();
        console.log('Rotation: ' + rotation + '; Speed: ' + this.positionBuffer.getSpeed());
        if (this.positionBuffer.getSpeed() > 0.5 || this.positionBuffer.getSpeed() === null) {
          // Only change arrow rotation if last detected speed was more than 0.5 m/s (or if no speed data is availabe at all)
          this.mapService.map.setLayoutProperty(Constants.LAYER_NAVIGATION_OWN_POSITION, 'icon-rotate', rotation);
        }
      }
    }
    // Center map
    if (!this.mapService.timeoutPan) {
      this.mapService.map.setCenter(currentLngLat);
    }

  }

  /**
   * Setup the UI for navigation, e.g. generate texts for instructions.
   */
  public async setupNavigationView(route: RoutingSolution) {
    // Remove GeolocateControl to prevent disabling updates of user's location.
    this.events.publish('navigation:setup-navigation-view');
    // this.showNavigationNodes(route, 0.02);
    for (const instruction of route.instructions) {
      await this.buildInstructions(instruction);
    }
    // Disable dragging of marker
    this.mapService.markers.forEach((marker: Marker) => {
      marker.setDraggable(false);
    });
  }

  /**
   * Cancel ongoing navigation
   */
  public async cancelNavigation() {
    if (Capacitor.isNativePlatform()) {
      // Allow sleep again
      try {
        await KeepAwake.allowSleep();
        console.log('Allowed display to sleep again.');
      } catch (e) {
        console.log('Could not allow display to sleep again.');
      }

      this.positionService.stopBackgroundWatch();
    } else {
      this.positionService.clearWatch();
    }

    // Reset view
    this.events.publish('navigation:cancel-navigation-view');


    if (this.mapService.map.hasImage('navigationArrow')) {
      this.mapService.map.removeImage('navigationArrow');
    }
    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_LINES);
    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_HEADS);
    // Reset instruction node index
    this.currentNodeAndPosition = {currentNode: 0, position: 'before', distance: undefined};
    // Reset current segment
    this.currentSegment = 0;
    // Remove own position with rotated marker
    this.arrowImageAdded = false;
    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_OWN_POSITION);
    // Reset navigation state to 'off'
    if (this.state !== NavigationState.OFF) {
      this.state = NavigationState.OFF;
      console.log('State: ' + this.state);
      this.events.publish('navigation:update-instruction-panel', null, this.state);
    }
    // Disable dragging of marker
    this.mapService.markers.forEach((marker: Marker) => {
      marker.setDraggable(true);
    });
  }

  /**
   * Draw a single navigation node
   *
   */
  public showNavigationNode(route: RoutingSolution, size: number, instruction: NavigationInstruction): void {
    const routeGeometry: LineString = route.geom as LineString;
    const navigationNodeLocation = instruction.point;
    // const navigationNodeBuffer: turf.helpers.Feature<LineString> =
    //     UtilService.lineBuffer(routeGeometry, navigationNodeLocation, size);
    const navigationNodeBuffer: turf.helpers.Feature<LineString> =
      //UtilService.lineBufferUsingLineSliceAlong(routeGeometry, navigationNodeLocation, size);
      UtilService.lineBufferV3(routeGeometry, navigationNodeLocation, size, this.currentSegment);
    const navigationNodeBufferFeatures: turf.helpers.Feature<LineString>[] = [];
    navigationNodeBufferFeatures.push(navigationNodeBuffer);
    const navigationNodeBufferFeatureCollection = turf.featureCollection(navigationNodeBufferFeatures);

    this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_LINES);

    // Create the line part of the arrow
    const lines = {
      id: Constants.LAYER_NAVIGATION_ARROW_LINES,
      type: 'line',
      source: {
        type: 'geojson',
        data: navigationNodeBufferFeatureCollection
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#ffff00',
        'line-opacity': 1.0,
        'line-width': 5
      }
    };
    this.mapService.addLayerAfter(lines, undefined, Constants.LAYER_ROUTING_CUSTOM_ROUTE);

    // Collect the points where the arrow heads are directing
    const directedPointFeatures: turf.helpers.Feature<Point>[] = [];

    for (const bufferFeature of navigationNodeBufferFeatures) {
      // The directed point is the last point of the line string
      const lineString: LineString = bufferFeature.geometry as LineString;
      const directedPointCoordinates: number[] = lineString.coordinates[lineString.coordinates.length - 1];
      const directedPointFeature = turf.point(directedPointCoordinates);
      // The bearing is the angle between the last point and the next to last point
      if (lineString.coordinates.length >= 2) {
        const nextToLastPointCoordinates: number[] = lineString.coordinates[lineString.coordinates.length - 2];
        directedPointFeature.properties.bearing = turf.bearing(nextToLastPointCoordinates, directedPointCoordinates);
        directedPointFeature.properties.current = false;
        directedPointFeatures.push(directedPointFeature);
      }
    }
    const directedPointsFeatureCollection = turf.featureCollection(directedPointFeatures);

    this.mapService.map.loadImage('https://i.imgur.com/AQZMhcS.png', (error, image) => {
      if (error) {
        throw error;
      }
      // (Remove previously added image and layer)
      if (this.mapService.map.hasImage('arrowHead')) {
        this.mapService.map.removeImage('arrowHead');
      }
      this.mapService.removeLayer(Constants.LAYER_NAVIGATION_ARROW_HEADS);

      // ..add image to the map
      this.mapService.map.addImage('arrowHead', image);
      // ..and reference it with a coordinate
      const arrowHeads = {
        id: Constants.LAYER_NAVIGATION_ARROW_HEADS,
        type: 'symbol',
        source: {
          type: 'geojson',
          data: directedPointsFeatureCollection
        },
        layout: {
          'icon-image': 'arrowHead',
          'icon-size': 0.5,
          'icon-rotation-alignment': 'map',
          'icon-rotate': {
            type: 'identity',
            property: 'bearing',
            default: 90
          }
        }
      };
      this.mapService.addLayerAfter(arrowHeads, undefined, Constants.LAYER_ROUTING_CUSTOM_ROUTE);
    });
  }

  /**
   * The instruction panel is updated when the next navigation node is in range.
   */
  public preFocusNode(route: RoutingSolution, instruction: NavigationInstruction, snappedLngLat: LngLat) {
    this.showNavigationNode(route, this.proximityDistance, instruction);
    // Tells the routing page to reflect changes in current navigation instruction
    this.events.publish('navigation:update-instruction-panel', instruction, this.state);
    // Align map so that the node is up
    const snappedLngLatCoordinates: number[] = snappedLngLat.toArray();
    const instructionCoordinates: number[] = instruction.point.coordinates;
    const bearing = turf.bearing(snappedLngLatCoordinates, instructionCoordinates);
    // Change bearing of map if instruction point is far enough from snapped position
    const distance = turf.distance(snappedLngLatCoordinates, instructionCoordinates, {units: 'meters'});
    if (distance > this.proximityDistance) {
      this.mapService.map.flyTo({bearing});
      // Re-enable automatic centering
      this.mapService.cancelPanTimeout();
    }
  }

  /**
   * An audio is played when the navigation node is actually reached.
   */
  public focusNode(index: number, instructions: NavigationInstruction[]) {
    if (instructions[index].audioFile !== undefined) {
      this.audio = UtilService.playAudio(instructions[index].audioFile, this.audio);
    } else {
      this.audio = UtilService.playAudio('assets/audio/notification.mp3', this.audio);
    }
  }

  /**
   * Returns the index of the nearest instruction of a route
   */

  /*
  public getNearestInstruction(route: RoutingSolution, position: Geoposition) {
    const instructions: NavigationInstruction[] = route.instructions;
    const currentPosition = turf.point([position.coords.longitude, position.coords.latitude]);
    let nearestInstructionIndex = 0;
    let minDistance = Infinity;
    for (let i = 0; i < instructions.length; i++) {
      const distance = turf.distance(instructions[i].point.coordinates, currentPosition, {units: 'meters'});
      if (distance < minDistance) {
        nearestInstructionIndex = i;
        minDistance = distance;
      }
    }
    return nearestInstructionIndex;
  }
  */

  /**
   * Shows an alert message to inform the user that the end of the tour has been reached,
   * then publishes an event to notify the routing page.
   */
  async finishNavigation() {
    const header = await this.translate.get('services.navigation.finished-header').toPromise();
    const message = await this.translate.get('services.navigation.finished-message').toPromise();
    if (this.state !== NavigationState.OFF) {
      this.state = NavigationState.OFF;
      const alert = await this.alertCtrl.create({
        header,
        message,
        buttons: [{
          text: 'OK',
          handler: () => {
            this.events.publish('navigation:finished');
          }
        }]
      });
      await alert.present();
    }
  }

  /*
  private async subscribeToBackgroundPosition(route: RoutingSolution, instructions: NavigationInstruction[]) {
    /!*  Subscribe to the BACKGROUND and FOREGROUND event in order to
    use the background location plugin in background and
    unsubscribe from it when in foreground again. *!/
    await this.backgroundGeolocation.removeAllListeners(BackgroundGeolocationEvents.background);
    this.backgroundGeolocation.on(BackgroundGeolocationEvents.background)
      .subscribe(() => {
        console.log('[INFO] BackgroundGeolocation: background event fired');
        this.positionBackgroundSubscription = this.backgroundGeolocation
          .on(BackgroundGeolocationEvents.location)
          .subscribe(async (location: BackgroundGeolocationResponse) => {
            const geoposition = {
              coords:
                {
                  accuracy: location.accuracy,
                  altitude: location.altitude,
                  altitudeAccuracy: null,
                  heading: location.bearing,
                  latitude: location.latitude,
                  longitude: location.longitude,
                  speed: location.speed
                },
              timestamp: location.time
            };
            console.log('Obtained new position by background provider');
            await this.navigate(route, geoposition, instructions);
          }, error => {
            console.log(error);
          });
      });

    await this.backgroundGeolocation.removeAllListeners(BackgroundGeolocationEvents.foreground);
    this.backgroundGeolocation
      .on(BackgroundGeolocationEvents.foreground)
      .subscribe(() => {
        console.log('[INFO] BackgroundGeolocation: foreground event fired');
        if (this.positionBackgroundSubscription) {
          this.positionBackgroundSubscription.unsubscribe();
          this.positionBackgroundSubscription = null;
        }
      });
  }
  */

  /**
   * Find the nearest position on the route seen from the user's real position
   * and snap to that position if it is near enough.
   *
   */

  /*
  private async snapToRoute(realPosition: Geoposition, route: RoutingSolution, radius: number): Promise<SnappedLocation> {
    const line: LineString = route.geom;
    // const lineFeature: turf.helpers.Feature<LineString> = turf.lineString(line.coordinates);
    const point = turf.point([realPosition.coords.longitude, realPosition.coords.latitude]);
    const snappedPointFeature: turf.helpers.Feature<Point> = turf.nearestPointOnLine(line, point, {units: 'meters'});
    console.log('Distance to route: ' + snappedPointFeature.properties.dist);
    if (snappedPointFeature.properties.dist < radius) {
      const snappedPoint: Point = snappedPointFeature.geometry;
      if (UtilService.pointOnLine(snappedPoint, line)) {
        return new SnappedLocation(snappedPoint);
      } else {
        const toast = await this.toastCtrl.create({
          message: 'snapToRoute: Snapped point not located on route. This should never happen.',
          duration: 10000
        });
        await toast.present();
        throw Error('Snapped point not located on route. This should never happen.');
      }
    } else {
      return null;
    }
  }
  */

  /**
   * Find the nearest position on the route seen from the user's real position
   * and snap to that position if it is near enough.
   * Bear in mind the current segment to deal with partly overlapping segments.
   *
   */
  private async snapToRouteV2(realPosition: Position, route: RoutingSolution, radius: number,
                              currentSegmentNr: number): Promise<SnappedLocation> {
    const routeCoords = route.geom.coordinates;
    const point = turf.point([realPosition.coords.longitude, realPosition.coords.latitude]);
    // Initialize margins
    let lowerMargin: number = currentSegmentNr;
    let upperMargin: number = currentSegmentNr;
    const segmentCoordinates: number[][] = routeCoords.slice(currentSegmentNr, currentSegmentNr + 2);
    const segment = turf.lineString(segmentCoordinates, {name: 'line segment'}).geometry;
    // Try to snap on current segment
    let snappedLocation: SnappedLocation = null;
    const snappedPointFeature: turf.helpers.Feature<Point> = turf.nearestPointOnLine(segment, point, {units: 'meters'});
    if (snappedPointFeature.properties.dist < radius) {
      snappedLocation = new SnappedLocation(snappedPointFeature.geometry);
      return snappedLocation;
    }
    // Could not snap on current segment
    while (lowerMargin > 0 || upperMargin < routeCoords.length - 2) {
      // Search to the left
      // Decrement left margin segment if possible
      if (lowerMargin > 0) {
        lowerMargin--;
        snappedLocation = await this.snapToSegmentOfRoute(routeCoords, lowerMargin, point, radius);
      }
      if (upperMargin < routeCoords.length - 2 && snappedLocation === null) {
        // Search to the right
        // Increment right margin segment if possible
        upperMargin++;
        snappedLocation = await this.snapToSegmentOfRoute(routeCoords, upperMargin, point, radius);
      }
      if (snappedLocation !== null) {
        return snappedLocation;
      }
    }
    // Could not snap anywhere
    return null;
  }


  /**
   * Takes a number in the coordinates array of a route and tries to snap a given point to a segment
   * defined by this coordinates pair and the next coordinates pair.
   */
  private async snapToSegmentOfRoute(routeCoords: GeoJSON.Position[], arrayPosition: number,
                                     point: turf.helpers.Feature<turf.helpers.Point>, radius: number): Promise<SnappedLocation> {
    const segmentCoordinates: GeoJSON.Position[] = routeCoords.slice(arrayPosition, arrayPosition + 2);
    const segment = turf.lineString(segmentCoordinates, {name: 'line segment'}).geometry;
    // Try to snap on segment
    const snappedPointFeature: turf.helpers.Feature<Point> = turf.nearestPointOnLine(segment, point, {units: 'meters'});
    if (snappedPointFeature.properties.dist < radius) {
      return new SnappedLocation(snappedPointFeature.geometry);
    } else {return null;}
  }

  /**
   * Find the nearest instruction to a snapped position.
   * Therefor compare the distances from the snapped position to all instruction nodes.
   *
   * @deprecated Use new {@link getNearestInstructionOnRouteWithStartNode} method instead.
   *
   */
  private async getNearestInstructionOnRoute(snappedLngLat: LngLat, route: RoutingSolution): Promise<number> {
    const routeFeature: turf.helpers.Feature<LineString> = turf.lineString(route.geom.coordinates);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]).geometry;
    if (UtilService.pointOnLine(snappedPoint, routeFeature.geometry)) {
      let nextInstructionIndex = 0;
      let minDistance = Infinity;
      const instructions = route.instructions;
      for (let i = 0; i < instructions.length; i++) {
        const positionToInstructionDist: number = Math.abs(
          UtilService.distanceOnLine(snappedPoint, instructions[i].point, route.geom)
        );
        if (positionToInstructionDist < minDistance) {
          minDistance = positionToInstructionDist;
          nextInstructionIndex = i;
        }
      }
      return nextInstructionIndex;
    } else {
      const toast = await this.toastCtrl.create({
        message: 'getNearestInstructionOnRoute: Snapped point not located on route. This should never happen.',
        duration: 10000
      });
      await toast.present();
      throw Error('Snapped point not located on route. This should never happen.');
    }
  }

  /**
   *
   * Find the nearest instruction to a snapped position.
   * Therefor compare the distances from the snapped position to all instruction nodes.
   * Start search at a given instruction node and search until the end of the array of instruction nodes is reached.
   *
   * @param snappedLngLat - The snapped position on the route
   * @param route - The route
   * @param startNode - An index of the array of instructions
   */
  private async getNearestInstructionOnRouteWithStartNode(snappedLngLat: LngLat, route: RoutingSolution,
                                                          startNode: number): Promise<number> {
    const routeFeature: turf.helpers.Feature<LineString> = turf.lineString(route.geom.coordinates);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]).geometry;
    if (UtilService.pointOnLine(snappedPoint, routeFeature.geometry)) {
      let nextInstructionIndex = 0;
      let minDistance = Infinity;
      const instructions = route.instructions;
      // const currentNode: number = this.currentNodeAndPosition.currentNode;
      for (let i = startNode; i < instructions.length; i++) {
        const positionToInstructionDist: number = Math.abs(UtilService.distanceOnLineV2(snappedPoint,
          instructions[i].point, route.geom, 0));
        if (positionToInstructionDist < minDistance) {
          minDistance = positionToInstructionDist;
          nextInstructionIndex = i;
        }
      }
      return nextInstructionIndex;
    } else {
      const toast = await this.toastCtrl.create({
        message: 'getNearestInstructionOnRoute: Snapped point not located on route. This should never happen.',
        duration: 10000
      });
      await toast.present();
      throw Error('Snapped point not located on route. This should never happen.');
    }
  }

  /**
   *
   * Find the nearest instruction to a snapped position.
   * Therefor compare the distances from the snapped position to all instruction nodes.
   * Start search at a given instruction node and search until the end of the array of instruction nodes is reached.
   *
   * @param snappedLngLat - The snapped position on the route
   * @param route - The route
   * @param startSegment - The current
   */
  private getNearestInstructionOnRouteWithStartSegment(snappedLngLat: LngLat, route: RoutingSolution, startSegment: number): number {
    const routeFeature: turf.helpers.Feature<LineString> = turf.lineString(route.geom.coordinates);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]).geometry;
    if (UtilService.pointOnLine(snappedPoint, routeFeature.geometry)) {
      let nextInstructionIndex = 0;
      let minDistance = Infinity;
      const instructions = route.instructions;
      for (let i = 0; i < instructions.length; i++) {
        const positionToInstructionDist: number = Math.abs(UtilService.distanceOnLineV2(snappedPoint,
          instructions[i].point, route.geom, startSegment));
        if (positionToInstructionDist < minDistance) {
          minDistance = positionToInstructionDist;
          nextInstructionIndex = i;
        }
      }
      return nextInstructionIndex;
    } else {
      console.log('Snapped point not located on route. This should never happen.');
    }
  }

  /**
   *
   * Find the nearest instruction to a snapped position.
   * Therefor compare the distances from the snapped position to all instruction nodes.
   * Start search at a given instruction node and search until the end of the array of instruction nodes is reached.
   *
   * @param snappedLngLat - The snapped position on the route
   * @param route - The route
   * @param startSegment - The current segment
   * @param startNode The node to start search from to prevent stepping back when reclaiming route
   */
  private getNearestInstructionOnRouteWithStartSegmentAndNode(snappedLngLat: LngLat, route: RoutingSolution,
                                                              startSegment: number, startNode: number): number {
    const routeFeature: turf.helpers.Feature<LineString> = turf.lineString(route.geom.coordinates);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]).geometry;
    if (UtilService.pointOnLine(snappedPoint, routeFeature.geometry)) {
      let nextInstructionIndex = 0;
      let minDistance = Infinity;
      const instructions = route.instructions;
      for (let i = startNode; i < instructions.length; i++) {
        console.log('In getNearestInstructionOnRouteWithStartSegmentAndNode, will call distanceOnLineV2', i);
        const positionToInstructionDist: number = Math.abs(UtilService.distanceOnLineV2(snappedPoint,
          instructions[i].point, route.geom, startSegment));
        if (positionToInstructionDist < minDistance) {
          minDistance = positionToInstructionDist;
          nextInstructionIndex = i;
        }
      }
      return nextInstructionIndex;
    } else {
      console.log('Snapped point not located on route. This should never happen.');
    }
  }

  /**
   * Determines the next instruction on route and the relative position of the snapped location to this instruction
   * ('before' | 'on')
   */
  private async getCurrentNodeAndPosition(snappedLngLat: LngLat, route: RoutingSolution, startSegment: number) {
    const nearestInstruction: number = this.getNearestInstructionOnRouteWithStartSegment(snappedLngLat, route, startSegment);
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]);
    const distSnappedPointInstruction = UtilService.distanceOnLineV2(snappedPoint.geometry,
      route.instructions[nearestInstruction].point, route.geom, startSegment);
    let currentNodeAndPosition = {currentNode: undefined, position: undefined, distance: undefined};
    if (distSnappedPointInstruction !== undefined) {
      console.log('Distance from snapped location to instruction ' + nearestInstruction + ': ' + distSnappedPointInstruction);
      let displayDistance = 0;
      if (Math.abs(distSnappedPointInstruction) < this.proximityDistance) {
        currentNodeAndPosition = {currentNode: nearestInstruction, position: 'on', distance: displayDistance};
      } else if (distSnappedPointInstruction > 0) {
        // Snapped position lies before nearest instruction
        // next node == nearest node, only round to the nearest 10 metres
        displayDistance = Math.abs(Math.round(distSnappedPointInstruction / 10) * 10);
        currentNodeAndPosition = {
          currentNode: nearestInstruction,
          position: 'before',
          distance: displayDistance
        };
      } else if (distSnappedPointInstruction < 0 && nearestInstruction < route.instructions.length - 1) {
        // Snapped position lies after nearest instruction
        // next node == nearest node + 1, calculate distance to next node
        displayDistance = NavigationService.roundedDistanceToNextNode(snappedPoint.geometry,
          route.instructions[nearestInstruction + 1].point, route.geom);
        currentNodeAndPosition = {
          currentNode: nearestInstruction + 1,
          position: 'before',
          distance: displayDistance
        };
      } else if (nearestInstruction === route.instructions.length - 1) {
        console.log('You were detected: ' + Math.abs(distSnappedPointInstruction) + ' behind the last node.');
      } else {
        const toast = await this.toastCtrl.create({
          message: 'Cannot determine next currentNodeAndPosition; distSnappedPointInstruction: ' + distSnappedPointInstruction,
          duration: 10000
        });
        await toast.present();
      }
    }
    return currentNodeAndPosition;
  }

  /**
   * Determines the next instruction on route and the relative position of the snapped location to this instruction
   * ('before' | 'on')
   */
  private getCurrentNodeAndPositionV2(snappedLngLat: LngLat, route: RoutingSolution, startSegment: number) {
    let nearestInstruction: number = this.currentNodeAndPosition.currentNode;
    let position: string;
    let displayDistance: number;
    if (this.state === NavigationState.AWAITED || this.state === NavigationState.LEFT_TOLD) {
      // Only reset nearest node if navigation hasn't started yet or the user left the route.
      //nearestInstruction = this.getNearestInstructionOnRouteWithStartSegment(snappedLngLat, route, startSegment);
      console.log('calling getNearestInstructionOnRouteWithStartSegmentAndNode', this.state);
      nearestInstruction = this.getNearestInstructionOnRouteWithStartSegmentAndNode(snappedLngLat, route, startSegment, nearestInstruction);
      console.log('received from getNearestInstructionOnRouteWithStartSegmentAndNode', nearestInstruction);
    }
    // Most importantly the decision do to keep or to increment nearestInstruction
    const snappedPoint = turf.point([snappedLngLat.lng, snappedLngLat.lat]);
    const distSnappedPointInstruction = UtilService.distanceOnLineV2(snappedPoint.geometry,
      route.instructions[nearestInstruction].point, route.geom, startSegment);
    if (distSnappedPointInstruction !== undefined) {
      console.log('Distance from snapped location to instruction ' + nearestInstruction + ': ' + distSnappedPointInstruction);
      if (Math.abs(distSnappedPointInstruction) < this.proximityDistance) {
        // Snapped position lies on nearest instruction
        position = 'on';
        displayDistance = 0;
      } else if (distSnappedPointInstruction > 0) {
        // Snapped position lies before nearest instruction
        position = 'before';
        // next node == nearest node, only round to the nearest 10 metres
        displayDistance = Math.abs(Math.round(distSnappedPointInstruction / 10) * 10);
      } else if (distSnappedPointInstruction < 0 && nearestInstruction < route.instructions.length - 1) {
        // Snapped position lies after nearest instruction
        // next node == nearest node + 1, calculate distance to next node
        position = 'before';
        displayDistance = NavigationService.roundedDistanceToNextNode(snappedPoint.geometry,
          route.instructions[nearestInstruction + 1].point, route.geom);
        nearestInstruction++;
      } else if (nearestInstruction === route.instructions.length - 1) {
        console.log('You were detected: ' + Math.abs(distSnappedPointInstruction) + ' after the last node.');
      } else {
        console.log('Cannot determine next currentNodeAndPosition; distSnappedPointInstruction: ' + distSnappedPointInstruction);
      }
    }
    return {
      currentNode: nearestInstruction,
      position,
      distance: displayDistance
    };
  }

  /**
   * Return the number of the current segment
   *
   * @param snappedLocation
   * @param routeCoords
   * @param oldSegmentNumber
   */
  private async getCurrentSegment(snappedLocation: SnappedLocation, routeCoords: number[][], oldSegmentNumber: number) {
    for (let i = oldSegmentNumber; i < routeCoords.length - 1; i++) {
      const segment: number = UtilService.findPointOnRouteSegment(snappedLocation.point, routeCoords, i);
      if (segment !== null) {
        return segment;
      }
    }
    // return old segment number if not found
    return oldSegmentNumber;
  }


}
