import {IControl, Map} from 'maplibre-gl';
import {DOM} from './dom';
import {Category} from '../../types/radrevier-ruhr';
import {EventsService} from '../../../services/events.service';

export class PoiControl implements IControl {
  public container: HTMLElement;
  private map: Map;
  private className = 'mapboxgl-ctrl';
  private togglePoiChooserBtn: HTMLButtonElement;
  private poiChooser: HTMLElement;
  private categoryWrapper: HTMLElement;
  private scrollUpBtn: HTMLButtonElement;
  private scrollDownBtn: HTMLButtonElement;
  private marginTop = 0;
  private readonly marginTopMin: number;

  constructor(
    private categories: Category[],
    private events: EventsService
  ) {
    this.marginTopMin = (categories.length - 8) * -30;
  }

  /**
   * @implements {IControl.onAdd}
   * @param map
   */
  onAdd(map: Map): HTMLElement {
    this.map = map;

    // the container is a transparent wrapper for the togglePoiChooserBtn
    this.container = DOM.create('div', `${this.className} ${this.className}-group ${this.className}-group-poi`);
    this.container.addEventListener('contextmenu', (e: MouseEvent) => e.preventDefault());
    this.container.addEventListener('click', event => event.stopPropagation());
    this.container.addEventListener('wheel', (event: WheelEvent) => this.onWheel(event));

    // the togglePoiChooserBtn triggers the visibility of the poiChooser
    this.togglePoiChooserBtn = DOM.create('button',
      `${this.className}-icon ${this.className}-poi`, this.container) as HTMLButtonElement;
    this.togglePoiChooserBtn.type = 'button';
    this.togglePoiChooserBtn.title = 'Orte';
    this.togglePoiChooserBtn.setAttribute('aria-label', 'Orte');
    this.togglePoiChooserBtn.addEventListener('click', event => {
      event.stopPropagation();
      this.trigger(this.togglePoiChooserBtn, false);
    });

    // scroll up button
    this.scrollUpBtn = DOM.create('button', 'half-button scroll-up', this.container) as HTMLButtonElement;
    this.scrollUpBtn.disabled = true;
    this.scrollUpBtn.style.display = 'none';
    this.scrollUpBtn.addEventListener('click', event => {
      event.stopPropagation();
      this.scroll('up');
    });

    // this is the poiChooser div element
    this.poiChooser = DOM.create('div', `${this.className}-poichooser`, this.container);
    // clicking anywhere will close the poiChooser while open, stopPropagation excludes the poiChooser div from this
    // behaviour (same for the button, see above)
    this.poiChooser.addEventListener('click', evt => evt.stopPropagation());
    this.poiChooser.classList.add('invisible');
    // category buttons
    this.categoryWrapper = DOM.create('div', `category-wrapper`, this.poiChooser);
    if (this.categories && this.categories.length > 0) {
      this.categories.forEach(category => {
        // for each category, add a button
        const button: HTMLButtonElement = DOM.create('button',
          `poichooser-category-button`, this.categoryWrapper) as HTMLButtonElement;
        button.id = category.code.toString();
        button.title = category.localized.de.function;
        button.style.backgroundImage = 'url("assets/svg/' + category.code + '.svg")';
        button.addEventListener('click', () => this.togglePoiCategoryVisibility(category, button));
        if (category.selected) {
          button.classList.add('triggered');
        }
      });
    }

    // scroll down button
    this.scrollDownBtn = DOM.create('button', 'half-button scroll-down', this.container) as HTMLButtonElement;
    this.scrollDownBtn.disabled = true;
    this.scrollDownBtn.style.display = 'none';
    this.scrollDownBtn.addEventListener('click', event => {
      event.stopPropagation();
      this.scroll('down');
    });

    return this.container;
  }

  setCategories(categories) {
    this.categories = categories;
  }

  /**
   * @implements {IControl.onRemove}
   */
  onRemove() {
    DOM.remove(this.container);
  }

  onWheel(event: WheelEvent) {
    if (event.deltaY < 0) {
      this.scroll('up');
    } else {
      this.scroll('down');
    }
  }

  /**
   * @implements {IControl.getDefaultPosition}
   */
  getDefaultPosition() {
    return 'top-right';
  }

  /**
   * Toggles visibility of the poiChooser
   * @param button
   * @param forceClose explicitely close poiChooser by setting forceClose to true
   */
  private trigger(button: HTMLButtonElement, forceClose: boolean = false) {
    forceClose = forceClose === true; // false by default
    const mobile = window.innerWidth < 1024;
    if (mobile) {
      this.events.publish('poi-control:toggle');
    } else {
      if (!this.poiChooser.classList.contains('invisible') || forceClose) {
        this.poiChooser.classList.add('invisible');
        // remove the eventlistener when the poiChooser is closed
        document.removeEventListener('click', () => this.trigger(button));
        // remove triggered class from button
        button.classList.remove('triggered');
        this.setScrollBtnStatus(true);
      } else {
        this.poiChooser.classList.remove('invisible');
        // while the poiChooser is visible, clicking anywhere except on the poiChooserButton and the poiChooser
        // itself will close the poiChooser
        document.addEventListener('click', () => this.trigger(button, true));
        // set button as triggered
        button.classList.add('triggered');
        this.setScrollBtnStatus(false);
        // update select status
        // TODO iterate over categoryWrapper child elements and add/remove triggered class in accordance
        //  to selected status; remove event listener from map component
        Array.from(this.categoryWrapper.children).forEach((child: HTMLElement) => {
          const code = Number.parseInt((child as HTMLElement).id, 10);
          const category = this.categories.find(cat => cat.code === code);
          if (category.selected) {
            child.classList.add('triggered');
          } else {
            child.classList.remove('triggered');
          }
        });
      }
    }
  }

  /**
   * Toggles visibility for a specified poi category on the map
   * @param category the Category to show/hide
   */
  private togglePoiCategoryVisibility(category: Category, button: HTMLButtonElement) {
    category.selected = !category.selected;
    if (category.selected) {
      button.classList.add('triggered');
    } else {
      button.classList.remove('triggered');
    }
    const event = new CustomEvent('togglePoiCategory', {detail: {categories: this.categories, changed: category}});
    this.container.dispatchEvent(event);
  }

  private setScrollBtnStatus(hide: boolean) {
    this.scrollUpBtn.disabled = this.marginTop === 0;
    this.scrollDownBtn.disabled = this.marginTop === this.marginTopMin;
    if (hide) {
      this.scrollUpBtn.style.display = 'none';
      this.scrollDownBtn.style.display = 'none';
    } else {
      this.scrollUpBtn.style.display = 'block';
      this.scrollDownBtn.style.display = 'block';
    }
  }

  private scroll(direction: 'up' | 'down') {
    if (direction === 'up' && this.marginTop < 0) {
      this.marginTop += 30;
    } else if (direction === 'down' && this.marginTop > this.marginTopMin) {
      this.marginTop -= 30;
    }
    this.setScrollBtnStatus(false);
    this.categoryWrapper.style.top = `${this.marginTop}px`;
  }
}


