import {Injectable} from '@angular/core';
import {EventsService} from './events.service';
import {GlobalsService} from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  constructor(
    private events: EventsService,
    private globals: GlobalsService
  ) {
  }

  /**
   * Interprets the url and hands off for next processing
   */
  getUrlParams(url) {
    const result = this.parseQueryString(url);
    if (result.hasOwnProperty('width')) {
      this.applyScreenWidth(result);
    }
    if (result.hasOwnProperty('header')) {
      this.setHeaderStatus(result);
    }
    if (result.hasOwnProperty('lang')) {
      this.setLanguage(result);
    }
    if (result.hasOwnProperty('login')) {
      this.setLoginPossibility(result);
    }
    if (result.hasOwnProperty('headercolor')) {
      this.setHeaderColor(result);
    }
    if (result.hasOwnProperty('poiID')) {
      this.applyPoiIds(result);
    }
    if (result.hasOwnProperty('tourID')) {
      this.applyTourIds(result);
    }
    console.log(result);
  }

  /**
   * Emits an event and sets a global variable if the 'header' parameter was true/false
   */
  setHeaderStatus(result) {
    let header;
    result.header = result.header.replace(/\//g, '');
    if (result.header === 'true') {
      header = true;
    } else if (result.header === 'false') {
      header = false;
    }
    if (header !== undefined) {
      this.events.publish('header:status', header);
      this.globals.set('headerVisible', header);
    }

  }

  applyScreenWidth(result) {
    result.width = decodeURIComponent(result.width);
    result.width = result.width.replace(/\//g, '');
    this.globals.set('screenWidth', result.width);
  }

  setHeaderColor(result) {
    this.events.publish('header:color', result.headercolor);
    // TODO can it be changed?
  }

  /**
   * Language (en/nl/de-as default)
   */
  setLanguage(result) {
    let language;
    if (result.lang === 'nl') {
      language = 'nl';
    } else if (result.lang === 'en') {
      language = 'en';
    } else {
      language = 'de';
    }
    this.events.publish('language:status', language);
    this.globals.set('appLanguage', language);
  }

  /**
   * Emits an event and sets a global variable if the 'login' parameter was true/false
   */
  setLoginPossibility(result) {
    let loginpossible;
    if (result.login === 'true') {
      loginpossible = true;
    } else if (result.login === 'false') {
      loginpossible = false;
    }
    if (loginpossible !== undefined) {
      this.events.publish('login-possible:status', loginpossible);
      this.globals.set('loginpossible', loginpossible);
    }
  }

  /**
   * Sets a global variable with IDs of requested pois, that will be caught in the
   * corresponding controller
   */
  applyPoiIds(result) {
    const idarray = result.poiID.split(',').map(Number);
    this.globals.set('requestedPoiIDs', idarray);
  }

  /**
   * Sets a global variable with IDs of requested tours, that will be caught in the
   * corresponding controller
   */
  applyTourIds(result) {
    const idarray = result.tourID.split(',').map(Number);
    console.log(idarray);
    this.globals.set('requestedTourIDs', idarray);
  }

  /**
   * Helping function to extract all the possible key-value combinations
   */
  parseQueryString(url) {
    const urlParams = {};
    url.replace(
      new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
      ($0, $1, $2, $3) => {
        urlParams[$1] = $3;
      }
    );
    return urlParams;
  }
}
