import {IControl, Layer, Map,} from 'maplibre-gl';
import {DOM} from './dom';
import {LayerService, OverlayInfo, WMSLayerInfo} from '../../../services/layer.service';
import {EventsService} from '../../../services/events.service';
import {UtilService} from '../../../services/util.service';
import {TranslateService} from '@ngx-translate/core';

export class WMSLayerControl implements IControl {
  private map: Map;
  private container: HTMLElement;
  private className = 'mapboxgl-ctrl';
  private toggleLayerChooserBtn: HTMLButtonElement;
  private layerChooser: HTMLElement;

  constructor(
    private baseLayers: WMSLayerInfo[],
    // private overlays: WMSLayerInfo[],
    // private blockedOverlays: WMSLayerInfo[],
    private events: EventsService,
    private layerService: LayerService,
    private translate: TranslateService
  ) {
    events.subscribe('layer:set-base-layer', (baseLayer: Layer) => {
      this.setChecked(baseLayer.id, 'base');
    });
    events.subscribe('layer:add-overlay', (overlay: Layer) => {
      this.setChecked(overlay.id, 'overlay', true);
    });
    events.subscribe('layer:remove-overlay', (overlay: Layer) => {
      this.setChecked(overlay.id, 'overlay', false);
    });
  }

  /**
   * @implements {IControl.onAdd}
   * @param map
   */
  onAdd(map: Map): HTMLElement {
    this.map = map;

    // the container is a transparent wrapper for the toggleLayerChooserBtn
    this.container = DOM.create('div', `${this.className} ${this.className}-group ${this.className}-group-layers`);
    this.container.addEventListener('contextmenu', (e: MouseEvent) => e.preventDefault());

    // the toggleLayerChooserBtn triggers the visibility of the layerChooser
    this.toggleLayerChooserBtn = DOM.create('button',
      `${this.className}-icon ${this.className}-layers`, this.container) as HTMLButtonElement;
    this.toggleLayerChooserBtn.type = 'button';
    this.translate.get('map-controls.available-map-layers').subscribe(translation => {
      this.toggleLayerChooserBtn.title = translation;
      this.toggleLayerChooserBtn.setAttribute('aria-label', translation);
    });
    this.toggleLayerChooserBtn.addEventListener('click', event => {
      event.stopPropagation();
      this.trigger(this.toggleLayerChooserBtn, false);
    });

    // this is the layerChooser div element
    this.layerChooser = DOM.create('div', `${this.className}-layerchooser`, this.container);
    const form = DOM.create('form', null, this.layerChooser);
    const fieldset = DOM.create('fieldset', null, form);
    // clicking anywhere will close the layerChooser while open, stopPropagation excludes the layerChooser div from this
    // behaviour (same for the button, see above)
    this.layerChooser.addEventListener('click', evt => evt.stopPropagation());
    this.layerChooser.classList.add('invisible');

    const wmsTitle = DOM.create('div', 'layerchooser-title', fieldset);
    this.translate.get('map-controls.base-layers').subscribe(translation => {
      wmsTitle.innerHTML = translation;
    });
    this.baseLayers.forEach((layerInfo: WMSLayerInfo) => {
      this.addLayerEntry(layerInfo, fieldset);
    });

    // const overlayTitle = DOM.create('div', 'layerchooser-title', fieldset);
    // this.translate.get('map-controls.road-networks').subscribe(translation => {
    //   overlayTitle.innerHTML = translation;
    // });
    // overlayTitle.style.marginTop = '0.5em';
    // this.overlays.forEach((layerInfo: OverlayInfo) => {
    //   this.addLayerEntry(layerInfo, fieldset);
    // });

    const tourTitle = DOM.create('div', 'layerchooser-title', fieldset);
    this.translate.get('shared.tours').subscribe(translation => {
      tourTitle.innerHTML = translation;
    });
    tourTitle.style.marginTop = '0.5em';
    tourTitle.style.display = 'none';
    tourTitle.setAttribute('id', 'tourTitle');
    const layer = {
      localized: null,
      title: 'custom-route',
      url: null,
      layer: null,
      legend: 'GetTourGraphic'
    };
    this.addLayerEntry(layer, fieldset);

    return this.container;
  }

  toggleOverlay(overlay) {
    let blockedOverlay;
    // this.blockedOverlays.forEach((element) => {
    //   if (element.layer.id.includes(overlay.layerInfo.layer.id)) {
    //     blockedOverlay = element;
    //   }
    // });
    this.layerService.toggleOverlay(overlay);
    if (blockedOverlay) {
      blockedOverlay.selected = overlay.layerInfo.selected;
      this.layerService.toggleOverlay({layerInfo: blockedOverlay, fireEvent: false});
    }
  }

  public enableCustomTourControl(layer, hoverLayer) {
    const clickListener = (event: MouseEvent, targetLayer) => {
      const targetCheckbox = event.target as HTMLInputElement;
      this.layerService.toggleOverlay({
        layer: targetLayer,
        checked: targetCheckbox.checked,
        before: 'toursbefore',
        fireEvent: false
      });
    };

    if (layer) {
      const title = document.getElementById('tourTitle');
      title.style.display = 'block';

      const checkbox = document.getElementById('checkbox-custom-route');
      const newCheckbox: any = checkbox.cloneNode(true);
      checkbox.parentNode.replaceChild(newCheckbox, checkbox);
      newCheckbox.disabled = false;
      newCheckbox.checked = !!this.map.getLayer(layer.id);
      newCheckbox.parentNode.style.display = 'block';
      newCheckbox.addEventListener('click', (event: MouseEvent) => clickListener(event, layer));
      if (hoverLayer) {
        newCheckbox.addEventListener('click', (event: MouseEvent) => clickListener(event, hoverLayer));
      }
    }
  }

  public disableCustomTourControl() {
    const tour = false;
    const title = document.getElementById('tourTitle');
    if (title) {
      title.style.display = 'none';
    }

    const checkbox: any = document.getElementById('checkbox-custom-route');
    // if (this.map) {
    //   tour = !!this.map.getLayer('custom-route');
    // }
    if (checkbox) {
      checkbox.disabled = !tour;
      checkbox.checked = tour;
      checkbox.parentNode.style.display = 'none';
    }
  }

  /**
   * @implements {IControl.onRemove}
   */
  onRemove() {
    DOM.remove(this.container);
  }

  /**
   * @implements {IControl.getDefaultPosition}
   */
  getDefaultPosition() {
    return 'top-right';
  }

  setChecked(id: string, type: 'base' | 'overlay', checked?: boolean) {
    let className = type === 'base' ? 'radio-' : 'checkbox-';
    className += UtilService.stringToClassName(id);
    const entries: any = this.layerChooser.getElementsByClassName(type);
    Array.from(entries).forEach((entry: any) => {
      if (type === 'base') {
        entry.checked = entry.id === className;
      } else {
        entry.checked = entry.id === className ? checked : entry.checked;
      }
    });
  }

  private addLayerEntry(
    layerInfo: WMSLayerInfo | OverlayInfo,
    parentEl: HTMLElement,
    selected?: boolean
  ) {
    selected = selected || !!this.map.getLayer(layerInfo.title);
    // for each overlay, add a div containing a checkbox a label and a legend graphic
    const div = DOM.create('div', 'layerchooser-checkbox-wrapper', parentEl);
    if (layerInfo.legend) {
      let legendUrl = layerInfo.legend;
      if (layerInfo.legend === 'GetLegendGraphic') {
        legendUrl =
          `${(layerInfo as WMSLayerInfo).url}?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20` +
          `&HEIGHT=20&LAYER=${layerInfo.layer}`;
        const legendGraphic = DOM.create('img', null, div) as HTMLImageElement;
        legendGraphic.src = legendUrl;
        legendGraphic.style.marginRight = '5px';
      } else if (layerInfo.legend === 'GetTourGraphic') {
        const legendGraphic = DOM.create('img', null, div) as HTMLImageElement;
        legendGraphic.src = 'assets/symbols/touricon.PNG';
        legendGraphic.style.marginRight = '5px';
      } else if (!layerInfo.hasOwnProperty('url')) {
        const legendGraphic = DOM.create('img', null, div) as HTMLImageElement;
        legendGraphic.src = legendUrl;
        legendGraphic.style.marginRight = '5px';
      } else {
        const legendGraphic = DOM.create('img', null, parentEl) as HTMLImageElement;
        legendGraphic.src = legendUrl;
      }
    }
    const className = UtilService.stringToClassName(layerInfo.title);
    const type = layerInfo.hasOwnProperty('url') ? 'base' : 'overlay';
    const input = DOM.create('input', `${type} ${className}`, div) as HTMLInputElement;
    input.id = type === 'base' ? `radio-${className}` : `checkbox-${className}`;
    input.classList.add('base-layer');
    input.type = type === 'base' ? 'radio' : 'checkbox';
    input.name = type;
    input.value = layerInfo.title;
    if (selected || (type === 'base' && this.baseLayers.indexOf(layerInfo as WMSLayerInfo) === 0)) {
      input.defaultChecked = true;
    }
    if (layerInfo.title === 'custom-route') {
      // disabling the tour's checkbox because it certainly doesn't exist yet
      input.disabled = true;
      div.style.display = 'none';
    }

    // create layer object from layerInfo
    // const layer = this.layerService.createLayer(layerInfo, type);

    // add an event listener to toggle visibility of the layer
    if (type === 'base') {
      input.addEventListener('click', async () => await this.layerService
        .setBaseLayer({layerInfo: (layerInfo as WMSLayerInfo), fireEvent: false}));
    } else {
      if (layerInfo.legend !== 'GetTourGraphic') {
        input.addEventListener('click', (event: MouseEvent) => {
          const checkbox = event.target as HTMLInputElement;
          layerInfo.selected = checkbox.checked;
          this.toggleOverlay({layerInfo, fireEvent: false});
        });
      }
    }
    const label = (DOM.create('label', null, div) as HTMLLabelElement);
    label.htmlFor = `checkbox-${layerInfo.title}`;
    if (layerInfo.title === 'custom-route') {
      label.innerText = 'Geplante Tour';
    } else {
      label.innerText = layerInfo.title;
    }
  }

  /**
   * Toggles visibility of the layerChooser
   *
   * @param button
   * @param forceClose explicitly close layerChooser by setting forceClose to true
   */
  private trigger(button: HTMLButtonElement, forceClose: boolean = false) {
    forceClose = forceClose === true; // false by default
    const mobile = window.innerWidth < 1024;
    if (mobile) {
      this.events.publish('wms-layer-control:toggle');
    } else {
      if (!this.layerChooser.classList.contains('invisible') || forceClose) {
        this.layerChooser.classList.add('invisible');
        // remove the event listener when the layerChooser is closed
        document.removeEventListener('click', () => this.trigger(button));
        // remove triggered class from button
        button.classList.remove('triggered');
      } else {
        this.layerChooser.classList.remove('invisible');
        // while the layerChooser is visible, clicking anywhere except on the layerChooserButton and the layerChooser
        // itself will close the layerChooser
        document.addEventListener('click', () => this.trigger(button, true));
        // set button as triggered
        button.classList.add('triggered');
      }
    }
  }
}
