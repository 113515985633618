import {GeoJsonObject, LineString, Point} from 'geojson';
import * as turf from '@turf/turf';
import {LngLat} from 'maplibre-gl';

import {Position} from '@capacitor/geolocation';


export interface Point2D extends GeoJsonObject {
  type: 'Point';
  coordinates: [number, number];
}

export interface Translatable {
  localized?: {
    de?: Translation;
    en?: Translation;
    nl?: Translation;
  };
}

export class Item extends Object implements Translatable {
  id?: any;
  localized?: {
    de?: Translation;
    en?: Translation;
    nl?: Translation;
  };
  media?: Media[];
  type?: 'NORMAL' | 'HIGHLIGHT' | 'CUSTOM' | 'COMMUNITY';
  significance?: number;
  isFavourite?: boolean;
}

export class Highlight implements Item {
  category?: Category[];
  distance?: number;
  id?: number;
  localized?: {
    de?: Translation;
    en?: Translation;
    nl?: Translation;
  };
  media?: Media[];
  type?: 'NORMAL' | 'HIGHLIGHT' | 'CUSTOM' | 'COMMUNITY';
  length?: number;
  significance?: number;
  highlightImage?: string;
  displayName?: string;
}

export class PoiBrief extends Item {
  categorycodes?: number[];
  geom?: Point2D;
  type?: 'NORMAL' | 'HIGHLIGHT';
  distance?: number;
  uuid?: string;
}

export class Poi extends PoiBrief {
  uuid?: string;
  source?: string;
  user?: User;
  lastupdated?: string;
  status?: string;
  statusComment?: string;
  handicapfree?: boolean;
  contact?: Contact;
  openinghours?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  comments?: any;
  rating?: any;
  selected?: boolean;
  tours?: Tour[];
  displayString?: string;
  importid?: string;
  availableBicycles?: number;
  highlightImage?: string;
  isFavourite?: boolean;
  categorynames?: string;
}

export class BusStop extends Poi {
  stoptype: string;
  cached: boolean;
}

export class TourBrief extends Item {
  audience?: string;
  distance?: number;
  geom?: LineString;
  length?: number;
  status?: 'DRAFT' | 'ACTIVE' | 'DELETE_REQUEST' | 'CLOSED_TEMPORARILY' | 'DEACTIVATED';
  tripline?: boolean;
  tripround?: boolean;
}

export class Tour extends TourBrief {
  accessible?: boolean;
  altitudemax?: number;
  altitudemin?: number;
  ascent?: number;
  category?: any[];
  comments?: any;
  contact?: Contact;
  descent?: number;
  duration?: string;
  geomend?: Point2D;
  geomstart?: Point2D;
  heightchart?: HeightChart;
  lastupdated?: string;
  name?: string;
  navigationinstructions?: NavigationInstruction[];
  poi?: Poi[];
  rating?: any;
  statuscomment?: string;
  type?: 'NORMAL' | 'HIGHLIGHT' | 'CUSTOM' | 'COMMUNITY';
  user?: User;
  uuid?: string;
  distance?: number;
  remarks?: any;
  dirty?: boolean;
  highlightImage?: string;
  isFavourite?: boolean;
  instructions?: NavigationInstruction[];
}

export class PoiTag {
  name: string;
  selected: boolean;
}

export class BusStopBrief implements Item {
  id: string;
  name: string;
  city: string;
  ags?: string;
  zone?: string;
  geom?: Point2D;
}

export class Category implements Translatable {
  code?: number;
  localized?: {
    de?: Translation;
    en?: Translation;
    nl?: Translation;
  };
  icon?: string;
  ionIcon?: {
    ios: string;
    md: string;
    name: string;
  };
  selected?: boolean;
  userSelected?: boolean;
}

export class HeightChart {
  step: number;
  first: number;
  last?: number;
  values: number[];
}

export class Media {
  caption?: string;
  contenttype?: string;
  id?: number;
  name?: string;
  url?: string;
  usage?: 'LOGO' | 'HIGHLIGHT' | 'GALLERY' | 'URL';
  uuid?: string;
}

export class User {
  id: number;
  contact?: Contact;
  activationexpiry?: Date;
  activationlink?: string;
  active?: boolean;
  authmethod?: 'PLAIN' | 'FACEBOOK' | 'APPLE' | 'GOOGLEPLUS';
  email?: string;
  favoritespoi?: Poi[];
  favoritestour?: Tour[];
  loginname?: string;
  mytours?: Tour[];
  name?: string;
  organisation?: string;
  password?: string;
  passwordrecoveryexpiry?: Date;
  passwordrecoverylink?: string;
  role?: 'USER' | 'ADMIN';
  unregistered?: boolean;
  dirty?: boolean;
  token?: string;
  consentGiven?: boolean;
  appleTokenData?: { access_token?: string; expires_in?: number; refresh_token?: string };
}

export class Contact {
  id: number;
  name: string;
  street?: string;
  postal_code?: string;
  city?: string;
  phone?: string;
  fax?: string;
  email: string;
  url?: string;
}

export class Image {
  id: number;
  title: string;
  description?: string;
  width: number;
  height: number;
  url: string;
}

export class Translation {
  name?: string;
  teaser?: string;
  description?: string;
  accessibleinfo?: string;
  tags?: string;
  tagsList?: string[];
  fee?: string;
  category?: string;
  function?: string;
  openinghours?: string;
  packet?: string;
}

export class Sortation {
  sortBy: 'DISTANCE' | 'LENGTH' | 'NAME' | 'RATING' | 'DATE';
  direction: 'ASC' | 'DESC';
}

export class RoutingSolution {
  geom: LineString;
  instructions: NavigationInstruction[];
  length: number;
  score: number;
  ascent?: number;
  descent?: number;
  altitudemax?: number;
  altitudemin?: number;
  heightchart?: any;
  remarks?: any;
  error?: any;
}

export class NavigationInstruction {
  angle: number;
  audioFile: string;
  direction: Direction;
  distanceToLastNode: number;
  iconcls: string;
  instructiontype: 'JUNCTION' | 'POI' | 'ROUNDABOUT' | 'ABSTOP';
  longText: string; // text of the long instruction shown on routing page
  shortTextPart1: string; // text part before an icon shown during navigation
  shortTextPart2: string; // text part after an icon shown during navigation
  name: string;
  number: number;
  point: Point;
  start: boolean;
  stop: boolean;
  instructionText?: any;

  constructor(shortTextPart1: string, iconCls?: string) {
    this.shortTextPart1 = shortTextPart1;
    if (iconCls) {
      this.iconcls = iconCls;
    }
  }
}

export enum Direction {
  STRAIGHT_AHEAD = 'STRAIGHT_AHEAD',
  SLIGHT_LEFT = 'SLIGHT_LEFT',
  LEFT = 'LEFT',
  SHARP_LEFT = 'SHARP_LEFT',
  BACKWARDS = 'BACKWARDS',
  SHARP_RIGHT = 'SHARP_RIGHT',
  RIGHT = 'RIGHT',
  SLIGHT_RIGHT = 'SLIGHT_RIGHT'
}

export class PositionBuffer {
  size: number; // Size of the buffer
  positions: Position[]; // The storage
  add(position: Position) {
    if (this.positions.length === this.size) {
      // Remove oldest element if buffer is already full
      this.positions.shift();
    }
    this.positions.push(position);
  }

  getRotation(): number {
    if (this.positions.length >= 2) {
      const from = this.positions[0].coords;
      const to = this.positions[this.positions.length - 1].coords;
      const fromPoint: turf.Coord = turf.point([from.longitude, from.latitude]);
      const toPoint: turf.Coord = turf.point([to.longitude, to.latitude]);
      const bearing = turf.bearing(fromPoint, toPoint);
      if (bearing >= 0) {
        return bearing;
      } else {
        return 360 + bearing;
      }
    } else {
      return 0;
    }
  }

  getSpeed(): number {
    return this.positions[this.positions.length - 1].coords.speed;
  }

  constructor(size: number) {
    this.positions = new Array();
    this.size = size;
  }
}


export enum NavigationState {
  OFF = 'off',
  LEFT = 'left',
  LEFT_TOLD = 'left_told',
  AWAITED = 'awaited',
  ACTIVE = 'active',
}

/**
 * This class stores the snapped location, expressed as a point and the current segment
 */
export class SnappedLocation {
  lngLat: LngLat;
  point: Point;
  segmentNumber: number;
  segmentStartPoint: Point
  segmentEndPoint: Point;

  constructor(point: Point, segmentNumber?: number, segmentStartPoint?: Point, segmentEndPoint?: Point) {
    this.point = point;
    if (segmentNumber) {
      this.segmentNumber = segmentNumber;
    }
    if (segmentStartPoint) {
      this.segmentStartPoint = segmentStartPoint;
    }
    if (segmentEndPoint) {
      this.segmentEndPoint = segmentEndPoint;
    }
    this.lngLat = new LngLat(point.coordinates[0], point.coordinates[1])
  }
}

