import {Injectable} from '@angular/core';
import {EventsService} from './events.service';
import {NavigationInstruction} from '../lib/types/radrevier-ruhr';
import {UtilService} from './util.service';
import {TranslateService} from '@ngx-translate/core';
import {Capacitor} from '@capacitor/core';
import {LocalNotificationDescriptor, LocalNotifications} from '@capacitor/local-notifications';
import {LocalNotificationSchema} from '@capacitor/local-notifications/dist/esm/definitions';

const NAVIGATION_ID = 37745;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  navigationNotification: LocalNotificationSchema;

  constructor(
    private events: EventsService,
    private translate: TranslateService
  ) {
    events.subscribe('navigation:update-instruction-panel',
      (instruction: NavigationInstruction) => this.showNavigationNotification(instruction));
    events.subscribe('navigation:remaining-distance',
      (distance: number) => this.updateNavigationNotification(distance));
    events.subscribe('navigation:cancel-navigation-view', () => this.hideNavigationNotification());
  }

  async hideNavigationNotification() {
    if (Capacitor.isNativePlatform()) {
      const descriptor: LocalNotificationDescriptor = {id: this.navigationNotification.id};
      await LocalNotifications.cancel({notifications: [descriptor]});
    }
  }

  private async showNavigationNotification(instruction: NavigationInstruction) {
    if (Capacitor.isNativePlatform()) {
      console.log(instruction.iconcls);
      const update = !!this.navigationNotification;
      let title = instruction.shortTextPart1;
      if (instruction.shortTextPart2) {
        if (title.charAt(title.length - 1) !== ' ') {
          title += ' ';
        }
        title += instruction.shortTextPart2;
      }
      let largeIcon = `${instruction.iconcls}`.replace('nav-', 'direction_');
      let theme = 'dark';
      // try {
      //   theme = await UtilService.getTheme();
      // } catch (e) {
      //   console.log(e);
      // }
      largeIcon += '_' + theme;
      if (update) {
        this.navigationNotification.title = title;
        this.navigationNotification.largeIcon = largeIcon;
        // LocalNotifications.update(this.navigationNotification);
      } else {
        this.navigationNotification = {
          id: NAVIGATION_ID,
          title,
          body: '',
          sound: null,
          largeIcon,
          smallIcon: 'ic_rrr_notification'
        };
        await LocalNotifications.schedule({notifications: [this.navigationNotification]});
      }
    }
  }

  private async updateNavigationNotification(distance: number) {
    if (Capacitor.isNativePlatform()) {
      if (this.navigationNotification && distance > 0) {
        this.navigationNotification.body = await this.translate
          .get('services.notification.navigation-notification-text', {distance}).toPromise();
        // cordova.plugins.notification.local.update(this.navigationNotification);
      }
    }
  }
}
