import {MenuItem} from '../lib/menu-item';

export class MenuItems {

  public static primary: MenuItem[] = [{
    label: 'var.menuitems.primary.HighlightsPage.label',
    text: 'var.menuitems.primary.HighlightsPage.text',
    icon: 'star',
    img: 'assets/images/start/entdecken',
    routerLink: '/entdecken'
  }, {
    label: 'var.menuitems.primary.ToursPage.label',
    text: 'var.menuitems.primary.ToursPage.text',
    icon: 'bicycle',
    img: 'assets/images/start/touren',
    routerLink: '/touren'
  }, {
    label: 'var.menuitems.primary.PoisPage.label',
    text: 'var.menuitems.primary.PoisPage.text',
    icon: 'location',
    img: 'assets/images/start/orte',
    routerLink: '/orte'
  }];

  public static secondary: MenuItem[] = [
    // {
    //     label: 'Einstellungen',
    //     page: 'SettingsPage'
    // },
    // {
    //  label: 'var.menuitems.secondary.defects',
    //  routerLink: '/maengelmelder'
    // },
    {
      label: 'var.menuitems.secondary.privacy',
      routerLink: '/info/datenschutz',
      params: {activeTab: 'datenschutz'}
    }, {
      label: 'var.menuitems.secondary.faq',
      routerLink: '/info/faq',
      params: {activeTab: 'faq'}
    }, {
      label: 'var.menuitems.secondary.contact',
      routerLink: '/info/kontakt',
      params: {activeTab: 'kontakt'}
    }, {
      label: 'var.menuitems.secondary.imprint',
      routerLink: '/info/impressum',
      params: {activeTab: 'impressum'}
    }];

  public static user: MenuItem[] = [{
    label: 'Mein Profil',
    routerLink: '/benutzerprofil'
  }, {
    label: 'Meine Touren',
    routerLink: '/eigene-touren'
  }, {
    label: 'Meine Favoriten',
    routerLink: '/favoriten'
  }];
}
