import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, ToastController} from '@ionic/angular';
import {MatomoTracker} from 'ngx-matomo';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private alertCtrl: AlertController,
    private matomoTracker: MatomoTracker,
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {
  }

  async askForTrackingConsent(): Promise<boolean> {
    const header: string = await this.translate.get('app.cookie-preferences.header').toPromise();
    const message: string = await this.translate.get('app.cookie-preferences.message').toPromise();
    const btnAgree: string = await this.translate.get('shared.agree').toPromise();
    const btnDecline: string = await this.translate.get('shared.decline').toPromise();
    let consentGiven: boolean;
    /* Test if Do-Not-Track is enabled. */
    const dnt = (((window as any).doNotTrack && (window as any).doNotTrack === '1') ||
      (navigator.doNotTrack && (navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1')) ||
      ((navigator as any).msDoNotTrack && (navigator as any).msDoNotTrack === '1') ||
      (window.external && ('msTrackingProtectionEnabled' in window.external) && (window.external as any).msTrackingProtectionEnabled()));
    if (dnt) {
      consentGiven = false;
      const dntFoundToast = await this.translate.get('app.cookie-preferences.dnt-toast').toPromise();
      let cssClass = 'dnt-toast';
      if (window.innerWidth >= 1280 && window.innerHeight < 830 || window.innerHeight < 730) {
        cssClass += ' slim-header';
      }
      const toast = await this.toastCtrl.create({
        message: dntFoundToast,
        duration: 3000,
        position: 'top',
        cssClass
      });
      await toast.present();
    } else {
      const alert = await this.alertCtrl.create({
        header,
        message,
        cssClass: 'cookie-dialog',
        backdropDismiss: false,
        buttons: [{
          text: btnDecline,
          role: 'deny'
        }, {
          text: btnAgree,
          role: 'consent'
        }]
      });
      await alert.present();
      const detail = await alert.onDidDismiss();
      consentGiven = detail.role === 'consent';
    }
    return consentGiven;
  }

  async toggleTracking(consent: boolean) {
    if (consent) {
      // init matomo
      this.matomoTracker.rememberConsentGiven();
      this.matomoTracker.rememberCookieConsentGiven();
    } else {
      // no matomo :-(
      this.matomoTracker.forgetConsentGiven();
      this.matomoTracker.forgetCookieConsentGiven();
    }
  }
}
