import {Component, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {LayerService, OverlayInfo, WMSLayerInfo} from '../../services/layer.service';
import {Category} from '../../lib/types/radrevier-ruhr';
import {ConnectionService} from '../../services/connection.service';
import {EventsService} from '../../services/events.service';
import {HttpClient} from '@angular/common/http';
import {MapService} from '../../services/map.service';
import {Platform} from '@ionic/angular';
import {PoisService} from '../../services/pois.service';
import {UtilService} from '../../services/util.service';
import {Layer} from 'maplibre-gl';
import {Subscription} from 'rxjs';
import {ItemListEntry} from '../item-list/item-list.component';

@Component({
  selector: 'app-map-menu',
  templateUrl: './map-menu.component.html',
  styleUrls: ['./map-menu.component.scss'],
})
export class MapMenuComponent implements OnInit, OnDestroy {

  baseLayers: WMSLayerInfo[];
  overlays: OverlayInfo[];
  blockedOverlays: OverlayInfo[];
  poiCategories: Category[];
  selectedBaseLayer: WMSLayerInfo;

  mobile: boolean;
  customRoute = {
    visible: false,
    layer: null
  };

  subscriptions: Subscription[] = [];

  constructor(
    private connectionService: ConnectionService,
    private events: EventsService,
    private http: HttpClient,
    public layerService: LayerService,
    private mapService: MapService,
    private platform: Platform,
    private poisService: PoisService,
    private translate: TranslateService,
    public util: UtilService
  ) {
  }

  async ngOnInit() {
    this.mobile = this.platform.is('mobile') && !this.platform.is('tablet');
    this.subscriptions.push(this.events.subscribe('map:destroy', () => {
      if (this.overlays) {
        this.overlays.forEach(overlay => {
          overlay.selected = false;
        });
      }
    }));
    this.subscriptions.push(this.events.subscribe('layer:set-base-layer', (baseLayer: Layer) => {
      this.setChecked(baseLayer.id, 'base');
    }));
    this.subscriptions.push(this.events.subscribe('layer:add-overlay', (overlay: Layer) => {
      this.setChecked(overlay.id, 'overlay', true);
    }));
    this.subscriptions.push(this.events.subscribe('layer:remove-overlay', (overlay: Layer) => {
      this.setChecked(overlay.id, 'overlay', false);
    }));
    this.subscriptions.push(this.events.subscribe('layer:custom-route', (layer) => {
      if (layer) {
        this.customRoute.layer = layer;
        this.customRoute.visible = true;
      }
    }));
    this.subscriptions.push(this.events.subscribe('layer:custom-route-clear', () => {
      this.customRoute.layer = null;
      this.customRoute.visible = false;
    }));

    // loading everything only when the language was set in app component
    this.subscriptions.push(this.events.subscribe('language:change', async () => {
      // suppress error messages if data cannot be loaded on init
      await this.refresh(true);
    }));

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      await this.refresh(true);
    });
  }

  async refresh(quiet: boolean = false) {
    // load wms layer info from local json file
    const wmsLayersUrl = 'assets/json/wms-layers.json';
    const lang = this.translate.currentLang;
    console.log('language: ', lang);
    const response: any = await this.http.get(wmsLayersUrl).toPromise();
    response.baseLayers.forEach(item => {
      item.title = item.localized[lang].title;
    });
    // response.overlays.forEach(item => {
    //   item.title = item.localized[lang].title;
    // });
    // response.blockedOverlays.forEach(item => {
    //   item.title = item.localized[lang].title;
    // });

    this.baseLayers = response.baseLayers;
    // this.overlays = response.overlays;
    // this.blockedOverlays = response.blockedOverlays;
    // get poi categories from PoisService
    const poiList = await this.poisService.createPoiList(quiet);
    this.poiCategories = poiList.map(entry => entry.category);
    this.selectedBaseLayer = this.baseLayers[0];
  }

  async ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.mobile) {
      await this.connectionService.getUserInternetConnection();
    }
  }

  toggleCustomRoute() {
    if (this.customRoute.visible) {
      this.mapService.addLayer(this.customRoute.layer, false, ['toursbefore']);
    } else {
      this.mapService.removeLayer(this.customRoute.layer.id);
    }
  }

  overlayToggle(overlay) {
    overlay.selected = !overlay.selected;
    let blockedOverlay;
    this.blockedOverlays.forEach((element) => {
      if (element.layer.id.includes(overlay.layer.id)) {
        blockedOverlay = element;
      }
    });
    this.layerService.toggleOverlay({layerInfo: overlay, fireEvent: true});
    if (blockedOverlay) {
      blockedOverlay.selected = overlay.selected;
      this.layerService.toggleOverlay({layerInfo: blockedOverlay, fireEvent: true});
    }
  }

  /*
  getLegend(layerInfo: WMSLayerInfo): string {
      let legendUrl = null;
      if (layerInfo.legend) {
          legendUrl = layerInfo.legend;
          if (layerInfo.legend === 'GetLegendGraphic') {
              legendUrl =
                  `${layerInfo.url}?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerInfo.layer}`;
          }
      }
      return legendUrl;
  }
  */

  async selectPoiCategory(category: Category) {
    category.selected = !category.selected;
    const entries: ItemListEntry[] = this.poiCategories.map(cat => ({
        category: cat,
        items: [],
        type: 'POI'
      }));
    await this.poisService.setSelectedCategories({entries, changed: category});
  }

  async selectBaseLayer(event: any) {
    const lang = this.translate.currentLang;
    this.selectedBaseLayer = this.baseLayers.find(baselayer => baselayer.localized[lang].title === event.detail.value);
    await this.layerService.setBaseLayer({layerInfo: this.selectedBaseLayer});
  }

  private setChecked(id: string, type: 'base' | 'overlay', checked?: boolean) {
    const lang = this.translate.currentLang;
    if (type === 'base') {
      if (this.baseLayers) {
        this.baseLayers.forEach(baseLayer => {
          baseLayer.selected = baseLayer.localized[lang].title === id;
        });
      }
    } else {
      if (this.overlays) {
        this.overlays.forEach(overlay => {
          overlay.selected = overlay.localized[lang].title === id ? checked : overlay.selected;
        });
      }
    }
  }
}
