import {Component} from '@angular/core';
import {Constants} from '../../../../var/constants';
import {ReadMode} from 'ngx-file-helpers';
import {Tour, User} from '../../../../lib/types/radrevier-ruhr';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {GlobalsService} from '../../../../services/globals.service';
import {UtilService} from '../../../../services/util.service';
import {UserService} from '../../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AboutPrivacyPage} from '../../../about/tabs/about-privacy/about-privacy.page';
import {AboutUsagePage} from '../../../about/tabs/about-usage/about-usage.page';

import {Capacitor} from '@capacitor/core';
import {Photo} from '@capacitor/camera';
import {CameraService} from '../../../../services/camera.service';

export class TourData {
  name: string;
  description: string;
  isPublic: boolean;
  photos: { contenttype: string; filelocation: string; hash: string }[];
}

@Component({
  selector: 'app-save-custom-tour-modal',
  templateUrl: './save-custom-tour-modal.page.html',
  styleUrls: ['./save-custom-tour-modal.page.scss'],
})
export class SaveCustomTourModalPage {

  tourData: TourData = {
    name: '',
    description: '',
    isPublic: false,
    photos: []
  };
  // photos: { contenttype: string, filelocation: string, hash: string }[]

  imageBaseUrl = Constants.URL_IMAGE;
  mediaBaseUrl = Constants.URL_BASE_MEDIA;

  /**
   * ngxFilePicker reads files as specified here
   */
  readMode = ReadMode.dataURL;

  /**
   * Whether capacitor is available
   */
  mobile: boolean;

  /**
   * True while uploading a photo
   */
  uploadingPhoto = false;

  /**
   * User object
   */
  user: User;

  editingTour: Tour;

  editing = false;

  constructor(
    private alertCtrl: AlertController,
    private cameraService: CameraService,
    private globals: GlobalsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private router: Router,
    private util: UtilService,
    private userService: UserService,
    private translate: TranslateService
  ) {
    this.mobile = Capacitor.isNativePlatform();
    this.tourData.name = navParams.get('name');
    const editingTour = navParams.get('tour');
    if (editingTour) {
      this.editing = true;
      // copy without reference
      this.editingTour = JSON.parse(JSON.stringify(editingTour));
      this.tourData.isPublic = editingTour.status === 'ACTIVE';
    } else if (this.globals.hasKey('tourData')) {
      this.tourData = this.globals.get('tourData');
      this.globals.remove('tourData');
      if (this.globals.hasKey('autoRestoreTourData')) {
        this.globals.remove('autoRestoreTourData');
      }
    }
    this.userService.getUser().then(user => this.user = user);
  }

  async save() {
    if (this.editing) {
      this.editingTour.status = this.tourData.isPublic ? 'ACTIVE' : 'DEACTIVATED';
      await this.modalCtrl.dismiss(this.editingTour);
    } else {
      await this.modalCtrl.dismiss(this.tourData);
    }
  }

  async cancel() {
    await this.modalCtrl.dismiss();
  }

  async choosePhotoDesktop(file) {
    // create blob from file object
    // const blob = new Blob([file.content], {type: file.type});
    const blob = UtilService.base64toBlob(file.content, file.type);
    await this.uploadPhoto(blob, file.underlyingFile.name);
  }

  async choosePhotoMobile() {
    const photo: Photo = await this.cameraService.getPhoto();
    if (photo && photo.base64String) {
      console.log(photo.base64String);
      const blob = UtilService.base64toBlob(photo.base64String, 'image/' + photo.format);
      await this.uploadPhoto(blob, photo.exif.name);
    }
  }

  async uploadPhoto(blob: Blob, name: string) {
    // get headers
    this.userService.getAuthHeaders().then((headers) => {
      // create formData object and add blob
      const formData = new FormData();
      formData.append('file', blob, name);

      // create XMLHttpRequest
      const request = new XMLHttpRequest();
      // success listener
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE) {
          this.uploadingPhoto = false;
          if (request.response) {
            const response = JSON.parse(request.response);
            if (response.length > 0 && response[0].metadata) {
              console.log(response[0]);
              if (this.editing) {
                this.editingTour.media.push({
                  url: `content/${response[0].metadata.filelocation}`,
                  contenttype: response[0].metadata.contenttype,
                  usage: 'GALLERY'
                });
              } else {
                this.tourData.photos.push(response[0].metadata);
              }
            } else {
              console.log(request.response);
              if (response[0].success === false && response[0].errmsg !== null) {
                const error = {message: response[0].errmsg};
                this.util.handleErrorMsg(error);
              }
            }
          }
        }
      };
      // error listener
      request.onerror = (event) => {
        this.uploadingPhoto = false;
        this.util.handleErrorMsg(event);
      };
      // timeout listener
      request.ontimeout = async (event: ProgressEvent) => {
        this.uploadingPhoto = false;
        const message = await this.translate.get('components.routing.modal-save-timeout').toPromise();
        await this.util.handleErrorMsg({message});
      };
      // set request method and url
      request.open('POST', this.imageBaseUrl);

      // set headers
      request.setRequestHeader('Authorization', headers.get('Authorization'));

      // send request
      request.send(formData);
      this.uploadingPhoto = true;
    });
  }

  proceedDeletePhoto(photo) {
    if (this.editing) {
      const index = this.editingTour.media.indexOf(photo);
      if (index > -1) {
        this.editingTour.media.splice(index, 1);
      }
    } else {
      const index = this.tourData.photos.indexOf(photo);
      if (index > -1) {
        this.tourData.photos.splice(index, 1);
      }
    }
  }

  async deletePhoto(photo) {
    const header = await this.translate.get('pages.tour-details.delete-photo-title').toPromise();
    const message = await this.translate.get('pages.tour-details.delete-photo-text').toPromise();
    const btnCancel = await this.translate.get('shared.cancel').toPromise();
    const btnDelete = await this.translate.get('pages.tour-details.confirm-delete-button').toPromise();

    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [{
        text: btnCancel,
        role: 'cancel'
      }, {
        text: btnDelete,
        handler: () => {
          this.proceedDeletePhoto(photo);
        }
      }]
    });
    await alert.present();
  }

  async loginRegister() {
    this.globals.set('tourData', this.tourData);
    this.globals.set('autoRestoreTourData', true);
    await this.router.navigate(['/anmelden'], {queryParams: {fromPage: 'RoutingPage'}});
    await this.modalCtrl.dismiss();
  }

  checkUrl(url) {
    if (this.editing) {
      if (!url.match(/^https?:\/\/.*/)) {
        url = this.mediaBaseUrl + url + '?size=150cx150c';
      }
    } else {
      if (!url.match(/^https?:\/\/.*/)) {
        url = this.imageBaseUrl + '/' + url + '?size=150cx150c';
      }
    }
    return url;
  }

  async showModal(type: 'privacy' | 'terms') {
    let component: any = AboutPrivacyPage;
    if (type === 'terms') {
      component = AboutUsagePage;
    }
    const modal = await this.modalCtrl.create({
      component,
      componentProps: {isModal: true}
    });
    await modal.present();
  }

  async showPublicToursInfo() {
    const header = await this.translate.get('pages.routing.modal-save-public-info-header').toPromise();
    const message = await this.translate.get('pages.routing.modal-save-public-info-message').toPromise();
    const btnBack = await this.translate.get('shared.back').toPromise();
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [{
        text: btnBack,
        role: 'cancel'
      }]
    });
    await alert.present();
  }

}
