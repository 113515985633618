/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */

import {Injectable} from '@angular/core';
import {AlertController, MenuController, Platform, PopoverController, ToastController} from '@ionic/angular';
import {EventsService} from './events.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from './util.service';
import {Constants} from '../var/constants';
import {Item, Poi, Tour, User} from '../lib/types/radrevier-ruhr';
import {StorageError} from '../lib/errors';
import {DataService, DataType} from './data.service';
import {ListPopoverComponent, ListPopoverItem} from '../components/list-popover/list-popover.component';
import {Router} from '@angular/router';
import {StorageService} from './storage.service';

import {FacebookLogin, FacebookLoginResponse} from '@capacitor-community/facebook-login';
import {FacebookLoginProvider, SocialAuthService} from '@abacritt/angularx-social-login';

import {SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse} from '@capacitor-community/apple-sign-in';
import {environment} from '../../environments/environment';
import {Capacitor} from '@capacitor/core';
import {Device, DeviceId} from '@capacitor/device';
import {lastValueFrom} from 'rxjs';

type AppleToken = {
  token: string;
  device_name: string;
  code: string;
  name?: string;
};

export class Credentials {
  name: string;
  loginName: string;
  email: string;
  password: string;
  passwordCheck: string;
  termsToggle: boolean;
  region?: string;
}

export class ErrorResponse {
  status: number;
  error: string;
  message: string;
}

export class MigrationConfig {
  migrateMytours: boolean;
  migrateFavpois: boolean;
  migrateFavtours: boolean;
  clearGuest: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Local reference to current user
   */
  user: User;

  userLoginPossible = true;

  constructor(
    private alertCtrl: AlertController,
    private authService: SocialAuthService,
    private dataService: DataService,
    private events: EventsService,
    private http: HttpClient,
    private menuCtrl: MenuController,
    private oauthService: OAuthService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private router: Router,
    private storage: StorageService,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private util: UtilService
  ) {
    // this.oauthService.setStorage(localStorage);
    // Login-Url
    this.oauthService.tokenEndpoint = Constants.URL_TOKEN;
    // Url with user info endpoint
    this.oauthService.userinfoEndpoint = Constants.URL_USER_ME;
    this.oauthService.oidc = false;
    // The SPA's id. Register SPA with this id at the auth-server
    this.oauthService.clientId = 'radrevierruhr-pwa';
    // Set the scope for the permissions the client should request
    this.oauthService.scope = '';
    // Set a dummy secret
    this.oauthService.dummyClientSecret = 'jae4avaid3ojeeHoo2Ahdo7LeiG9ku';
    // URL of the SPA to redirect the user after silent refresh
    this.oauthService.silentRefreshRedirectUri = window.location.origin + '/silent-refresh.html';
    // Automatically refreshing a token when/ before it expires
    // this.oauthService.silentRefreshTimeout = 5000;
    // // is only for Implicit Flow (when there is no refresh token)?
    // this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events.subscribe(e => {
      if (e instanceof OAuthErrorEvent) {
        console.error(e);
      } else {
        console.log(e);
      }
    });

    // if info whether user login should be possible was in the link
    this.events.subscribe('login-possible:status', (loginpossible) => {
      this.userLoginPossible = loginpossible;
    });
  }

  async login(loginName: string, password: string) {
    if (loginName && password) {
      await this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(loginName, password);
      const response = this.oauthService.getIdentityClaims();
      if (response && response.hasOwnProperty('id')) {
        console.log('new login');
        this.user = response as User;
        // this.user.password = password;
        await this.storage.set('user', this.user);
        this.events.publish('user:login-change', this.user);
      } else {
        const message$ = this.translate.get('services.error.login-error');
        const message = await lastValueFrom(message$);
        throw new Error(message);
      }
    }
  }

  async mobileFacebookLogin() {
    const response: FacebookLoginResponse = await FacebookLogin.login({permissions: ['email']});

    if (response && response.accessToken) {
      const headers = await this.getAuthHeaders(response.accessToken.token);
      if (headers) {
        const json$ = this.http.get(Constants.URL_USER_FACEBOOK, {headers});
        const json = await lastValueFrom(json$);
        console.log('facebook login json', json);
        this.user = json as User;
        this.user.token = response.accessToken.token;
        await this.storage.set('user', this.user);
        this.events.publish('user:login-change', this.user);
      }
    }
  }

  async webFacebookLogin() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(async response => {
      if (response.hasOwnProperty('id')) {
        const headers = await this.getAuthHeaders(response.authToken);
        if (headers) {
          const httpOptions = {headers};

          // Send request to the server
          this.http.get(Constants.URL_USER_FACEBOOK, httpOptions).subscribe({
            next: json => {
              console.log(json);
              this.user = json as User;
              this.user.token = response.authToken;
              this.storage.set('user', this.user);
              this.events.publish('user:login-change', this.user);
            },
            error: error => {
              console.log(error);
              if (error.status >= 500 && error.status < 600) {
                this.translate.get('services.util.server-down-error').subscribe(value => {
                  this.util.handleErrorMsg({message: value});
                });
              } else {
                this.util.handleErrorMsg(error);
              }
              // throw new Error('Unknown Error');
            }
          });
        }
      } else {
        throw new Error('Unknown Error');
      }
    }, error => {
      console.log(error);
    });
  }

  async signInWithApple() {
    const deviceId = await Device.getId();
    if (Capacitor.getPlatform() === 'web') {
      await this.signInWithAppleWeb(deviceId);
    } else {
      await this.signInWithAppleMobile(deviceId);
    }
  }

  signInWithAppleWeb(deviceId: DeviceId): Promise<AppleToken> {
    return new Promise(async (resolve, reject) => {
      const appleSignInAbortController = new AbortController();
      const appleSignInWindow = window.open(
        'https://appleid.apple.com/auth/authorize?' +
        'client_id=' + environment.appIdWeb + '&redirect_uri=' + environment.appUrl +
        '&response_type=code id_token&scope=name email&response_mode=form_post&nonce=' +
        encodeURIComponent('{"region": "RADREVIERRUHR"}'),
        '_blank'
      );
      window.addEventListener(
        'message',
        (e) => {
          if (!(e instanceof MessageEvent)) {
            return;
          }

          const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
          if (typeof data !== 'object' || data === null) {
            return;
          }
          if (data.data.hasOwnProperty('error')) {
            return;
          }
          if (!data.data.hasOwnProperty('authorization') || !data.data.authorization.hasOwnProperty('id_token')) {
            return;
          }
          const returnData = {
            token: data.data.authorization.id_token,
            device_name: deviceId.uuid,
            code: data.data.authorization.code,
            name: null,
          };
          if (
            data.data.hasOwnProperty('user') &&
            data.data.user.hasOwnProperty('name') &&
            data.data.user.name.hasOwnProperty('firstName') &&
            data.data.user.name.hasOwnProperty('lastName')
          ) {
            returnData.name = data.data.user.name.lastName + ' ' + data.data.user.name.firstName;
          }
          appleSignInAbortController.abort();
          appleSignInWindow.close();
          resolve(returnData);
        },
        {signal: appleSignInAbortController.signal}
      );
      return;
    });
  }

  async signInWithAppleMobile(deviceId: DeviceId): Promise<AppleToken> {
    const options: SignInWithAppleOptions = {
      clientId: environment.appIdMobile,
      redirectURI: environment.appUrl,
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };
    const result: SignInWithAppleResponse = await SignInWithApple.authorize(options);
    const returnData = {
      token: result.response.identityToken,
      device_name: deviceId.uuid,
      code: result.response.authorizationCode,
      name: null
    };
    if (
      result.response.hasOwnProperty('givenName') &&
      result.response.hasOwnProperty('familyName') &&
      result.response.givenName &&
      result.response.familyName
    ) {
      returnData.name = result.response.familyName + ' ' + result.response.givenName;
    }
    return returnData;
  }

  completeSignInWithApple(appleResponse: { access_token?: string; expires_in?: number; refresh_token?: string }) {
    this.user = {id: 0};
    this.user.appleTokenData = appleResponse;
  }

  async refresh() {
    if (!this.user.unregistered) {
      const headers = await this.getAuthHeaders();
      if (headers) {
        try {
          const user = (await this.http.get(Constants.URL_USER_ME, {headers}).toPromise() as User);
          if (this.user.token) {
            user.token = this.user.token;
          }
          this.user = user;
        } catch (e) {
          // this would keep coming up all the time if the server is down
          // if (e.status >= 500 && e.status < 600) {
          //   this.translate.get('services.util.server-down-error').subscribe(value => {
          //     this.util.handleErrorMsg({message: value});
          //   });
          // } else {
          //   this.util.handleErrorMsg(e);
          // }
        }
      }
    }
  }

  async logout(oauth: boolean = true) {
    let httpOptions;

    if (oauth) {
      try {
        const headers = await this.getAuthHeaders();
        if (headers) {
          httpOptions = {headers};
          if (this.user.authmethod && this.user.authmethod === 'FACEBOOK') {
            // signing out from the angularx-social-login
            this.authService.authState.subscribe(async (user) => {
              if (user !== null) {
                const response = await this.authService.signOut(true);
                console.log(response);
              }
            });
          } else {
            this.oauthService.logOut();
          }
          // and from the backend
          await this.http.get(Constants.URL_USER_LOGOUT, httpOptions).toPromise();
        }
      } catch (e) {
        if (e.status >= 500 && e.status < 600) {
          this.translate.get('services.util.server-down-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
          });
        } else if (e.hasOwnProperty('error')) {
          await this.util.handleServerError(e.error);
        }
      }
    }

    try {
      await this.storage.remove('user');
      const keys = await this.storage.keys();
      console.log(keys);
      if (keys.indexOf('routingData') !== -1) {
        await this.storage.remove('routingData');
      }
      this.events.publish('user:login-change');
      const guest = await this.storage.get('guest');
      if (guest && guest.hasOwnProperty('id')) {
        this.user = guest;
        // this.events.publish('user:login-change', this.user);
      } else {
        await this.createGuest();
      }
    } catch (e) {
      if (e.status >= 500 && e.status < 600) {
        this.translate.get('services.util.server-down-error').subscribe(value => {
          this.util.handleErrorMsg({message: value});
        });
      } else if (e.hasOwnProperty('error')) {
        await this.util.handleServerError(e.error);
      }
    }

    if (this.router.url !== '/start') {
      await this.router.navigate(['/start']);
    }
  }

  async register(credentials: Credentials) {
    delete credentials.passwordCheck;
    return this.post(credentials);
    // try {
    //   const response = await this.post(credentials);
    //
    //   if (response && response.hasOwnProperty('id')) {
    //     const title = await this.translate.get('services.user.register-feedback-title').toPromise();
    //     const msg = await this.translate.get('services.user.register-feedback-msg',
    //       {user: response['loginname'], email: response['email']}).toPromise();
    //     await this.alertCtrl.create({
    //       title: title,
    //       message: msg,
    //       buttons: [{
    //         text: 'OK',
    //         handler: () => {
    //           this.events.publish('user:registration-complete', response);
    //         }
    //       }]
    //     }).present();
    //   }
    // } catch (e) {
    //   if (e.hasOwnProperty('error')) {
    //     this.util.handleServerError(e.error);
    //   }
    // }
  }

  async completeRegistration(token: string) {
    return this.http.get(Constants.URL_ACCOUNT_ACTIVATE + token).toPromise();
  }

  async lostPassword(user) {
    return this.http.get(Constants.URL_LOST_PASSWORD + user).toPromise();
  }

  async checkToken(token: string) {
    try {
      await this.http.get(Constants.URL_NEW_PASSWORD + token).toPromise();
      return true;
    } catch (e) {
      return false;
    }
  }

  async submitNewPassword(token: string, password: string) {
    return this.http.post(Constants.URL_NEW_PASSWORD + token, {password}).toPromise();
  }

  async deleteAccount() {
    const headers = await this.getAuthHeaders();
    if (headers) {
      return this.http.delete(Constants.URL_USER_ME, {headers}).toPromise();
    }
  }

  async getPublicUserTours(): Promise<Tour[]> {
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    if (this.user && this.user.mytours) {
      return this.user.mytours.filter(tour => tour.status === 'ACTIVE');
    } else {
      return [];
    }
  }

  async deleteAccountByToken(token) {
    return this.http.get(Constants.URL_ACCOUNT_DELETE + token).toPromise();
  }

  async getUser(): Promise<User> {
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    try {
      await this.refresh();
    } catch (e) {
      console.warn('Could not refresh user object.');
    }
    return this.user;
  }

  async isLoggedIn(): Promise<boolean> {
    const user = await this.getUser();
    return !user.unregistered;
  }

  async changePassword(newPass: string) {
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    this.user.password = newPass;
    try {
      await this.put();
      delete this.user.password;
      await this.storage.set('user', this.user);
      // TODO messages and where to show them?
      const header = await this.translate.get('services.user.update-title').toPromise();
      const msg = await this.translate.get('services.user.update-password-msg').toPromise();
      const alert = await this.alertCtrl.create({
        header,
        message: msg,
        buttons: ['OK']
      });
      await alert.present();
    } catch (e) {
      if (e.status >= 500 && e.status < 600) {
        this.translate.get('services.util.server-down-error').subscribe(value => {
          this.util.handleErrorMsg({message: value});
        });
      } else if (e.hasOwnProperty('error')) {
        await this.util.handleServerError(e.error);
      }
    }
  }

  async updateProfile(name: string, email: string) {
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    this.user.name = name;
    this.user.email = email;
    try {
      await this.put();
      await this.storage.set('user', this.user);
      // TODO messages and where to show them?
      const header = await this.translate.get('services.user.update-title').toPromise();
      const msg = await this.translate.get('services.user.update-profile-msg').toPromise();
      const alert = await this.alertCtrl.create({
        header,
        message: msg,
        buttons: ['OK']
      });
      await alert.present();
    } catch (e) {
      if (e.status >= 500 && e.status < 600) {
        this.translate.get('services.util.server-down-error').subscribe(value => {
          this.util.handleErrorMsg({message: value});
        });
      } else if (e.hasOwnProperty('error')) {
        await this.util.handleServerError(e.error);
      }
    }
  }

  async getFavourites(type: 'pois' | 'tours'): Promise<Item[]> {
    let items: Item[];
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    if (type === 'pois') {
      items = this.user.favoritespoi;
    } else {
      items = this.user.favoritestour;
    }
    return items.sort((a, b) => {
      const nameA = this.util.getTranslation(a).name.toUpperCase();
      const nameB = this.util.getTranslation(b).name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  async getFavouritePois(): Promise<Poi[]> {
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    return this.user.favoritespoi.sort((a, b) => {
      const nameA = this.util.getTranslation(a).name.toUpperCase();
      const nameB = this.util.getTranslation(b).name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  async isOwnTour(tourId: number) {
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    return this.user.mytours.findIndex(tour => tour.id === tourId) !== -1;
  }

  async isFavourite(obj: Poi | Tour) {
    if (!this.user) {
      try {
        await this.restore();
      } catch (e) {
        throw new Error('Could not restore user object.');
      }
    }
    let favourites: any[] = this.user.favoritespoi;
    if (obj.hasOwnProperty('length')) {
      favourites = this.user.favoritestour;
    }
    const isFav = favourites.findIndex(fav => fav.id === obj.id) !== -1;
    // console.log(obj.id + ' favourite: ' + isFav);
    return isFav;
  }

  /**
   * The user can add a tour or a poi to his favourites.
   */
  async toggleFavouriteEntry(item, type) {
    try {
      const isFavourite = await this.toggleFavourite(item);
      let message;
      if (isFavourite) {
        message = await this.translate.get('pages.' + type + '-details.toggle-favourites-add').toPromise();
      } else {
        message = await this.translate.get('pages.' + type + '-details.toggle-favourites-remove').toPromise();
      }
      const toast = await this.toastCtrl.create({
        message,
        duration: 2000,
        position: 'bottom'
      });
      await toast.present();
    } catch (e) {
      if (e.status >= 500 && e.status < 600) {
        this.translate.get('services.util.server-down-error').subscribe(value => {
          this.util.handleErrorMsg({message: value});
        });
      } else {
        await this.util.handleErrorMsg(e);
      }
    }
  }

  /**
   * Toggles favourite state of either a given poi or tour.
   */
  async toggleFavourite(obj: Poi | Tour, store: boolean = true): Promise<boolean> {
    if (!this.user) {
      await this.restore();
    }
    let favourites: any[] = this.user.favoritespoi;
    if (obj.hasOwnProperty('length')) {
      favourites = this.user.favoritestour;
    }
    const index = favourites.findIndex(fav => fav.id === obj.id);
    const setAsFav = index === -1;
    if (setAsFav) {
      favourites.push(obj);
    } else {
      favourites.splice(index, 1);
    }
    try {
      await this.store();
      return setAsFav;
    } catch (e) {
      // rollback changes
      return this.toggleFavourite(obj, false);
    }
  }

  async saveCustomTour(tour: Tour) {
    if (!this.user) {
      await this.restore();
    }
    const existingTour = this.user.mytours.find(usertour => usertour.localized.de.name === tour.localized.de.name);
    if (!existingTour) {
      if (this.user.unregistered) {
        tour.id = this.generateGuestTourId();
        const now = new Date();
        tour.lastupdated = now.toString();
        this.user.mytours.push(tour);
        tour.dirty = true;
        await this.store();
      } else {
        const headers = await this.getAuthHeaders();
        if (headers) {
          tour.id = null;
          await this.http.post(Constants.URL_TOUR, tour, {headers}).toPromise();
          await this.refresh();
          // update community tours to load changes
          await this.updateCommunityTours();
        }
      }
    } else {
      throw new Error('Name already in use');
    }
  }

  generateGuestTourId() {
    let id;
    for (let i = 0; i <= this.user.mytours.length; i++) {
      if (i === this.user.mytours.length) {
        id = this.user.mytours.length;
      } else {
        if (this.user.mytours[i].id && typeof (this.user.mytours[i].id) === 'string') {
          const num = Number.parseInt(((this.user.mytours[i].id as string).substring(1)), 10);
          if (num > i) {
            id = i;
            break;
          }
        } else {
          id = i;
          break;
        }
      }
    }
    return `l${id}`;
  }

  async deleteCustomTour(tour: Tour): Promise<User> {
    if (this.user.unregistered) {
      const index = this.user.mytours.findIndex(ownTour => ownTour.id === tour.id);
      if (index !== -1) {
        this.user.mytours.splice(index, 1);
      }
    } else {
      const headers = await this.getAuthHeaders();
      if (headers) {
        try {
          await this.http.delete(Constants.URL_TOUR + tour.id, {headers}).toPromise();
          this.user = (await this.http.get(Constants.URL_USER_ME, {headers}).toPromise() as User);
        } catch (e) {
          if (e.status >= 500 && e.status < 600) {
            this.translate.get('services.util.server-down-error').subscribe(value => {
              this.util.handleErrorMsg({message: value});
            });
          } else {
            await this.util.handleServerError(e.error);
          }
        }
      }
    }
    await this.store();
    // update community tours to load changes
    await this.updateCommunityTours();
    return this.user;
  }

  async saveTourChanges(changedTour: Tour) {
    if (this.user.unregistered) {
      const index = this.user.mytours.findIndex(ownTour => ownTour.id === changedTour.id);
      if (index !== -1) {
        const now = new Date();
        changedTour.lastupdated = now.toString();
        this.user.mytours[index] = changedTour;
      }
    } else {
      const headers = await this.getAuthHeaders();
      if (headers) {
        try {
          await this.http.put(Constants.URL_TOUR + changedTour.id, changedTour, {headers}).toPromise();
          const user = (await this.http.get(Constants.URL_USER_ME, {headers}).toPromise() as User);
          await this.storage.set('user', user);
          this.user = user;
          // update community tours to load changes
          await this.updateCommunityTours();
          return user;
        } catch (e) {
          if (e.status >= 500 && e.status < 600) {
            this.translate.get('services.util.server-down-error').subscribe(value => {
              this.util.handleErrorMsg({message: value});
            });
          } else {
            await this.util.handleServerError(e.error);
          }
        }
      } else {
        return null;
      }
    }
  }

  async restore() {
    try {
      const user = await this.storage.get('user');
      if (this.user && this.user.token) {
        user.token = this.user.token;
      }
      this.user = user;

      if (!this.user) {
        this.user = await this.storage.get('guest');
        if (!this.user) {
          // generate empty unregistered user
          await this.createGuest();
          console.log('created new guest user');
        }
      } else {

        // try {
        //   await this.login(this.user.loginname, this.user.password);
        // } catch (e) {
        //   console.log(e);
        // }
      }
    } catch (e) {
      throw new StorageError('Could not restore user object.');
    }
  }

  async getAuthHeaders(token?: any): Promise<HttpHeaders> {
    let headers;
    if (this.user) {

      if (!token) {
        if (this.user.authmethod && this.user.authmethod === 'FACEBOOK') {
          token = this.user.token;
        } else if ((this.user.authmethod && this.user.authmethod === 'APPLE') || this.user.appleTokenData) {
          token = this.user.appleTokenData.access_token;
        } else {
          const expirationData = new Date(this.oauthService.getAccessTokenExpiration()).getTime();
          const now = new Date().getTime();
          if (now - expirationData > 0) {
            // renew if token has expired
            try {
              const response = await this.oauthService.refreshToken();
              console.log(response);
            } catch (e) {
              this.oauthService.logOut();
              this.logout(false);
              // this.util.handleErrorMsg({
              //   message: 'Ihre Sitzung konnte nicht automatisch verlängert werden. Bitte melden Sie sich erneut an!'
              // });
            }
          }
          token = this.oauthService.getAccessToken();
          console.log('token: ' + token);
        }
      }
      if (token) {
        headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        });
      }
    }
    return headers;
  }

  async guestHasData(): Promise<boolean> {
    const guest: User = await this.storage.get('guest');
    return guest.favoritespoi.length >= 1 || guest.favoritestour.length >= 1 || guest.mytours.length >= 1;
  }

  // private async get(loginname: string): Promise<User> {
  //   try {
  //     return (<User>await this.http.get(Constants.URL_USER + loginname).toPromise());
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  checkForDuplicates(user: User, tour: Tour) {
    const lang = this.translate.defaultLang;
    const duplicate = user.mytours.find(userTour => userTour.localized[lang].name === tour.localized[lang].name);
    if (duplicate) {
      const match = tour.localized[lang].name.match(/.*\((\d+)\)$/);
      if (match && match.length >= 2) {
        // increment number
        let num = Number.parseInt(match[1], 10);
        num++;
        // replace
        tour.localized[lang].name = tour.localized[lang].name.replace(/\(\d+\)$/, `(${num})`);
      } else {
        // add number
        tour.localized[lang].name += ' (1)';
      }
      return this.checkForDuplicates(user, tour);
    } else {
      return tour;
    }
  }

  async migrateGuestData(config: MigrationConfig =
                           {migrateFavpois: true, migrateFavtours: true, migrateMytours: true, clearGuest: true}) {
    const guest: User = await this.storage.get('guest');
    const user: User = await this.storage.get('user');
    // migrate tours
    if (config.migrateMytours) {
      for (let tour of guest.mytours) {
        // check if a tour with the same name already exists
        tour = this.checkForDuplicates(user, tour);
        // upload tour
        await this.saveCustomTour(tour);
        // save tour in new user object
        user.mytours.push(tour);
      }
    }
    // migrate favourites
    if (config.migrateFavpois) {
      guest.favoritespoi.forEach(poi => {
        if (!user.favoritespoi.find(favPoi => favPoi.id === poi.id)) {
          user.favoritespoi.push(poi);
        }
      });
    }
    if (config.migrateFavtours) {
      guest.favoritestour.forEach(tour => {
        if (!user.favoritestour.find(favTour => favTour.id === tour.id)) {
          user.favoritestour.push(tour);
        }
      });
    }
    if (config.clearGuest) {
      guest.mytours = [];
      guest.favoritespoi = [];
      guest.favoritestour = [];
    }

    // save guest
    try {
      await this.storage.set('guest', guest);
    } catch (e) {
      console.log(e);
    }
    // save user
    this.user = user;
    await this.store(true);
  }

  /**
   * Shows a popover displaying user menu options and navigating to the respective page based on user choice.
   *
   * @param event Click event is passed to popover show method to position the popover correctly.
   * TODO outsource into user component
   */
  async showUserPopover(event) {
    // fetch translations
    const translations: any = {};
    translations.userFavourites = await this.translate.get('app.user-popover.user-favourites').toPromise();
    translations.userProfile = await this.translate.get('app.user-popover.user-profile').toPromise();
    translations.userTours = await this.translate.get('app.user-popover.user-tours').toPromise();
    translations.settings = await this.translate.get('app.user-popover.settings').toPromise();
    if (this.user && !this.user.unregistered) {
      if (this.user.authmethod === 'PLAIN') {
        translations.loggedInAs = await this.translate
          .get('app.user-popover.logged-in-as', {name: this.user.loginname}).toPromise();
      } else if (this.user.authmethod === 'FACEBOOK') {
        translations.loggedInAs = await this.translate
          .get('app.user-popover.logged-in-as', {name: this.user.name}).toPromise();
      }
      translations.logout = await this.translate.get('app.user-popover.logout').toPromise();
    } else {
      translations.loggedInAs = await this.translate.get('app.user-popover.guest-account-active').toPromise();
      translations.login = await this.translate.get('app.user-popover.login').toPromise();
    }

    // create listItems
    const listItems: ListPopoverItem[] = [];
    const accountInfo: ListPopoverItem = {
      label: translations.loggedInAs,
      id: 'user-label',
      cls: 'no-arrow',
      disabled: true,
    };
    if (!this.user || this.user.unregistered) {
      accountInfo.iconRight = 'help-circle';
      accountInfo.iconRightOnClick = () => this.showGuestAccountInfo();
    }
    listItems.push(accountInfo);
    listItems.push({
      label: translations.userProfile,
      id: 'profile'
    });
    listItems.push({
      label: translations.userTours,
      id: 'tours'
    });
    listItems.push({
      label: translations.userFavourites,
      id: 'favourites'
    });
    listItems.push({
      label: translations.settings,
      id: 'settings'
    });
    // if (this.user && !this.user.unregistered) {
    //   listItems.push({
    //     label: translations.logout,
    //     id: 'logout'
    //   });
    // } else if (this.userLoginPossible) {
    //   listItems.push({
    //     label: translations.login,
    //     id: 'login'
    //   });
    // }
    const popover = await this.popoverCtrl.create({
      component: ListPopoverComponent,
      componentProps: {listItems, disabled: false},
      cssClass: 'user-menu-popover',
      event
    });

    await popover.present();

    // callback run when a ListItem was selected and the menu closes
    const detail = await popover.onDidDismiss();
    if (detail.data) {
      switch (detail.data.id) {
        case 'profile':
          await this.router.navigate(['/benutzerprofil']);
          break;
        case 'tours':
          await this.router.navigate(['/eigene-touren']);
          break;
        case 'favourites':
          await this.router.navigate(['/favoriten']);
          break;
        case 'settings':
          await this.router.navigate(['/einstellungen']);
          break;
        case 'login':
          await this.router.navigate(['/anmelden']);
          break;
        case 'logout':
          await this.logout();
          break;
      }
      // close side menu (not the popover)
      await this.menuCtrl.close();
    }
  }

  async store(put: boolean = true) {
    try {
      if (this.user.unregistered) {
        await this.storage.set('guest', this.user);
      } else {
        await this.storage.set('user', this.user);
        if (put) {
          await this.put();
        }
      }
    } catch (e) {
      throw new StorageError('Error storing user object.');
    }
  }

  private async updateCommunityTours() {
    try {
      const response: any = await this.http.get(Constants.URL_TOURS + '&types=COMMUNITY').toPromise();
      if (response.hasOwnProperty('content')) {
        const communityToursData = response.content;
        const tours = this.dataService.resources.find(resource => resource.type === DataType.tours);
        const toursData = tours.data.filter(tour => tour.type !== 'COMMUNITY');
        tours.data = toursData.concat(communityToursData);
      }
    } catch (e) {
      console.log(e);
    }
  }

  private post(credentials: Credentials) {
    return this.http.post(Constants.URL_USER_REGISTER, credentials).toPromise();
  }

  private async put() {
    try {
      const headers = await this.getAuthHeaders();
      if (headers) {
        const response = await this.http.put(Constants.URL_USER_ME, this.getPackedUser(), {headers}).toPromise();
        if (response) {
          this.user.dirty = false;
        }
      }
    } catch (e) {
      this.user.dirty = true;
    }
  }

  /**
   * Creates an empty anonymous User object to user when no user is logged in.
   */
  private async createGuest() {
    const guest = {
      id: Math.floor(Math.random() * 1000),
      mytours: [],
      favoritespoi: [],
      favoritestour: [],
      unregistered: true
    };
    try {
      await this.storage.set('guest', guest);
      this.user = guest;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Returns a packed version of the current user by reducing saved tours and pois to their id.
   *
   * @return Object
   */
  private getPackedUser(): any {
    const user = (Object.assign({}, this.user) as User);
    user.mytours = this.user.mytours.map(tour => tour.id);
    user.favoritespoi = this.user.favoritespoi.map(tour => tour.id);
    user.favoritestour = this.user.favoritestour.map(poi => poi.id);
    // user['password'] = null;
    // delete user['password'];
    return user;
  }

  private async showGuestAccountInfo() {
    const header = await this.translate.get('app.guest-account-info.title').toPromise();
    const message = await this.translate.get('app.guest-account-info.message').toPromise();

    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [{
        text: 'Ok',
        role: 'cancel'
      }],
    });

    await alert.present();
  }
}
