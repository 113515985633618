import {Injectable} from '@angular/core';
import {Item} from '../lib/types/radrevier-ruhr';
import {Constants} from '../var/constants';
import {EventsService} from './events.service';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from './util.service';
import {StorageService} from './storage.service';

import {App} from '@capacitor/app';

export enum DataType {
  highlights = 'highlights',
  poiCategories = 'poicategories',
  pois = 'pois',
  tours = 'tours'
}

export class Resource {
  type: DataType;
  url: string;
  data?: Item[];
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  resources: Resource[] = [{
    type: DataType.highlights,
    url: Constants.URL_HIGHLIGHT_ALL
  }, {
    type: DataType.poiCategories,
    url: Constants.URL_CATEGORIES + '&group=POI'
  }, {
    type: DataType.pois,
    url: Constants.URL_POIS
  }, {
    type: DataType.tours,
    url: Constants.URL_TOURS + '&types=NORMAL,HIGHLIGHT,COMMUNITY&zoomlevel=17'
  }];

  // usableNetworks = ['ethernet', 'wifi'];
  loading = false;

  constructor(
    private events: EventsService,
    private http: HttpClient,
    private storage: StorageService,
    private translate: TranslateService,
    private util: UtilService
  ) {
  }

  /**
   * Loads initial data from json files or storage and updates data from the server.
   * This approach is only necessary for the built app. The website should rely on the service worker for caching data.
   */
  async init() {
    this.loading = true;
    try {
      const version = (await App.getInfo()).version;
      let dataInit = await this.storage.get('data-init');
      console.log('app version: ' + version);
      console.log('data version: ' + dataInit);
      if (dataInit && dataInit === version) {
        // if app has run before, restore last used set of data from local storage
        console.log('trying to restore last set of data from local storage');
        await this.restore();
        this.events.publish('data-provider:load');
      } else if (dataInit !== version) {
        // clear app storage with each new version
        this.resources.forEach(resource => this.storage.remove(resource.type));
        dataInit = false;
      }
      // try to get the latest data from server
      console.log('trying to update data from remote server');
      await this.update(!dataInit, null, true);
      if (!dataInit) {
        this.events.publish('data-provider:load');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Returns a local copy of either highlights, pois or tours
   */
  getData(type: DataType, quiet: boolean = false): Promise<Item[]> {
    return new Promise<Item[]>(async (resolve, reject) => {
      const onUpdated = () => {
        resource = this.resources.find(res => res.type === type);
        if (resource && resource.data) {
          resolve(resource.data.slice(0));
        } else {
          this.translate.get('services.util.data-error').subscribe(value => {
            reject({message: value});
          });
        }
      };

      // case 1: data has been preloaded
      let resource = this.resources.find(res => res.type === type);
      if (resource && resource.data) {
        resolve(resource.data.slice(0));
      } else {
        if (!this.loading) {
          // case 2: data has not been preloaded, preloading has finished
          await this.update(false, type, quiet);
          onUpdated();
        } else {
          // case 3: data has not been preloaded, preloading has not finished
          onUpdated();
        }
      }
    });
  }

  /**
   * Updates data (either from local json file or server)
   * Updating from a local file is used only for first startup, to provide initial data in case there is no network connection.
   */
  async update(init: boolean = false, type?: DataType, quiet: boolean = false) {
    const categorize = (n: number) => {
      if (n >= 200 && n < 300){
        return 200;
      }
      if (n >= 400 && n < 500){
        return 400;
      }
      if (n >= 500 && n < 600){
        return 500;
      }
    };

    let resources: Resource[];
    if (type) {
      const index = this.resources.findIndex(resource => resource.type === type);
      resources = this.resources.slice(index, index + 1);
    } else {
      resources = this.resources;
    }
    let errorCode;
    for (const resource of resources) {
      try {
        const response: any = await this.http.get(resource.url).toPromise();
        if (response.hasOwnProperty('content')) {
          console.log('data loaded');
          resource.data = response.content;
        }
      } catch (e) {
        if (e.status) {
          errorCode = e.status;
        }
      }
    }
    if (errorCode && !quiet) {
      const roundedDown = categorize(errorCode);
      switch (roundedDown) {
        case 200:
          this.translate.get('services.util.server-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
          });
          break;
        case 500:
          this.translate.get('services.util.server-down-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
          });
          break;
        default:
          this.translate.get('services.util.unknown-error').subscribe(value => {
            this.util.handleErrorMsg({message: value});
          });
      }
    }
    await this.store(init, type);
  }

  /**
   * Retrieves locally stored data from storage
   */
  private async restore() {
    for (const resource of this.resources) {
      resource.data = await this.storage.get(resource.type);
      console.log('data restored');
    }
  }

  /**
   * Saves data in local storage
   */
  private async store(init: boolean = false, type?: DataType) {
    let resources;
    if (type) {
      const index = this.resources.findIndex(resource => resource.type === type);
      resources = this.resources.slice(index, index + 1);
    } else {
      resources = this.resources;
    }
    for (const resource of resources) {
      await this.storage.set(resource.type, resource.data);
    }
    if (init) {
      const version = (await App.getInfo()).version;
      await this.storage.set('data-init', version);
    }
  }
}
